import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "src/api";

interface useGetNewsProps {
  pageNum: number;
  pageSize: number;
  mainExposure?: boolean;
  exposure?: boolean;
  searchWord?: string;
  sort: "CREATE" | "ENDDATE";
}

export function useDeleteNews() {
  return useMutation({
    mutationFn: (newsIds: number[]) => {
      return api.delete("/api/v2/admin/news/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: newsIds,
        },
      });
    },
  });
}

export function useHideNews() {
  return useMutation({
    mutationFn: (newsIds: number[]) => {
      return api.put("/api/v2/admin/news/multiple/hide", {
        ids: newsIds,
      });
    },
  });
}

export function useExposeNews() {
  return useMutation({
    mutationFn: (newsIds: number[]) => {
      return api.put("/api/v2/admin/news/multiple/expose", {
        ids: newsIds,
      });
    },
  });
}

export function useGetNews({ ...props }: useGetNewsProps) {
  return useQuery({
    queryKey: ["news", props],
    queryFn: async () => {
      const response = await api.get<IPage<INewsListItemResponseDto>>(
        "/api/v2/admin/news",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useCreateNews() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/news", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetNewsDetail(newsId: number) {
  return useQuery({
    queryKey: ["news", newsId],
    queryFn: async () => {
      const response = await api.get<INewsDetailResponseDto>(
        `/api/v2/admin/news/${newsId}`,
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateNews(newsId: number) {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.put(`/api/v2/admin/news/${newsId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}
