import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "src/api";
import { IFormUser } from "src/pages/new/user/NewUsersAddPage";

interface useGetUsersProps {
  pageNum: number;
  pageSize: number;
}

export function useGetUsers({ ...props }: useGetUsersProps) {
  return useQuery({
    queryKey: ["users", props],
    queryFn: async () => {
      const response = await api.get<IPage<IUser>>("/api/v2/admin/users", {
        params: props,
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useRegisterManager() {
  return useMutation({
    mutationFn: (data: IFormUser) => {
      return api.post("/api/v2/admin/users/register/manager", data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetUser(id: number) {
  return useQuery({
    queryKey: ["user", id],
    queryFn: async () => {
      const response = await api.get<IUser>(`/api/v2/admin/users/${id}`);
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
  });
}

export function useEditManager(userId: number) {
  return useMutation({
    mutationFn: (data: { password: string }) => {
      return api.put(`/api/v2/admin/users/${userId}`, data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useDeleteManager() {
  return useMutation({
    mutationFn: (userId: number) => {
      return api.delete(`/api/v2/admin/users/${userId}`, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}
