import { useQuery } from "@tanstack/react-query";
import { api } from "src/api";

export function useGetBrandProjectProperties() {
  return useQuery({
    queryKey: ["brandProjectProperties"],
    queryFn: async () => {
      const response = await api.get<IBrandCommonPropertyDto[]>(
        "/api/v2/common/brandProjectProperties",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useGetBrandWorkScopes() {
  return useQuery({
    queryKey: ["brandWorkScopes"],
    queryFn: async () => {
      const response = await api.get<IBrandCommonPropertyDto[]>(
        "/api/v2/common/brandWorkScopes",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useGetBrandBusinessCategories() {
  return useQuery({
    queryKey: ["brandBusinessCategories"],
    queryFn: async () => {
      const response = await api.get<IBrandCommonPropertyDto[]>(
        "/api/v2/common/brandBusinessCategories",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useGetAgencyFields() {
  return useQuery({
    queryKey: ["agencyFields"],
    queryFn: async () => {
      const response = await api.get<IBrandCommonPropertyDto[]>(
        "/api/v2/common/agencyFields",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}
