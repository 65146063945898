import { useState } from "react";
import { useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import { Checkbox as NCheckbox } from "src/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { useCreateNews } from "src/hooks/api/useNews";
import { useSelector } from "react-redux";

export interface IFormNews {
  thumbnail: File;
  newsType: "JOBS" | "EVENT" | "EXHIBITION" | "PLACE";
  displayStartDate: string;
  displayEndDate: string;
  description: string;
  link: string;
  exposure: boolean;
  mainExposure: boolean;
}

const NewNewsAddPage = () => {
  const form = useForm<IFormNews>({
    defaultValues: {
      newsType: "JOBS",
      displayStartDate: "",
      displayEndDate: "",
      description: "",
      link: "",
      exposure: false,
      mainExposure: false,
    },
  });

  const [thumbnail, setThumbnail] = useState<File>();

  const [thumbnailPreview, setThumbnailPreview] = useState<string>("");

  const onNewsTypeChange = (
    newsType: "JOBS" | "EVENT" | "EXHIBITION" | "PLACE",
  ) => {
    form.setValue("newsType", newsType);
  };

  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "thumbnail",
  ) => {
    const fileArr = e.target.files;

    let fileURLs: Array<string> = [];
    let file;

    if (!fileArr) return;

    setThumbnail((prev) => {
      return fileArr[0];
    });

    file = fileArr[0];
    let reader = new FileReader();
    reader.onload = () => {
      fileURLs[0] = reader.result ? (reader.result as string) : "";
      setThumbnailPreview((prev) => {
        return fileURLs[0];
      });
    };
    reader.readAsDataURL(file);
  };

  const mutation = useCreateNews();

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const onSubmit = (data: IFormNews) => {
    const formData = new FormData();
    if (thumbnail) {
      data.thumbnail = thumbnail;
      formData.append("thumbnail", thumbnail);
    }
    if (!data.thumbnail) {
      alert("썸네일을 등록해주세요.");
      return;
    }

    formData.append(
      "news",
      new Blob([JSON.stringify(data)], { type: "application/json" }),
    );

    mutation.mutate(formData, {
      onSuccess: () => {
        alert("등록되었습니다.");
        window.location.replace("/new/news");
      },
      onError: () => {
        alert("등록에 실패하였습니다.");
      },
    });
  };

  const TextField = ({
    formField,
    label,
    placeholder,
  }: {
    formField: "link";
    label: string;
    placeholder?: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
              placeholder={placeholder}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "mainExposure" | "exposure";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">카드 등록</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="thumbnail"
            render={({ field }) => (
              <FormItem>
                <Label className="text-lg font-bold">
                  썸네일(960 X 500 72dpl 파일로 업로드 해주세요)
                </Label>
                {thumbnailPreview && (
                  <Label>
                    <img
                      src={thumbnailPreview}
                      alt="brand-thumbnail"
                      className="h-[220px] w-[330px] object-contain"
                    />
                  </Label>
                )}
                <FormControl>
                  <NInput
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(e) => {
                      handleImageUpload(e, "thumbnail");
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="newsType"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">종류</FormLabel>
                <FormControl>
                  <div className="flex w-full gap-6">
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">JOBS</Label>
                      <NCheckbox
                        checked={field.value === "JOBS"}
                        onCheckedChange={() => onNewsTypeChange("JOBS")}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">EVENT</Label>
                      <NCheckbox
                        checked={field.value === "EVENT"}
                        onCheckedChange={() => onNewsTypeChange("EVENT")}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">EXHIBITION</Label>
                      <NCheckbox
                        checked={field.value === "EXHIBITION"}
                        onCheckedChange={() => onNewsTypeChange("EXHIBITION")}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">PLACE</Label>
                      <NCheckbox
                        checked={field.value === "PLACE"}
                        onCheckedChange={() => onNewsTypeChange("PLACE")}
                      />
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <div>
            <Label className="text-lg font-bold">노출 기간</Label>
            <div className="h-4" />
            <div className="flex gap-4">
              <FormField
                control={form.control}
                name="displayStartDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-lg font-bold">시작일</FormLabel>
                    <FormControl>
                      <NInput
                        type="date"
                        value={field.value}
                        onChange={(e) =>
                          form.setValue("displayStartDate", e.target.value)
                        }
                        className="w-40"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="displayEndDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-lg font-bold">종료일</FormLabel>
                    <FormControl>
                      <NInput
                        type="date"
                        value={field.value}
                        onChange={(e) =>
                          form.setValue("displayEndDate", e.target.value)
                        }
                        className="w-40"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TextField formField="link" label="링크" />

          {isAdmin && <BooleanField formField="exposure" label="노출 여부" />}
          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}

          <div className="flex justify-end">
            <Button type="submit">등록</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default NewNewsAddPage;
