import { Dispatch, SetStateAction } from "react";
import { Card, Box, Grid, Button } from "@mui/material";
import styled from "styled-components";
import { useHistory } from "react-router";

const Image = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
  border: 1px solid #d2d2d2;
`;

const StyledCard = styled(Card)`
  padding: 15px;

  .brandTitle {
    font-size: 20px;
    font-weight: bold;
  }

  .client {
    font-size: 16px;
  }

  .title {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }

  .title-left {
    font-size: 15px;
    font-weight: bold;
    margin-left: 50px;
    margin-right: 10px;
  }

  .info {
    font-size: 15px;
  }
`;

const StyledCheckBox = styled.input`
  width: 20px;
  height: 20px;
  margin: 0;
`;

interface IAgencyListProps {
  agencyList: IAgencyListItem[];
  agencyTotal: number;
  selectedAgencyIds: number[];
  setSelectedAgencyIds: Dispatch<SetStateAction<number[]>>;
}

const AgencyList = ({
  agencyList,
  agencyTotal,
  selectedAgencyIds,
  setSelectedAgencyIds,
}: IAgencyListProps) => {
  const history = useHistory();

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedAgencyIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedAgencyIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedAgencyIds.slice(1),
      );
    } else if (selectedIndex === selectedAgencyIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedAgencyIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedAgencyIds.slice(0, selectedIndex),
        selectedAgencyIds.slice(selectedIndex + 1),
      );
    }
    setSelectedAgencyIds(newSelectedCustomerIds);
  };

  return (
    <>
      {agencyTotal === 0 ? (
        <></>
      ) : (
        <>
          <Grid container spacing={3} columns={{ xs: 2, sm: 4, md: 12 }}>
            {agencyList &&
              agencyList.map((agency, index) => (
                <Grid item xs={3} key={index}>
                  <StyledCard>
                    <Box display="flex" justifyContent="flex-start" mb={1}>
                      <StyledCheckBox
                        type="checkbox"
                        checked={selectedAgencyIds.indexOf(agency.id) !== -1}
                        onChange={(event) => handleSelectOne(event, agency.id)}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      onClick={() => {}}
                    >
                      <Box display="flex" flexDirection="row">
                        <Image src={agency.thumbnailUrl} />
                      </Box>
                      <Box display="flex" flexDirection="row" mt={2}>
                        <span className="brandTitle">{agency.korName}</span>
                        &nbsp;
                        <span className="client">{agency.engName}</span>(
                        {agency.relatedBrandCount})
                      </Box>
                      <Box mt={2} />
                      <div>
                        <span className="title">에이전시 Url</span>
                        <span className="info">{agency.url}</span>
                        <br />

                        <span className="title">오피스</span>
                        <span className="info">
                          {agency.offices && agency.offices.length === 0
                            ? ""
                            : agency.offices.join(", ")}
                        </span>
                        <br />
                        <span className="title">노출여부</span>
                        <span className="info">
                          {agency.exposure ? "Y" : "N"}
                        </span>
                        <span className="title-left">메인노출</span>
                        <span className="info">
                          {agency.mainExposure ? "Y" : "N"}
                        </span>
                      </div>
                    </Box>
                    <Box display="flex" flexDirection="row" mt={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          history.push(`/new/agencies/${agency.id}/edit`)
                        }
                      >
                        수정
                      </Button>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default AgencyList;
