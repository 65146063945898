import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../ui/checkbox";

export const BrandColumns: ColumnDef<IBrandListItemResponseDto>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  },

  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "korName",
    header: "한글명",
  },
  {
    accessorKey: "engName",
    header: "영문명",
  },
  {
    accessorKey: "announcementDate",
    header: "발표일",
  },
];
