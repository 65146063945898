import { useEffect, useState } from "react";
import { Box, Container, Button, Divider } from "@mui/material";
import { useHistory } from "react-router";
import Pagination from "react-js-pagination";
import ToolBar from "src/components/common/Toolbar";
import {
  useDeleteFeatures,
  useExposeFeatures,
  useGetFeatures,
  useHideFeatures,
} from "src/hooks/api/useFeatures";
import ExternalFeatureList from "src/components/externalFeature/ExternalFeatureList";
import { imageBaseUrl } from "src/constants";
import { convertDate } from "src/lib/utils";
import { useSelector } from "react-redux";
import { useToast } from "src/components/ui/use-toast";

const ExternalFeaturesPage = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [mainExposure, setMainExposure] = useState<boolean>();
  const [exposure, setExposure] = useState<boolean>();

  const [selectedExternalFeatureIds, setSelectedExternalFeatureIds] = useState<
    number[]
  >([]);

  const {
    data: externalFeatures,
    // isLoading,
    // isError,
    refetch,
  } = useGetFeatures({
    pageNum: pageNum,
    pageSize: 10,
    type: "EXTERNAL",
    searchWord: searchWord,
    mainExposure: mainExposure,
    exposure: exposure,
    sort: "CREATE",
  });

  useEffect(() => {
    refetch();
  }, [pageNum, exposure, mainExposure, refetch]);

  const onClickSearch = () => {
    refetch();
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const onClickOrderByCreate = () => {
    setMainExposure(undefined);
    setExposure(undefined);
  };

  const onClickMainExposure = () => {
    setMainExposure(true);
    setExposure(undefined);
  };

  const onClickHide = () => {
    setExposure(false);
    setMainExposure(undefined);
  };

  const goBack = () => {
    window.location.reload();
  };

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);
  const { toast } = useToast();

  const mutation = useDeleteFeatures();
  const deleteExternalFeatures = () => {
    mutation.mutate(selectedExternalFeatureIds, {
      onSuccess: () => {
        toast({
          title: "삭제되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.reload();
      },
      onError: (error) => {
        if (error.message.includes("403")) {
          toast({
            title: "권한이 없습니다.",
            description: "관리자만 삭제할 수 있습니다.",
            variant: "destructive",
          });
          return;
        }
      },
    });
  };

  const mutationHide = useHideFeatures();
  const hideFeatures = () => {
    mutationHide.mutate(selectedExternalFeatureIds, {
      onSuccess: () => {
        alert("숨김처리 되었습니다.");
        window.location.reload();
      },
      onError: () => {
        alert("숨김처리에 실패하였습니다.");
      },
    });
  };

  const mutationExpose = useExposeFeatures();
  const exposeFeatures = () => {
    mutationExpose.mutate(selectedExternalFeatureIds, {
      onSuccess: () => {
        alert("숨김 해제 처리 되었습니다.");
        window.location.reload();
      },
      onError: () => {
        alert("숨김 해제 처리에 실패하였습니다.");
      },
    });
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <h2 className="text-xl font-bold">외부 콘텐츠 관리</h2>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/new/features/external/add")}
            >
              콘텐츠 등록
            </Button>
            <Box mr={2} />
            <Button variant="contained" onClick={() => goBack()}>
              검색 결과 초기화
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              onClick={() => history.push("/new/features/tags")}
            >
              태그 관리
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteExternalFeatures()}
              >
                콘텐츠 삭제
              </Button>
            )}
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => hideFeatures()}
            >
              일괄 숨김
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => exposeFeatures()}
            >
              일괄 숨김 해제
            </Button>
          </Box>
        </Box>
        <ToolBar
          search={searchWord}
          setSearch={setSearchWord}
          onClickSearch={onClickSearch}
        />
        <Divider />
        <Box mt={2} />
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByCreate}
          >
            등록 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickHide}
          >
            숨김 컨텐츠 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickMainExposure}
          >
            메인노출 컨텐츠 보기
          </Button>
        </Box>
        <Divider />
        <div className="py-4 text-xl font-semibold">
          전체 데이터 수 : {externalFeatures?.totalElements}개
        </div>
        <ExternalFeatureList
          externalFeatureList={
            externalFeatures?.content?.map((feature) => ({
              id: feature.id,
              mainExposure: feature.mainExposure,
              thumbnailUrl: `${imageBaseUrl}/?fileId=${feature.thumbnailId}`,
              source: feature.source,
              title: feature.title,
              description: feature.description,
              createAt: convertDate(feature.createAt),
              exposure: feature.exposure,
            })) ?? []
          }
          selectedExternalFeatureIds={selectedExternalFeatureIds}
          setSelectedExternalFeatureIds={setSelectedExternalFeatureIds}
        />
        <Pagination
          activePage={pageNum}
          itemsCountPerPage={10}
          totalItemsCount={externalFeatures?.totalElements ?? 0}
          pageRangeDisplayed={10}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </Container>
    </Box>
  );
};

export default ExternalFeaturesPage;
