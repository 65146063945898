import { ColumnDef } from "@tanstack/react-table";
import { FeatureTagEditButton } from "./FeatureTagEditButton";
import FeatureTagDeleteButton from "./FeatureTagDeleteButton";

export const FeatureTagColumns = (refetch?: () => void) => {
  return [
    {
      accessorKey: "id",
      header: "ID",
      size: 100,
    },
    {
      accessorKey: "name",
      header: "태그 이름",
      size: 500,
    },
    {
      id: "edit",
      header: "수정",
      size: 100,
      cell: ({ row }) => {
        return (
          <FeatureTagEditButton
            id={row.id}
            name={row.original.name}
            refetch={refetch}
          />
        );
      },
    },
    {
      id: "delete",
      header: "삭제",
      size: 100,
      cell: ({ row }) => (
        <FeatureTagDeleteButton id={row.id} refetch={refetch} />
      ),
    },
  ] as ColumnDef<IBrandCommonPropertyDto>[];
};
