import { Route, Switch } from "react-router-dom";
import BrandPage from "src/pages/new/brand/BrandPage";
import BrandAddPage from "src/pages/new/brand/BrandAddPage";
import AgencyPage from "./pages/new/agency/AgencyPage";
import NewAgencyAddPage from "./pages/new/agency/AgencyAddPage";
import NewTrendKeywordAddPage from "./pages/new/trendKeyword/TrendKeywordAddPage";
import TrendKeywordPage from "./pages/new/trendKeyword/TrendKeywordPage";
import ExternalFeaturesPage from "./pages/new/feature/external/ExternalFeaturePage";
import BrandBFeaturePage from "./pages/new/feature/brandb/BrandBFeaturePage";
import ExternalFeaturesAddPage from "./pages/new/feature/external/ExternalFeatureAddPage";
import BrandBFeatureAddPage from "./pages/new/feature/brandb/BrandBFeatureAddPage";
import NewTrendKeywordEditPage from "./pages/new/trendKeyword/TrendKeywordEditPage";
import ExternalFeaturesEditPage from "./pages/new/feature/external/ExternalFeatureEditPage";
import BrandBFeatureEditPage from "./pages/new/feature/brandb/BrandBFeatureEditPage";
import NewPinnedCaseStudyPage from "./pages/new/pinnedCaseStudy/NewPinnedCaseStudyPage";
import NewPinnedCaseStudyAddPage from "./pages/new/pinnedCaseStudy/NewPinnedCaseStudyAddPage";
import NewPinnedCaseStudyEditPage from "./pages/new/pinnedCaseStudy/NewPinnedCaseStudyEditPage";
import NewNewsPage from "./pages/new/news/NewNewsPage";
import NewNewsAddPage from "./pages/new/news/NewNewsAddPage";
import NewNewsEditPage from "./pages/new/news/NewNewsEditPage";
import NewAgencyEditPage from "./pages/new/agency/AgencyEditPage";
import BrandEditPage from "./pages/new/brand/BrandEditPage";
import NewTypefacePage from "./pages/new/typeface/NewTypefacePage";
import NewTypefaceAddPage from "./pages/new/typeface/NewTypefaceAddPage";
import NewTypefaceEditPage from "./pages/new/typeface/NewTypefaceEditPage";
import NewUsersPage from "./pages/new/user/NewUsersPage";
import NewUsersAddPage from "./pages/new/user/NewUsersAddPage";
import NewUsersEditPage from "./pages/new/user/NewUsersEditPage";
import BrandTagsPage from "./pages/new/brand/BrandTagsPage";
import FeatureTagsPage from "./pages/new/feature/FeatureTagsPage";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={BrandPage} />
      <Route path="/new/brands" exact component={BrandPage} />
      <Route path="/new/brands/add" exact component={BrandAddPage} />
      <Route path="/new/brands/:id/edit" exact component={BrandEditPage} />
      <Route path="/new/trend-keywords" exact component={TrendKeywordPage} />
      <Route
        path="/new/trend-keywords/add"
        exact
        component={NewTrendKeywordAddPage}
      />
      <Route
        path="/new/trend-keywords/:id/edit"
        exact
        component={NewTrendKeywordEditPage}
      />
      <Route path="/new/agencies" exact component={AgencyPage} />
      <Route path="/new/agencies/add" exact component={NewAgencyAddPage} />
      <Route
        path="/new/agencies/:id/edit"
        exact
        component={NewAgencyEditPage}
      />
      <Route
        path="/new/features/external"
        exact
        component={ExternalFeaturesPage}
      />
      <Route
        path="/new/features/external/add"
        exact
        component={ExternalFeaturesAddPage}
      />
      <Route
        path="/new/features/external/:id/edit"
        exact
        component={ExternalFeaturesEditPage}
      />

      <Route path="/new/features/brandb" exact component={BrandBFeaturePage} />
      <Route
        path="/new/features/brandb/add"
        exact
        component={BrandBFeatureAddPage}
      />
      <Route
        path="/new/features/brandb/:id/edit"
        exact
        component={BrandBFeatureEditPage}
      />
      <Route path="/new/pinned-case" exact component={NewPinnedCaseStudyPage} />
      <Route
        path="/new/pinned-case/add"
        exact
        component={NewPinnedCaseStudyAddPage}
      />
      <Route
        path="/new/pinned-case/:id/edit"
        exact
        component={NewPinnedCaseStudyEditPage}
      />
      <Route path="/new/news" exact component={NewNewsPage} />
      <Route path="/new/news/add" exact component={NewNewsAddPage} />
      <Route path="/new/news/:id/edit" exact component={NewNewsEditPage} />
      <Route path="/new/typeface" exact component={NewTypefacePage} />
      <Route path="/new/typeface/add" exact component={NewTypefaceAddPage} />
      <Route
        path="/new/typeface/:id/edit"
        exact
        component={NewTypefaceEditPage}
      />

      <Route path="/new/users" exact component={NewUsersPage} />
      <Route path="/new/users/add" exact component={NewUsersAddPage} />
      <Route path="/new/users/:id/edit" exact component={NewUsersEditPage} />
      <Route path="/new/brands/tags" exact component={BrandTagsPage} />
      <Route path="/new/features/tags" exact component={FeatureTagsPage} />
    </Switch>
  );
};
