import { ColumnDef } from "@tanstack/react-table";
import { Checkbox } from "../ui/checkbox";
import { convertDate } from "src/lib/utils";
import { Button } from "../ui/button";
import UserDeleteButton from "./UserDeleteButton";

export const UsersColumns: ColumnDef<IUser>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
  },
  {
    accessorKey: "id",
    header: "ID",
  },
  {
    accessorKey: "userId",
    header: "아이디",
  },
  {
    accessorKey: "role",
    header: "권한",
    accessorFn: (row) => {
      if (row.role === "ROLE_ADMIN") {
        return "관리자";
      }
      if (row.role === "ROLE_MANAGER") {
        return "매니저";
      }
      return "알 수 없음";
    },
  },
  {
    accessorKey: "createAt",
    header: "가입일",
    accessorFn: (row) => {
      return convertDate(row.createAt);
    },
  },
  {
    accessorKey: "updateAt",
    header: "수정일",
    accessorFn: (row) => {
      return convertDate(row.updateAt);
    },
  },
  {
    id: "edit",
    header: "수정",
    cell: ({ row }) => (
      <Button
        onClick={() => (window.location.href = `/new/users/${row.id}/edit`)}
      >
        수정
      </Button>
    ),
  },
  {
    id: "delete",
    header: "삭제",
    cell: ({ row }) => <UserDeleteButton id={row.id} />,
  },
];
