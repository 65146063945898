import { useEffect, useState } from "react";
import { Box, Container, Button, Divider } from "@mui/material";
import { useHistory } from "react-router";
import Pagination from "react-js-pagination";
import ToolBar from "src/components/common/Toolbar";
import PinnedCaseStudyList from "src/components/pinnedCaseStudy/PinnedCaseStudyList";
import {
  useDeletePinnedCaseStudies,
  useExposePinnedCaseStudies,
  useGetPinnedCaseStudies,
  useHidePinnedCaseStudies,
} from "src/hooks/api/usePinnedCaseStudy";
import { imageBaseUrl } from "src/constants";
import { convertDate } from "src/lib/utils";
import { useSelector } from "react-redux";
import { useToast } from "src/components/ui/use-toast";

const NewPinnedCaseStudyPage = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [mainExposure, setMainExposure] = useState<boolean>();
  const [exposure, setExposure] = useState<boolean>();

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);
  const { toast } = useToast();

  const [selectedPinnedCaseStudyIds, setSelectedPinnedCaseStudyIds] = useState<
    number[]
  >([]);

  const {
    data: pinnedCaseStudies,
    // isLoading,
    // isError,
    refetch,
  } = useGetPinnedCaseStudies({
    pageNum: pageNum,
    pageSize: 10,
    searchWord: searchWord,
    mainExposure: mainExposure,
    exposure: exposure,
  });

  useEffect(() => {
    refetch();
  }, [pageNum, exposure, mainExposure, refetch]);

  const onClickSearch = () => {
    refetch();
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const onClickOrderByCreate = () => {
    setMainExposure(undefined);
    setExposure(undefined);
  };

  const onClickMainExposure = () => {
    setMainExposure(true);
    setExposure(undefined);
  };

  const onClickHide = () => {
    setExposure(false);
    setMainExposure(undefined);
  };

  const goBack = () => {
    window.location.reload();
  };

  const mutation = useDeletePinnedCaseStudies();
  const deletePinnedCaseStudies = () => {
    mutation.mutate(selectedPinnedCaseStudyIds, {
      onSuccess: () => {
        toast({
          title: "삭제되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.reload();
      },
      onError: (error) => {
        if (error.message.includes("403")) {
          toast({
            title: "권한이 없습니다.",
            description: "관리자만 삭제할 수 있습니다.",
            variant: "destructive",
          });
          return;
        }
      },
    });
  };

  const mutationHide = useHidePinnedCaseStudies();
  const hidePinnedCaseStudies = () => {
    mutationHide.mutate(selectedPinnedCaseStudyIds, {
      onSuccess: () => {
        alert("숨김 처리 되었습니다.");
        window.location.reload();
      },
      onError: () => {
        alert("숨김 처리에 실패하였습니다.");
      },
    });
  };

  const mutationExpose = useExposePinnedCaseStudies();
  const exposePinnedCaseStudies = () => {
    mutationExpose.mutate(selectedPinnedCaseStudyIds, {
      onSuccess: () => {
        alert("숨김 해제 처리 되었습니다.");
        window.location.reload();
      },
      onError: () => {
        alert("숨김 해제 처리에 실패하였습니다.");
      },
    });
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <h2 className="text-xl font-bold">핀업 케이스 관리</h2>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/new/pinned-case/add")}
            >
              링크 등록
            </Button>
            <Box mr={2} />
            <Button variant="contained" onClick={() => goBack()}>
              검색 결과 초기화
            </Button>
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => deletePinnedCaseStudies()}
              >
                콘텐츠 삭제
              </Button>
            )}
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => hidePinnedCaseStudies()}
            >
              일괄 숨김
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              onClick={() => exposePinnedCaseStudies()}
            >
              일괄 숨김 해제
            </Button>
          </Box>
        </Box>
        <ToolBar
          search={searchWord}
          setSearch={setSearchWord}
          onClickSearch={onClickSearch}
        />
        <Divider />
        <Box mt={2} />
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByCreate}
          >
            등록 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickHide}
          >
            숨김 컨텐츠 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickMainExposure}
          >
            메인노출 컨텐츠 보기
          </Button>
        </Box>
        <Divider />
        <div className="py-4 text-xl font-semibold">
          전체 데이터 수 : {pinnedCaseStudies?.totalElements}개
        </div>
        <PinnedCaseStudyList
          pinnedCaseStudyList={
            pinnedCaseStudies?.content?.map((pinnedCaseStudy) => ({
              id: pinnedCaseStudy.id,
              mainExposure: pinnedCaseStudy.mainExposure,
              thumbnailUrl: `${imageBaseUrl}/?fileId=${pinnedCaseStudy.thumbnailId}`,
              exposure: pinnedCaseStudy.exposure,
              brandName: pinnedCaseStudy.brandName,
              content: pinnedCaseStudy.content,
              createAt: convertDate(pinnedCaseStudy.createAt),
            })) ?? []
          }
          selectedPinnedCaseStudyIds={selectedPinnedCaseStudyIds}
          setSelectedPinnedCaseStudyIds={setSelectedPinnedCaseStudyIds}
        />
        <Pagination
          activePage={pageNum}
          itemsCountPerPage={10}
          totalItemsCount={pinnedCaseStudies?.totalElements || 0}
          pageRangeDisplayed={10}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </Container>
    </Box>
  );
};

export default NewPinnedCaseStudyPage;
