import React from "react";
import styled from "styled-components";

const Text = (props) => {
  const {
    children,
    onClick,
    onMouseEnter,
    color,
    size,
    bold,
    flexGrow,
    margin,
    marginB,
    marginT,
    textAlign,
    center,
    full,
    lineHeight,
    weight,
    subBody,
    width,
    border,
    pointer,
  } = props;

  const styles = {
    color: color,
    flexGrow: flexGrow,
    margin: margin,
    marginB: marginB,
    marginT: marginT,
    textAlign: textAlign,
    lineHeight: lineHeight,
    weight: weight,
    width: width,
    border: border,
    pointer: pointer,
  };

  const preSetting = {
    bold: bold,
    center: center,
    full: full,
    // pointer: pointer,
  };

  if (subBody) {
    return (
      <TextBox
        size="12px"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        {...styles}
        {...preSetting}
      >
        {children}
      </TextBox>
    );
  }

  return (
    <TextBox size={size} onClick={onClick} {...styles} {...preSetting}>
      {children}
    </TextBox>
  );
};

// body 기준 default임
// defaultProps P대문자!!!
Text.defaultProps = {
  color: "black",
  size: "14px",
  weight: "400",
  flexGrow: false,
  margin: false,
  marginB: false,
  marginT: false,
  width: false,
  border: false,
  children: "",
  onClick: null,
  pointer: false,
};

const TextBox = styled.div`
  ${(props) => (props.full ? "display: flex; flex-direction: row; width: 100%;" : "")}
  flex-grow:${(props) => (props.flexGrow ? props.flexGrow : "")};
  align-items: center;
  height: fit-content;
  width: ${(props) => props.width};
  border: ${(props) => props.border};
  margin: ${(props) => props.margin};
  margin-bottom: ${(props) => props.marginB};
  margin-top: ${(props) => props.marginT};
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => (props.bold ? "700" : "400")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "")};
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : "")}
  word-spacing : -1.5px;
  letter-spacing: 0.25px;
  cursor: ${(props) => (props.onClick ? "pointer" : "")};
`;

export default Text;
