import styled from "styled-components";
import { NavLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import { useSelector } from "react-redux";

const StyledLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const SideBarStyle = styled(List)`
  position: fixed;
  width: 240px;
  max-width: 240px;
  background-color: #f6f6f6;
  height: 100vh;
  overflow: hidden;
`;

const ListText = styled.div`
  font-size: 13px;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const menus = [
  { route: "/new/brands", name: "신규 브랜드 관리" },
  { route: "/new/trend-keywords", name: "신규 트렌드 키워드 관리" },
  { route: "/new/typeface", name: "신규 서체 정보 관리" },
  { route: "/new/agencies", name: "신규 에이전시 관리" },
  { route: "/new/features/external", name: "신규 외부 콘텐츠 관리" },

  { route: "/new/pinned-case", name: "신규 핀업 케이스 관리" },
  { route: "/new/news", name: "신규 뉴스 관리" },
];

export default function SideBar() {
  const isAdmin = useSelector((state) => state.user.isAdmin);
  if (
    isAdmin &&
    menus.findIndex((menu) => menu.route === "/new/features/brandb") === -1
  ) {
    menus.push({
      route: "/new/features/brandb",
      name: "신규 에디터 콘텐츠 관리",
    });
  }
  if (
    isAdmin &&
    menus.findIndex((menu) => menu.route === "/new/users") === -1
  ) {
    menus.push({ route: "/new/users", name: "신규 사용자 관리" });
  }

  return (
    <SideBarStyle component="nav">
      {menus.map((menu, idx) => {
        return (
          <StyledLink
            to={menu.route}
            activeStyle={{ color: "blue" }}
            key={`menu-${idx}`}
          >
            <ListItem button>
              <ListItemIcon>
                <PeopleAltRoundedIcon />
              </ListItemIcon>
              <ListText>{menu.name}</ListText>
            </ListItem>
          </StyledLink>
        );
      })}
    </SideBarStyle>
  );
}
