import axios from "axios";

// export const baseURL = "https://api.brandb.net";
// export const baseURL = "http://3.38.250.141:8080";
export const baseURL = process.env.REACT_APP_BASE_URL;

export const token = localStorage.getItem("access_token");

function createInstance() {
  const instance = axios.create({
    baseURL: baseURL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: token ? token : "",
    },
  });
  return instance;
}

export const api = createInstance();
