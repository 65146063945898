import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "src/api";
import { IFormTrendKeyword } from "src/pages/new/trendKeyword/TrendKeywordAddPage";

interface useGetTrendKeywordsProps {
  pageNum: number;
  sort: "CREATE" | "NAME" | "LOGO_COUNT" | "UPDATE";
  mainExposure?: boolean;
  searchWord?: string;
}

export function useGetSimpleTrendKeywords() {
  return useQuery({
    queryKey: ["simpleTrendKeywords"],
    queryFn: async () => {
      const response = await api.get<ISimpleTrendKeywordDto[]>(
        "/api/v2/admin/trendkeywords/simple",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useDeleteTrendKeywords() {
  return useMutation({
    mutationFn: (trendKeywordIds: number[]) => {
      return api.delete("/api/v2/admin/trendkeywords/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: trendKeywordIds,
        },
      });
    },
  });
}

export function useGetTrendKeywords({ ...props }: useGetTrendKeywordsProps) {
  return useQuery({
    queryKey: ["trendKeywords", props],
    queryFn: async () => {
      const response = await api.get<IPage<ITrendKeywordListItemResponseDto>>(
        "/api/v2/admin/trendkeywords",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useCreateTrendKeyword() {
  return useMutation({
    mutationFn: (data: IFormTrendKeyword) => {
      return api.post("/api/v2/admin/trendkeywords", data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetTrendKeywordDetail(trendKeywordId: number) {
  return useQuery({
    queryKey: ["trendKeywordDetail", trendKeywordId],
    queryFn: async () => {
      const response = await api.get<ITrendKeywordDetailResponseDto>(
        `/api/v2/admin/trendkeywords/${trendKeywordId}`,
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateTrendKeyword(trendKeywordId: number) {
  return useMutation({
    mutationFn: (data: IFormTrendKeyword) => {
      return api.put(`/api/v2/admin/trendkeywords/${trendKeywordId}`, data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}
