import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@mui/material";

import { useHistory } from "react-router";

const TableHeadColor = styled(TableHead)`
  background-color: #e2e2e2;
`;

const TableCellCenter = styled(TableCell)`
  text-align: center;
  :not(:last-child) {
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
  }
  :not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;

const Thumbnail = styled.img`
  border: 1px solid #dddddd;
  width: 300px;
  height: 150px;
  object-fit: contain;
`;

interface INewsListProps {
  newsList: INewsListItem[];
  selectedNewsIds: number[];
  setSelectedNewsIds: Dispatch<SetStateAction<number[]>>;
}

const NewsList = ({
  newsList,
  selectedNewsIds,
  setSelectedNewsIds,
}: INewsListProps) => {
  const history = useHistory();
  const handleSelectAll = (event: any) => {
    let newSelectedCustomerIds: number[];

    if (event.target.checked) {
      newSelectedCustomerIds = newsList.map((news) => news.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedNewsIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedNewsIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedNewsIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedNewsIds.slice(1),
      );
    } else if (selectedIndex === selectedNewsIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedNewsIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedNewsIds.slice(0, selectedIndex),
        selectedNewsIds.slice(selectedIndex + 1),
      );
    }
    setSelectedNewsIds(newSelectedCustomerIds);
  };

  return (
    <>
      <Card>
        {newsList.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={3}
            mb={3}
          >
            <b>등록된 컨텐츠가 없습니다</b>
          </Box>
        ) : (
          <>
            <Box sx={{ minWidth: 600 }}>
              <Table>
                <TableHeadColor>
                  <TableRow>
                    <TableCellCenter padding="checkbox">
                      <Checkbox
                        checked={
                          selectedNewsIds.length > 0 &&
                          selectedNewsIds.length < newsList.length
                        }
                        color="primary"
                        indeterminate={
                          selectedNewsIds.length > 0 &&
                          selectedNewsIds.length < newsList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCellCenter>
                    <TableCellCenter align="center">No.</TableCellCenter>
                    <TableCellCenter align="center">메인노출</TableCellCenter>
                    <TableCellCenter align="center">썸네일</TableCellCenter>
                    <TableCellCenter align="center">종류</TableCellCenter>
                    <TableCellCenter align="center">노출기간</TableCellCenter>
                    <TableCellCenter align="center">등록일자</TableCellCenter>
                    <TableCellCenter align="center">노출 여부</TableCellCenter>
                    <TableCellCenter align="center" />
                  </TableRow>
                </TableHeadColor>
                <TableBody>
                  {newsList &&
                    newsList.map((news) => (
                      <TableRow hover key={news.id}>
                        <TableCellCenter padding="checkbox">
                          <Checkbox
                            checked={selectedNewsIds.indexOf(news.id) !== -1}
                            onChange={(event) =>
                              handleSelectOne(event, news.id)
                            }
                            value="true"
                            color="primary"
                          />
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {news.id}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {news.mainExposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Box display="flex" justifyContent="center">
                            {news.thumbnailUrl && (
                              <Thumbnail src={news.thumbnailUrl} alt="썸네일" />
                            )}
                          </Box>
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {news.newsType}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {news.displayStartDate} ~ {news.displayEndDate}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {news.createAt}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {news.exposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              history.push(`/new/news/${news.id}/edit`)
                            }
                          >
                            수정
                          </Button>
                        </TableCellCenter>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={3}></Box>
    </>
  );
};

export default NewsList;
