import { useEffect, useRef, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import { Checkbox as NCheckbox } from "src/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { Button } from "src/components/ui/button";
import { X } from "lucide-react";
import { cn } from "src/lib/utils";
import { Badge } from "src/components/ui/badge";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { imageBaseUrl } from "src/constants";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { useGetAgencyFields } from "src/hooks/api/useCommon";
import { useGetBrands } from "src/hooks/api/useBrand";
import { DataTable } from "src/components/common/DataTable";
import { BrandColumns } from "src/components/common/BrandColumns";
import { useCreateAgency } from "src/hooks/api/useAgency";
import { useSelector } from "react-redux";

interface IFormAgency {
  korName: string;
  engName: string;
  description: string;
  file: File;
  url: string;
  instaLink: string;
  behanceLink: string;
  agencyType: "KOREA" | "GLOBAL";
  agencyFieldIds: number[];
  agencyOffices: { id?: number; name: string }[];
  relatedBrandIds: number[];
  exposure: boolean;
  mainExposure: boolean;
}

const NewAgencyAddPage = () => {
  const form = useForm<IFormAgency>({
    defaultValues: {
      korName: "",
      engName: "",
      description: "",
      url: "",
      instaLink: "",
      behanceLink: "",
      agencyType: "KOREA",
      agencyFieldIds: [],
      agencyOffices: [],
      relatedBrandIds: [],
      exposure: false,
      mainExposure: false,
    },
  });

  const [file, setFile] = useState<File>();
  const [filePreview, setFilePreview] = useState<string>("");

  const brandSearchRef = useRef<HTMLInputElement>(null);
  const [brandSearchWord, setBrandSearchWord] = useState("");
  const [brandPageNum, setBrandPageNum] = useState(1);
  const { data: brands, refetch: refetchBrands } = useGetBrands({
    pageNum: brandPageNum,
    pageSize: 10,
    sort: "CREATE",
    searchWord: brandSearchWord,
  });

  const [selectedBrandsRow, setSelectedBrandsRow] = useState({});

  useEffect(() => {
    if (!brandSearchWord) return;
    refetchBrands();
  }, [brandPageNum, brandSearchWord]);

  const [selectedBrands, setSelectedBrands] = useState<
    IBrandListItemResponseDto[]
  >([]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = e.target.files;

    let fileURLs: Array<string> = [];
    let file;

    if (!fileArr) return;

    setFile((prev) => {
      return fileArr[0];
    });

    file = fileArr[0];
    let reader = new FileReader();
    reader.onload = () => {
      fileURLs[0] = reader.result ? (reader.result as string) : "";
      setFilePreview((prev) => {
        return fileURLs[0];
      });
    };
    reader.readAsDataURL(file);
  };

  const mutation = useCreateAgency();

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const onSubmit = (data: IFormAgency) => {
    const formData = new FormData();
    if (file) {
      data.file = file;
      formData.append("file", file);
    }
    if (!data.file) {
      alert("썸네일을 등록해주세요.");
      return;
    }

    formData.append(
      "agency",
      new Blob([JSON.stringify(data)], { type: "application/json" }),
    );

    mutation.mutate(formData, {
      onSuccess: () => {
        alert("등록되었습니다.");
        window.location.replace("/new/agencies");
      },
      onError: () => {
        alert("등록에 실패하였습니다.");
      },
    });
  };

  const onAgencyTypeChange = (agencyType: "KOREA" | "GLOBAL") => {
    form.setValue("agencyType", agencyType);
  };

  const TextField = ({
    formField,
    label,
    placeholder,
  }: {
    formField: "korName" | "engName" | "url" | "instaLink" | "behanceLink";
    label: string;
    placeholder?: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
              placeholder={placeholder}
              className="w-full"
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "exposure" | "mainExposure";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">에이전시 등록</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex gap-5">
            <div className="flex w-full items-center gap-2">
              <TextField formField="korName" label="에이전시명(한글)" />
            </div>
            <div className="flex w-full items-center gap-2">
              <TextField formField="engName" label="에이전시명(영문)" />
            </div>
          </div>

          <FormField
            control={form.control}
            name="agencyType"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">
                  에이전시 종류
                </FormLabel>
                <FormControl>
                  <div className="flex w-full gap-6">
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">KOREA</Label>
                      <NCheckbox
                        checked={field.value === "KOREA"}
                        onCheckedChange={() => onAgencyTypeChange("KOREA")}
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">GLOBAL</Label>
                      <NCheckbox
                        checked={field.value === "GLOBAL"}
                        onCheckedChange={() => onAgencyTypeChange("GLOBAL")}
                      />
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="file"
            render={({ field }) => (
              <FormItem>
                <Label className="text-lg font-bold">
                  썸네일(870 X 240 72dpl 파일로 업로드 해주세요)
                </Label>
                {filePreview && (
                  <Label>
                    <img
                      src={filePreview}
                      alt="agency-thumbnail"
                      className="h-[220px] w-[330px] object-contain"
                    />
                  </Label>
                )}
                <FormControl>
                  <NInput
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TextField
            formField="url"
            label="홈페이지 URL"
            placeholder="http://example.com"
          />
          <TextField
            formField="instaLink"
            label="인스타그램"
            placeholder="http://example.com"
          />
          <TextField
            formField="behanceLink"
            label="비핸스"
            placeholder="http://example.com"
          />

          <AgencyFields form={form} />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <AgencyOfficeField form={form} />

          <div className="flex flex-col gap-4">
            <Label className="text-lg font-bold">Works</Label>
            <div className="flex max-w-sm items-center gap-2">
              <NInput
                ref={brandSearchRef}
                placeholder="검색"
                onKeyDown={(e) => {
                  if (e.nativeEvent.isComposing) return;
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setBrandPageNum(1);
                    setBrandSearchWord(brandSearchRef.current?.value ?? "");
                  }
                }}
              />
              <Button
                type="button"
                onClick={() => {
                  setBrandPageNum(1);
                  setBrandSearchWord(brandSearchRef.current?.value ?? "");
                }}
              >
                검색
              </Button>
            </div>
            <DataTable
              columns={BrandColumns}
              data={brands?.content ?? []}
              totalPages={brands?.totalPages}
              totalElements={brands?.totalElements}
              first={brands?.first}
              last={brands?.last}
              number={brands?.number}
              onClickNext={() => {
                if (brandPageNum === brands?.totalPages) return;
                setBrandPageNum((prev) => prev + 1);
              }}
              onClickPrev={() => {
                if (brandPageNum === 1) return;
                setBrandPageNum((prev) => prev - 1);
              }}
              rowSelection={selectedBrandsRow}
              setRowSelection={setSelectedBrandsRow}
            />
            <Button
              type="button"
              size={"sm"}
              className={cn("w-20 self-end")}
              onClick={() => {
                if (!selectedBrandsRow) return;
                const newSelectedBrand = Object.keys(selectedBrandsRow).map(
                  (key) => parseInt(key),
                );
                form.setValue(
                  "relatedBrandIds",
                  form
                    .getValues("relatedBrandIds")
                    .concat(newSelectedBrand)
                    .filter(
                      (value: number, index: number, self: number[]) =>
                        self.indexOf(value) === index,
                    ),
                );
                setSelectedBrands((prev) =>
                  prev
                    .concat(
                      brands?.content.filter((brand) =>
                        newSelectedBrand.includes(brand.id),
                      ) ?? [],
                    )
                    .filter(
                      (
                        value: IBrandListItemResponseDto,
                        index: number,
                        self: IBrandListItemResponseDto[],
                      ) => self.indexOf(value) === index,
                    ),
                );
                setSelectedBrandsRow({});
              }}
            >
              추가
            </Button>
            <BrandList
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              form={form}
            />
          </div>

          {isAdmin && <BooleanField formField="exposure" label="노출 여부" />}
          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}

          <div className="flex justify-end">
            <Button type="submit">등록</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default NewAgencyAddPage;

type AgencyFieldsProps = {
  form: UseFormReturn<IFormAgency>;
};

const AgencyFields = ({ form }: AgencyFieldsProps) => {
  const { data: agencyFields } = useGetAgencyFields();
  return (
    <FormField
      control={form.control}
      name="agencyFieldIds"
      render={() => (
        <FormItem>
          <div className="mb-4">
            <Label className="text-lg font-bold">업무 분야</Label>
          </div>
          <div className="grid grid-cols-3 gap-3 lg:grid-cols-4">
            {agencyFields?.map((agencyField) => (
              <FormField
                key={agencyField.id}
                control={form.control}
                name="agencyFieldIds"
                render={({ field }) => (
                  <FormItem
                    className="flex items-start gap-2 space-y-0"
                    key={agencyField.id}
                  >
                    <FormControl>
                      <NCheckbox
                        checked={field.value?.includes(agencyField.id)}
                        onCheckedChange={(checked) => {
                          checked
                            ? field.onChange([...field.value, agencyField.id])
                            : field.onChange(
                                field.value?.filter(
                                  (value: number) => value !== agencyField.id,
                                ),
                              );
                        }}
                      />
                    </FormControl>
                    <FormLabel>{agencyField.name}</FormLabel>
                  </FormItem>
                )}
              />
            ))}
          </div>
        </FormItem>
      )}
    />
  );
};

const AgencyOfficeField = ({ form }: { form: UseFormReturn<IFormAgency> }) => {
  const [name, setName] = useState<string>("");
  return (
    <FormField
      control={form.control}
      name="agencyOffices"
      render={({ field }) => (
        <FormItem>
          <div>
            <FormLabel className="text-lg font-bold">오피스</FormLabel>
            <div className="mt-1" />
            {field.value.map((office, index) => (
              <Badge
                variant={"outline"}
                key={index}
                className={cn("mx-1 px-4 py-2")}
              >
                <div className="flex items-center gap-2">
                  <div>{office.name}</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      form.setValue(
                        "agencyOffices",
                        field.value.filter(
                          (value) => value !== field.value[index],
                        ),
                      );
                    }}
                  >
                    <X className="h-4 w-4" />
                  </div>
                </div>
              </Badge>
            ))}
          </div>

          <FormControl>
            <div className="flex w-full max-w-sm items-center space-x-2">
              <NInput
                placeholder="이름 입력"
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.isComposing) return;
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (name) {
                      field.onChange([...field.value, { name }]);
                      setName("");
                    }
                  }
                }}
              />
              <Button
                type="button"
                className={cn("text-lg")}
                onClick={() => {
                  if (name) {
                    field.onChange([...field.value, { name }]);
                    setName("");
                  }
                }}
              >
                +
              </Button>
            </div>
          </FormControl>
        </FormItem>
      )}
    />
  );
};

const BrandList = ({
  selectedBrands,
  setSelectedBrands,
  form,
}: {
  selectedBrands: IBrandListItemResponseDto[];
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  form: ReturnType<typeof useForm<IFormAgency>>;
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {selectedBrands?.map((brand) => (
        <Card className="w-[250px]" key={brand.id}>
          <CardHeader>
            <CardTitle className={cn("flex justify-between text-lg")}>
              {brand.brandType === "KOREA" ? brand.korName : brand.engName}
              <div
                className="cursor-pointer"
                onClick={() => {
                  form.setValue(
                    "relatedBrandIds",
                    form
                      .getValues("relatedBrandIds")
                      .filter((value: number) => value !== brand.id),
                  );
                  setSelectedBrands(
                    selectedBrands.filter(
                      (selectedBrand) => selectedBrand.id !== brand.id,
                    ),
                  );
                }}
              >
                <X className="h-4 w-4" />
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              <img
                src={`${imageBaseUrl}/?fileId=${brand.thumbnailId}`}
                alt="brand thumbnail"
              />
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-announcement`}
                  className="font-bold"
                >
                  발표일
                </Label>
                <div id={`brand-${brand.id}-announcement`} className="text-sm">
                  {brand.announcementDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-update`}
                  className="font-bold"
                >
                  최종 입력 시간
                </Label>
                <div id={`brand-${brand.id}-update`} className="text-sm">
                  {brand.modifiedDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-exposure`}
                  className="font-bold"
                >
                  노출 여부
                </Label>
                <div id={`brand-${brand.id}-exposure`} className="text-sm">
                  {brand.exposure ? "Y" : "N"}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
