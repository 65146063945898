import { useState, useEffect } from "react";
import { Box, Container, Button, Divider } from "@mui/material";
import { useHistory } from "react-router";

import Pagination from "react-js-pagination";
import ToolBar from "src/components/common/Toolbar";
import { useDeleteTypefaces, useGetTypefaces } from "src/hooks/api/useTypeface";
import NewTypefaceList from "src/components/typeface/NewTypefaceList";
import { useSelector } from "react-redux";
import { useToast } from "src/components/ui/use-toast";

const NewTypefacePage = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [mainExposure, setMainExposure] = useState<boolean>();
  const [sort, setSort] = useState<
    "CREATE" | "NAME" | "LOGO_COUNT" | "UPDATE" | "DESIGNER"
  >("CREATE");
  const [selectedTypefaceIds, setSelectedTypefaceIds] = useState<number[]>([]);

  const {
    data: typefaces,
    // isLoading,
    // isError,
    refetch,
  } = useGetTypefaces({
    pageNum: pageNum,
    searchWord: searchWord,
    mainExposure: mainExposure,
    sort: sort,
  });

  useEffect(() => {
    refetch();
  }, [pageNum, sort, mainExposure, refetch]);

  const onClickSearch = () => {
    refetch();
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const onClickOrderByUpdate = () => {
    setSort("UPDATE");
    setMainExposure(undefined);
  };

  const onClickOrderByName = () => {
    setSort("NAME");
    setMainExposure(undefined);
  };

  const onClickOrderByLogoCount = () => {
    setSort("LOGO_COUNT");
    setMainExposure(undefined);
  };

  const onClickOrderByCreate = () => {
    setSort("CREATE");
    setMainExposure(undefined);
  };

  const onClickOrderByDesigner = () => {
    setSort("DESIGNER");
    setMainExposure(undefined);
  };

  const onClickMainExposure = () => {
    setMainExposure(true);
    setSort("CREATE");
  };

  const goBack = () => {
    window.location.reload();
  };

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { toast } = useToast();

  const mutation = useDeleteTypefaces();
  const deleteTypefaces = () => {
    mutation.mutate(selectedTypefaceIds, {
      onSuccess: () => {
        toast({
          title: "삭제되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.reload();
      },
      onError: (error) => {
        if (error.message.includes("403")) {
          toast({
            title: "권한이 없습니다.",
            description: "관리자만 삭제할 수 있습니다.",
            variant: "destructive",
          });
          return;
        }
      },
    });
  };
  return (
    <Box>
      <Container maxWidth={false}>
        <h2 className="text-xl font-bold">서체 정보 관리</h2>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/new/typeface/add")}
            >
              서체 등록
            </Button>
            <Box mr={2} />
            <Button variant="contained" onClick={() => goBack()}>
              검색 결과 초기화
            </Button>
          </Box>
          {isAdmin && (
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteTypefaces()}
              >
                서체 삭제
              </Button>
            </Box>
          )}
        </Box>
        <ToolBar
          search={searchWord}
          setSearch={setSearchWord}
          onClickSearch={onClickSearch}
        />
        <Divider />
        <Box mt={2} />
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByCreate}
          >
            등록 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByName}
          >
            가나다 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByDesigner}
          >
            제작사 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByLogoCount}
          >
            로고 많은 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByUpdate}
          >
            최종입력시간 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickMainExposure}
          >
            메인노출 서체 보기
          </Button>
        </Box>
        <Divider />
        <div className="py-4 text-xl font-semibold">
          전체 데이터 수 : {typefaces?.totalElements}개
        </div>
        <NewTypefaceList
          typefaceList={typefaces?.content || []}
          selectedTypefaceIds={selectedTypefaceIds}
          setSelectedTypefaceIds={setSelectedTypefaceIds}
        />
        <Pagination
          activePage={pageNum}
          itemsCountPerPage={10}
          totalItemsCount={typefaces?.totalElements || 0}
          pageRangeDisplayed={10}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </Container>
    </Box>
  );
};

export default NewTypefacePage;
