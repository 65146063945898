import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { api } from "src/api";

interface useGetBrandsProps {
  pageNum: number;
  sort: "CREATE" | "NAME" | "ANNOUNCEMENT";
  brandType?: "KOREA" | "GLOBAL";
  exposure?: boolean;
  modify?: boolean;
  recommend?: boolean;
  searchWord?: string;
  pageSize?: number;
}

interface useGetBrandTagsProps {
  pageNum: number;
  pageSize: number;
  searchWord?: string;
}

export function getBrands({ ...props }: useGetBrandsProps) {
  return api.get<IPage<IBrandListItemResponseDto>>("/api/v2/admin/brands", {
    params: props,
  });
}

export function useGetBrands({ ...props }: useGetBrandsProps) {
  return useQuery({
    queryKey: ["brands", props],
    queryFn: async () => {
      const response = await api.get<IPage<IBrandListItemResponseDto>>(
        "/api/v2/admin/brands",
        {
          params: props,
        },
      );
      return response.data;
    },
    staleTime: 1000 * 60,
    enabled: false,
  });
}

export function useDeleteBrands() {
  return useMutation({
    mutationFn: (brandIds: number[]) => {
      return api.delete("/api/v2/admin/brands/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          brandIds: brandIds,
        },
      });
    },
  });
}

export function useCreateBrand() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/brands", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetBrandDetail(brandId: number) {
  return useQuery({
    queryKey: ["brand", "detail", brandId],
    queryFn: async () => {
      const response = await api.get<IBrandDetailResponseDto>(
        `/api/v2/admin/brands/${brandId}`,
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateBrand(brandId: number) {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.put(`/api/v2/admin/brands/${brandId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetBrandTags({ ...props }: useGetBrandTagsProps) {
  return useInfiniteQuery({
    queryKey: ["brandTags", props],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await api.get<IPage<IBrandCommonPropertyDto>>(
        "/api/v2/admin/brands/tags",
        {
          params: {
            ...props,
            pageNum: pageParam,
          },
        },
      );
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.number + 2;
    },
    enabled: props.searchWord !== undefined && props.searchWord !== "",
  });
}

export function usePostBrandTag() {
  return useMutation({
    mutationFn: (data: { name: string }) => {
      return api.post("/api/v2/admin/brands/tags", data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useDeleteBrandTag() {
  return useMutation({
    mutationFn: (tagId: number) => {
      return api.delete(`/api/v2/admin/brands/tags/${tagId}`, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetBrandTagsInPage({ ...props }: useGetBrandTagsProps) {
  return useQuery({
    queryKey: ["brandTags", props],
    queryFn: async () => {
      const response = await api.get<IPage<IBrandCommonPropertyDto>>(
        "/api/v2/admin/brands/tags/page",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateBrandTag(tagId: number) {
  return useMutation({
    mutationFn: (data: { name: string }) => {
      return api.put(`/api/v2/admin/brands/tags/${tagId}`, data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}
