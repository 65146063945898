import React from "react";
import styled from "styled-components";
import StylesProvider from '@mui/styles/StylesProvider';

const MinHeightContainer = styled.div`
  margin-top: 70px;
  height: auto;
`;

const LoginMain = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <MinHeightContainer>{children}</MinHeightContainer>
    </StylesProvider>
  );
};

export default LoginMain;
