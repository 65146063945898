import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@mui/material";
import { useHistory } from "react-router";

const TableHeadColor = styled(TableHead)`
  background-color: #e2e2e2;
`;

const TableCellCenter = styled(TableCell)`
  text-align: center;
  :not(:last-child) {
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
  }
  :not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;

interface ITrendKeywordListProps {
  trendKeywordList: ITrendKeywordListItem[];
  trendKeywordsTotal: number;
  selectedTrendKeywordIds: number[];
  setSelectedTrendKeywordIds: Dispatch<SetStateAction<number[]>>;
}

const TrendKeywordList = ({
  trendKeywordList,
  trendKeywordsTotal,
  selectedTrendKeywordIds,
  setSelectedTrendKeywordIds,
}: ITrendKeywordListProps) => {
  const history = useHistory();

  const handleSelectAll = (event: any) => {
    let newSelectedCustomerIds: number[];

    if (event.target.checked) {
      newSelectedCustomerIds = trendKeywordList.map(
        (trendKeyword) => trendKeyword.id,
      );
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedTrendKeywordIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedTrendKeywordIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTrendKeywordIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTrendKeywordIds.slice(1),
      );
    } else if (selectedIndex === selectedTrendKeywordIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTrendKeywordIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTrendKeywordIds.slice(0, selectedIndex),
        selectedTrendKeywordIds.slice(selectedIndex + 1),
      );
    }
    setSelectedTrendKeywordIds(newSelectedCustomerIds);
  };

  return (
    <>
      <Card>
        {trendKeywordList.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={3}
            mb={3}
          >
            <b>등록된 컨텐츠가 없습니다</b>
          </Box>
        ) : (
          <>
            <Box sx={{ minWidth: 600 }}>
              <Table>
                <TableHeadColor>
                  <TableRow>
                    <TableCellCenter padding="checkbox">
                      <Checkbox
                        checked={
                          selectedTrendKeywordIds.length ===
                          trendKeywordList.length
                        }
                        color="primary"
                        indeterminate={
                          selectedTrendKeywordIds.length > 0 &&
                          selectedTrendKeywordIds.length <
                            trendKeywordList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCellCenter>
                    <TableCellCenter align="center">No.</TableCellCenter>
                    <TableCellCenter align="center">한글</TableCellCenter>
                    <TableCellCenter align="center">영문</TableCellCenter>
                    <TableCellCenter align="center">로고 개수</TableCellCenter>
                    <TableCellCenter align="center">
                      최종입력시간
                    </TableCellCenter>
                    <TableCellCenter align="center">메인노출</TableCellCenter>
                    <TableCellCenter align="center" />
                  </TableRow>
                </TableHeadColor>
                <TableBody>
                  {trendKeywordList.map((trendKeyword, index) => (
                    <TableRow hover key={trendKeyword.id}>
                      <TableCellCenter padding="checkbox">
                        <Checkbox
                          checked={
                            selectedTrendKeywordIds.indexOf(trendKeyword.id) !==
                            -1
                          }
                          onChange={(event) =>
                            handleSelectOne(event, trendKeyword.id)
                          }
                          value="true"
                          color="primary"
                        />
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {trendKeyword.id}
                      </TableCellCenter>
                      <TableCellCenter align="left">
                        {trendKeyword.korName}
                      </TableCellCenter>
                      <TableCellCenter align="left">
                        {trendKeyword.engName}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {trendKeyword.relatedLogoCount}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {trendKeyword.updateAt}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {trendKeyword.mainExposure ? "Y" : "N"}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/new/trend-keywords/${trendKeyword.id}/edit`,
                            )
                          }
                        >
                          수정
                        </Button>
                      </TableCellCenter>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
    </>
  );
};

export default TrendKeywordList;
