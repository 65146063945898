import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "src/api";

interface useGetPinnedCaseStudiesProps {
  pageNum: number;
  pageSize: number;
  mainExposure?: boolean;
  exposure?: boolean;
  searchWord?: string;
}

export function useDeletePinnedCaseStudies() {
  return useMutation({
    mutationFn: (featureIds: number[]) => {
      return api.delete("/api/v2/admin/pinnedCaseStudies/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: featureIds,
        },
      });
    },
  });
}

export function useGetPinnedCaseStudies({
  ...props
}: useGetPinnedCaseStudiesProps) {
  return useQuery({
    queryKey: ["pinnedCaseStudies", props],
    queryFn: async () => {
      const response = await api.get<
        IPage<IPinnedCaseStudyListItemResponseDto>
      >("/api/v2/admin/pinnedCaseStudies", {
        params: props,
      });
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useCreatePinnedCaseStudy() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/pinnedCaseStudies", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetPinnedCaseStudyDetail(id: number) {
  return useQuery({
    queryKey: ["pinnedCaseStudy", id],
    queryFn: async () => {
      const response = await api.get<IPinnedCaseStudyDetailDetailResponseDto>(
        `/api/v2/admin/pinnedCaseStudies/${id}`,
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdatePinnedCaseStudy(pinnedCaseStudyId: number) {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.put(
        `/api/v2/admin/pinnedCaseStudies/${pinnedCaseStudyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("access_token") ?? "",
          },
        },
      );
    },
  });
}

export function useHidePinnedCaseStudies() {
  return useMutation({
    mutationFn: (pinnedCaseStudyIds: number[]) => {
      return api.put("/api/v2/admin/pinnedCaseStudies/multiple/hide", {
        ids: pinnedCaseStudyIds,
      });
    },
  });
}

export function useExposePinnedCaseStudies() {
  return useMutation({
    mutationFn: (pinnedCaseStudyIds: number[]) => {
      return api.put("/api/v2/admin/pinnedCaseStudies/multiple/expose", {
        ids: pinnedCaseStudyIds,
      });
    },
  });
}
