import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { imageBaseUrl } from "src/constants";
import { X } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { IFormTrendKeyword } from "src/pages/new/trendKeyword/TrendKeywordEditPage";
import { cn } from "src/lib/utils";
import { Label } from "../ui/label";

interface IBrandItemProps {
  brand: IBrandListItemResponseDto;
  form: ReturnType<typeof useForm<IFormTrendKeyword>>;
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  selectedBrands: IBrandListItemResponseDto[];
}

const TrendKeywordBrandItem = ({ ...props }: IBrandItemProps) => {
  return (
    <Card className="w-[250px]" key={props.brand.id}>
      <CardHeader>
        <CardTitle className={cn("flex justify-between text-lg")}>
          {props.brand.brandType === "KOREA"
            ? props.brand.korName
            : props.brand.engName}
          <div
            className="cursor-pointer"
            onClick={() => {
              props.form.setValue(
                "brandIds",
                props.form
                  .getValues("brandIds")
                  .filter((value: number) => value !== props.brand.id),
              );
              props.setSelectedBrands(
                props.selectedBrands.filter(
                  (selectedBrand) => selectedBrand.id !== props.brand.id,
                ),
              );
            }}
          >
            <X className="h-4 w-4" />
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid w-full items-center gap-4">
          {props.brand.thumbnailId && (
            <img
              src={`${imageBaseUrl}/?fileId=${props.brand.thumbnailId}`}
              alt="brand thumbnail"
            />
          )}

          <div className="flex flex-col space-y-1.5">
            <Label
              htmlFor={`brand-${props.brand.id}-announcement`}
              className="font-bold"
            >
              발표일
            </Label>
            <div
              id={`brand-${props.brand.id}-announcement`}
              className="text-sm"
            >
              {props.brand.announcementDate}
            </div>
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label
              htmlFor={`brand-${props.brand.id}-update`}
              className="font-bold"
            >
              최종 입력 시간
            </Label>
            <div id={`brand-${props.brand.id}-update`} className="text-sm">
              {props.brand.modifiedDate}
            </div>
          </div>
          <div className="flex flex-col space-y-1.5">
            <Label
              htmlFor={`brand-${props.brand.id}-exposure`}
              className="font-bold"
            >
              노출 여부
            </Label>
            <div id={`brand-${props.brand.id}-exposure`} className="text-sm">
              {props.brand.exposure ? "Y" : "N"}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default TrendKeywordBrandItem;
