import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "src/api";

interface useGetSimpleAgenciesProps {
  pageNum: number;
  pageSize: number;
  searchWord?: string;
}

interface useGetAgenciesProps {
  pageNum: number;
  pageSize: number;
  searchWord?: string;
  sort: "CREATE" | "NAME" | "LOGO_COUNT" | "UPDATE";
  mainExposure?: boolean;
  exposure?: boolean;
}

export function useGetSimpleAgencies({ ...props }: useGetSimpleAgenciesProps) {
  return useQuery({
    queryKey: ["agencies", "simple", props],
    queryFn: async () => {
      const response = await api.get<IPage<ISimpleAgencyDto>>(
        "/api/v2/admin/agencies/simple",
        {
          params: props,
        },
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
    enabled: false,
    refetchOnWindowFocus: false,
  });
}

export function useGetAgencies({ ...props }: useGetAgenciesProps) {
  return useQuery({
    queryKey: ["agencies", props],
    queryFn: async () => {
      const response = await api.get<IPage<IAgencyListItemResponseDto>>(
        "/api/v2/admin/agencies",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
    refetchOnWindowFocus: false,
  });
}

export function useDeleteAgencies() {
  return useMutation({
    mutationFn: (agencyIds: number[]) => {
      return api.delete("/api/v2/admin/agencies/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: agencyIds,
        },
      });
    },
  });
}

export function useCreateAgency() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/agencies", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetAgencyDetail(agencyId: number) {
  return useQuery({
    queryKey: ["agencies", "detail", agencyId],
    queryFn: async () => {
      const response = await api.get<IAgencyDetailResponseDto>(
        `/api/v2/admin/agencies/${agencyId}`,
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateAgency(agencyId: number) {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.put(`/api/v2/admin/agencies/${agencyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}
