import { useState, useEffect } from "react";

import { Box, Container, Button } from "@mui/material";
import { useHistory } from "react-router";
import Pagination from "react-js-pagination";
import BrandToolBar from "src/components/LogoPage/BrandToolBar";
import BrandList from "src/components/LogoPage/BrandList";
import { useDeleteBrands, useGetBrands } from "src/hooks/api/useBrand";
import { parseISO } from "date-fns";
import { imageBaseUrl } from "src/constants";
import { convertDate } from "src/lib/utils";
import { useSelector } from "react-redux";
import { useToast } from "src/components/ui/use-toast";

const BrandPage = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [exposure, setExposure] = useState<boolean>();
  const [recommend, setRecommend] = useState<boolean>();
  const [modify, setModify] = useState<boolean>();
  const [sort, setSort] = useState<"CREATE" | "NAME" | "ANNOUNCEMENT">(
    "CREATE",
  );
  const [brandType, setBrandType] = useState<"KOREA" | "GLOBAL">();
  const [selectedBrandIds, setSelectedBrandIds] = useState<number[]>([]);

  const { data: brands, refetch } = useGetBrands({
    pageNum: pageNum,
    searchWord: searchWord,
    sort: sort,
    exposure: exposure,
    recommend: recommend,
    modify: modify,
    brandType: brandType,
  });

  useEffect(() => {
    refetch();
  }, [pageNum, sort, exposure, recommend, modify]);

  const onClickOrderByUpdate = () => {
    setExposure(undefined);
    setRecommend(undefined);
    setModify(undefined);
    setBrandType(undefined);
    setSort("CREATE");
  };

  const onClickOrderByKoreanName = () => {
    setExposure(undefined);
    setRecommend(undefined);
    setModify(undefined);
    setBrandType("KOREA");
    setSort("NAME");
  };

  const onClickOrderByGlobalName = () => {
    setExposure(undefined);
    setRecommend(undefined);
    setModify(undefined);
    setBrandType("GLOBAL");
    setSort("NAME");
  };

  const onClickHideBrand = () => {
    setExposure(false);
    setRecommend(undefined);
    setModify(undefined);
    setBrandType(undefined);
    setSort("CREATE");
  };

  const onClickRecommendBrand = () => {
    setExposure(undefined);
    setRecommend(true);
    setModify(undefined);
    setBrandType(undefined);
    setSort("CREATE");
  };

  const onClickModifyBrand = () => {
    setExposure(undefined);
    setRecommend(undefined);
    setBrandType(undefined);
    setModify(true);
    setSort("CREATE");
  };

  const onClickOrderByAnnouncement = () => {
    setExposure(undefined);
    setRecommend(undefined);
    setModify(undefined);
    setBrandType(undefined);
    setSort("ANNOUNCEMENT");
  };

  const onClickSearch = () => {
    refetch();
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const goBack = () => {
    window.location.reload();
  };

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { toast } = useToast();

  const mutation = useDeleteBrands();
  const deleteLogo = () => {
    mutation.mutate(selectedBrandIds, {
      onSuccess: () => {
        toast({
          title: "삭제되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.reload();
      },
      onError: (error) => {
        if (error.message.includes("403")) {
          toast({
            title: "권한이 없습니다.",
            description: "관리자만 삭제할 수 있습니다.",
            variant: "destructive",
          });
          return;
        }
      },
    });
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <h2 className="text-xl font-bold">로고 컨텐츠 관리</h2>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/new/brands/add")}
            >
              로고 등록
            </Button>
            <Box mr={2} />
            <Button variant="contained" onClick={() => goBack()}>
              검색 결과 초기화
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              onClick={() => history.push("/new/brands/tags")}
            >
              태그 관리
            </Button>
          </Box>
          {isAdmin && (
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteLogo()}
              >
                로고 삭제
              </Button>
            </Box>
          )}
        </Box>
        <BrandToolBar
          search={searchWord}
          setSearch={setSearchWord}
          onClickSearch={onClickSearch}
        />
        <Box mt={2} />
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByUpdate}
          >
            등록 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByKoreanName}
          >
            가나다 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByGlobalName}
          >
            ABC 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByAnnouncement}
          >
            발표년도 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickHideBrand}
          >
            숨김 로고 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickModifyBrand}
          >
            보완 로고 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickRecommendBrand}
          >
            추천 로고 보기
          </Button>
        </Box>
        <div className="text-xl font-semibold">
          전체 데이터 수 : {brands?.totalElements}개
        </div>
        <Box sx={{ pt: 3, mb: 3 }}>
          {brands?.content !== undefined ? (
            <>
              {brands?.totalElements === 0 ? (
                <Box display="flex" justifyContent="center">
                  <h2>검색 결과가 없습니다.</h2>
                </Box>
              ) : (
                <>
                  <BrandList
                    brandList={
                      brands?.content?.map((brand) => ({
                        id: brand.id,
                        name:
                          brand.brandType === "KOREA"
                            ? brand.korName
                            : brand.engName,
                        client: brand.client,
                        announcementDate: brand.announcementDate,
                        createdDate: convertDate(brand.createdDate),
                        exposure: brand.exposure,
                        modify: brand.modify,
                        thumbnailUrl: `${imageBaseUrl}/?fileId=${brand.thumbnailId}`,
                        brandType: brand.brandType,
                      })) ?? []
                    }
                    brandsTotal={brands?.totalElements ?? 0}
                    selectedBrandIds={selectedBrandIds}
                    setSelectedBrandIds={setSelectedBrandIds}
                  />
                  <Pagination
                    activePage={pageNum}
                    itemsCountPerPage={20}
                    totalItemsCount={brands?.totalElements ?? 0}
                    pageRangeDisplayed={10}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={handlePageChange}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <Box display="flex" justifyContent="center">
                <h2>에러가 발생했습니다. 새로고침 해주시기 바랍니다.</h2>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default BrandPage;
