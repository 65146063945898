import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@mui/material";

import { useHistory } from "react-router";

const TableHeadColor = styled(TableHead)`
  background-color: #e2e2e2;
`;

const TableCellCenter = styled(TableCell)`
  :not(:last-child) {
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
  }
  :not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;

const Thumbnail = styled.img`
  border: 1px solid #dddddd;
  width: 300px;
  height: 150px;
  object-fit: contain;
`;

interface IExternalFeatureListProps {
  externalFeatureList: IExternalFeatureListItem[];
  selectedExternalFeatureIds: number[];
  setSelectedExternalFeatureIds: Dispatch<SetStateAction<number[]>>;
}

const ExternalFeatureList = ({
  externalFeatureList,
  selectedExternalFeatureIds,
  setSelectedExternalFeatureIds,
}: IExternalFeatureListProps) => {
  const history = useHistory();

  const handleSelectAll = (event: any) => {
    let newSelectedCustomerIds: number[];

    if (event.target.checked) {
      newSelectedCustomerIds = externalFeatureList.map(
        (externalFeature) => externalFeature.id,
      );
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedExternalFeatureIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedExternalFeatureIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedExternalFeatureIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedExternalFeatureIds.slice(1),
      );
    } else if (selectedIndex === selectedExternalFeatureIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedExternalFeatureIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedExternalFeatureIds.slice(0, selectedIndex),
        selectedExternalFeatureIds.slice(selectedIndex + 1),
      );
    }
    setSelectedExternalFeatureIds(newSelectedCustomerIds);
  };

  return (
    <>
      <Card>
        {externalFeatureList.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={3}
            mb={3}
          >
            <b>등록된 컨텐츠가 없습니다</b>
          </Box>
        ) : (
          <>
            <Box sx={{ minWidth: 600 }}>
              <Table>
                <TableHeadColor>
                  <TableRow>
                    <TableCellCenter padding="checkbox">
                      <Checkbox
                        checked={
                          selectedExternalFeatureIds.length > 0 &&
                          selectedExternalFeatureIds.length <
                            externalFeatureList.length
                        }
                        color="primary"
                        indeterminate={
                          selectedExternalFeatureIds.length > 0 &&
                          selectedExternalFeatureIds.length <
                            externalFeatureList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCellCenter>
                    <TableCellCenter align="center">No.</TableCellCenter>
                    <TableCellCenter align="center">메인노출</TableCellCenter>
                    <TableCellCenter align="center">썸네일</TableCellCenter>
                    <TableCellCenter align="center">출처</TableCellCenter>
                    <TableCellCenter align="center">제목</TableCellCenter>
                    <TableCellCenter align="center">설명문구</TableCellCenter>
                    <TableCellCenter align="center">등록일자</TableCellCenter>
                    <TableCellCenter align="center">노출 여부</TableCellCenter>
                    <TableCellCenter align="center" />
                  </TableRow>
                </TableHeadColor>
                <TableBody>
                  {externalFeatureList &&
                    externalFeatureList.map((externalFeature, index) => (
                      <TableRow hover key={externalFeature.id}>
                        <TableCellCenter padding="checkbox">
                          <Checkbox
                            checked={
                              selectedExternalFeatureIds.indexOf(
                                externalFeature.id,
                              ) !== -1
                            }
                            onChange={(event) =>
                              handleSelectOne(event, externalFeature.id)
                            }
                            value="true"
                            color="primary"
                          />
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {externalFeature.id}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {externalFeature.mainExposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Box display="flex" justifyContent="center">
                            {externalFeature.thumbnailUrl && (
                              <Thumbnail
                                key={index}
                                src={externalFeature.thumbnailUrl}
                              />
                            )}
                          </Box>
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {externalFeature.source}
                        </TableCellCenter>
                        <TableCellCenter>
                          {externalFeature.title}
                        </TableCellCenter>
                        <TableCellCenter>
                          {externalFeature.description}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {externalFeature.createAt}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {externalFeature.exposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              history.push(
                                `/new/features/external/${externalFeature.id}/edit`,
                              )
                            }
                          >
                            수정
                          </Button>
                        </TableCellCenter>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={3}></Box>
    </>
  );
};

export default ExternalFeatureList;
