import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.snow.css";

import { ImageResize } from "quill-image-resize-module-ts";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGetBrands } from "src/hooks/api/useBrand";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { Input as NInput } from "src/components/ui/input";
import { Textarea } from "src/components/ui/textarea";
import { cn } from "src/lib/utils";
import { Label } from "src/components/ui/label";
import {
  useGetFeautreDetail,
  useInsertContentImage,
  useUpdateBrandBFeature,
} from "src/hooks/api/useFeatures";
import { imageBaseUrl } from "src/constants";
import { Divider } from "@mui/material";
import { Button } from "src/components/ui/button";
import { DataTable } from "src/components/common/DataTable";
import { BrandColumns } from "src/components/common/BrandColumns";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { X } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { useParams } from "react-router";
import FeatureTagList from "src/components/brandbFeature/FeatureTagList";
import FeatureTagSearch from "src/components/brandbFeature/FeatureTagSearch";
import { useSelector } from "react-redux";

const fontSizeArr = ["14px", "16px", "18px"];

Quill.register("modules/ImageResize", ImageResize);

interface IFormBrandBFeature {
  title: string;
  description: string;
  thumbnail: File;
  contents: string;
  relatedBrandIds: number[];
  tagIds: number[];
  publishedDate: string;
  mainExposure: boolean;
  exposure: boolean;
  isPromoted: boolean;
}

const BrandBFeatureEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: feature, refetch: refetchFeature } = useGetFeautreDetail(
    parseInt(id),
  );
  const form = useForm<IFormBrandBFeature>({
    defaultValues: {
      title: "",
      description: "",
      contents: "",
      relatedBrandIds: [],
      tagIds: [],
      publishedDate: "",
      exposure: false,
      mainExposure: false,
      isPromoted: false,
    },
  });

  useEffect(() => {
    refetchFeature();
  }, []);

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  useEffect(() => {
    if (feature) {
      form.setValue("title", feature.title);
      form.setValue("description", feature.description);
      form.setValue("contents", feature.contents);
      form.setValue("publishedDate", feature.publishedDate);
      form.setValue("exposure", feature.exposure);
      form.setValue("mainExposure", feature.mainExposure);
      form.setValue(
        "relatedBrandIds",
        feature.relatedBrands.map((brand) => brand.id),
      );
      setThumbnailPreview(`${imageBaseUrl}/?fileId=${feature.thumbnailId}`);
      setSelectedBrands(feature.relatedBrands);
      setSelectedTags(feature.tags);
    }
  }, [feature]);

  const editorRef = useRef<ReactQuill>();

  const [selectedBrands, setSelectedBrands] = useState<
    IBrandListItemResponseDto[]
  >([]);

  const [selectedTags, setSelectedTags] = useState<
    { id: number; name: string }[]
  >([]);

  const [thumbnail, setThumbnail] = useState<File>();

  const [thumbnailPreview, setThumbnailPreview] = useState<string>("");

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileArr = e.target.files;

    let fileURLs: Array<string> = [];
    let file;

    if (!fileArr) return;

    setThumbnail((prev) => {
      return fileArr[0];
    });

    file = fileArr[0];
    let reader = new FileReader();
    reader.onload = () => {
      fileURLs[0] = reader.result ? (reader.result as string) : "";
      setThumbnailPreview((prev) => {
        return fileURLs[0];
      });
    };
    reader.readAsDataURL(file);
  };

  const insertImageMutation = useInsertContentImage();

  const imageHandler = () => {
    console.log("에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!");

    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.addEventListener("change", async () => {
      console.log("온체인지");
      const file = input.files ? input.files[0] : null;
      const formData = new FormData();

      if (file === null) {
        alert("파일 선택 중 오류가 발생했습니다.");
        return;
      }

      formData.append("image", file);
      try {
        insertImageMutation.mutate(formData, {
          onSuccess: (result) => {
            const IMG_URL = `${imageBaseUrl}/?fileId=${result.data}`;
            const editor = editorRef.current?.getEditor();
            const range = editor?.getSelection();
            if (!range || !range.index) {
              alert("범위를 찾을 수 없습니다.");
              return;
            }
            editor?.insertEmbed(range?.index, "image", IMG_URL);
          },
          onError: () => {
            alert("이미지 업로드에 실패했습니다.");
          },
        });
      } catch (error) {
        console.log("실패했어요ㅠ");
      }
    });
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ size: fontSizeArr }],
          ["bold", "italic", "underline", "strike"],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            {
              color: [
                "#000000",
                "#e60000",
                "#ff9900",
                "#ffff00",
                "#008a00",
                "#0066cc",
                "#c285ff",
                "#888888",
                "#a10000",
                "#b26b00",
                "#b2b200",
                "#006100",
                "#0047b2",
                "#6b24b2",
              ],
            },
          ],
          ["link", "image", "video"],
        ],
        handlers: {
          // 이미지 처리는 우리가 직접 imageHandler라는 함수로 처리할 것이다.
          image: imageHandler,
        },
        clipboard: {
          matchVisual: false,
        },
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    };
  }, []);

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
    "color",
    "imageResize",
  ];

  const mutation = useUpdateBrandBFeature(parseInt(id));

  const onSubmit = (data: IFormBrandBFeature) => {
    const formData = new FormData();
    if (thumbnail) {
      data.thumbnail = thumbnail;
      formData.append("thumbnail", thumbnail);
    }

    selectedTags.forEach((tag) => {
      data.tagIds.push(tag.id);
    });

    formData.append(
      "feature",
      new Blob([JSON.stringify(data)], { type: "application/json" }),
    );

    mutation.mutate(formData, {
      onSuccess: () => {
        alert("수정되었습니다.");
        window.location.replace("/new/features/brandb");
      },
      onError: () => {
        alert("수정에 실패하였습니다.");
      },
    });
  };

  const TextField = ({
    formField,
    label,
  }: {
    formField: "title";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "mainExposure" | "exposure" | "isPromoted";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">컨텐츠 수정</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <TextField formField="title" label="제목" />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="thumbnail"
            render={({ field }) => (
              <FormItem>
                <Label className="text-lg font-bold">
                  썸네일 (가로 960px 이상 세로 길이 자유 / 72dpi 파일로 업로드
                  해주세요)
                </Label>
                {thumbnailPreview && (
                  <Label>
                    <img
                      src={thumbnailPreview}
                      alt="brand-thumbnail"
                      className="h-[220px] w-[330px] object-contain"
                    />
                  </Label>
                )}
                <FormControl>
                  <NInput
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(e) => {
                      handleImageUpload(e);
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <ReactQuill
            theme="snow"
            value={form.getValues("contents")}
            onChange={(value) => form.setValue("contents", value)}
            ref={(el) => {
              if (el !== null) {
                editorRef.current = el;
              }
            }}
            modules={modules}
            formats={formats}
            placeholder="내용을 입력해주세요."
            style={{
              width: "1000px",
              backgroundColor: "white",
              height: "600px",
            }}
            defaultValue={form.getValues("contents")}
          />

          <div className="w-full py-3">
            <Divider />
          </div>

          <BrandSearch setSelectedBrands={setSelectedBrands} form={form} />
          <BrandList
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            form={form}
          />

          <FormField
            control={form.control}
            name="publishedDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">발행일</FormLabel>
                <FormControl>
                  <NInput
                    type="month"
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("publishedDate", e.target.value)
                    }
                    className="w-40"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FeatureTagList
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          <FeatureTagSearch
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />

          {isAdmin && <BooleanField formField="exposure" label="노출 여부" />}
          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}
          <BooleanField formField="isPromoted" label="끌어올림 여부" />

          <div className="flex justify-end">
            <Button type="submit">수정</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default BrandBFeatureEditPage;

const BrandList = ({
  selectedBrands,
  setSelectedBrands,
  form,
}: {
  selectedBrands: IBrandListItemResponseDto[];
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  form: ReturnType<typeof useForm<IFormBrandBFeature>>;
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {selectedBrands?.map((brand) => (
        <Card className="w-[250px]" key={brand.id}>
          <CardHeader>
            <CardTitle className={cn("flex justify-between text-lg")}>
              {brand.brandType === "KOREA" ? brand.korName : brand.engName}
              <div
                className="cursor-pointer"
                onClick={() => {
                  form.setValue(
                    "relatedBrandIds",
                    form
                      .getValues("relatedBrandIds")
                      .filter((value: number) => value !== brand.id),
                  );
                  setSelectedBrands(
                    selectedBrands.filter(
                      (selectedBrand) => selectedBrand.id !== brand.id,
                    ),
                  );
                }}
              >
                <X className="h-4 w-4" />
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              <img
                src={`${imageBaseUrl}/?fileId=${brand.thumbnailId}`}
                alt="brand thumbnail"
              />
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-announcement`}
                  className="font-bold"
                >
                  발표일
                </Label>
                <div id={`brand-${brand.id}-announcement`} className="text-sm">
                  {brand.announcementDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-update`}
                  className="font-bold"
                >
                  최종 입력 시간
                </Label>
                <div id={`brand-${brand.id}-update`} className="text-sm">
                  {brand.modifiedDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-exposure`}
                  className="font-bold"
                >
                  노출 여부
                </Label>
                <div id={`brand-${brand.id}-exposure`} className="text-sm">
                  {brand.exposure ? "Y" : "N"}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

const BrandSearch = ({
  setSelectedBrands,
  form,
}: {
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  form: ReturnType<typeof useForm<IFormBrandBFeature>>;
}) => {
  const brandSearchRef = useRef<HTMLInputElement>(null);
  const [brandSearchWord, setBrandSearchWord] = useState("");
  const [brandPageNum, setBrandPageNum] = useState(1);
  const { data: brands, refetch: refetchBrands } = useGetBrands({
    pageNum: brandPageNum,
    pageSize: 10,
    sort: "CREATE",
    searchWord: brandSearchWord,
  });
  useEffect(() => {
    if (!brandSearchWord) return;
    refetchBrands();
  }, [brandPageNum, brandSearchWord]);

  const [selectedBrandsRow, setSelectedBrandsRow] = useState({});
  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">트렌드 연관 브랜드</Label>

      <div className="flex max-w-sm items-center gap-2">
        <NInput
          ref={brandSearchRef}
          placeholder="검색"
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) return;
            if (e.key === "Enter") {
              e.preventDefault();
              setBrandPageNum(1);
              setBrandSearchWord(brandSearchRef.current?.value ?? "");
            }
          }}
        />
        <Button
          type="button"
          onClick={() => {
            setBrandPageNum(1);
            setBrandSearchWord(brandSearchRef.current?.value ?? "");
          }}
        >
          검색
        </Button>
      </div>
      <DataTable
        columns={BrandColumns}
        data={brands?.content ?? []}
        totalPages={brands?.totalPages}
        totalElements={brands?.totalElements}
        first={brands?.first}
        last={brands?.last}
        number={brands?.number}
        onClickNext={() => {
          if (brandPageNum === brands?.totalPages) return;
          setBrandPageNum((prev) => prev + 1);
        }}
        onClickPrev={() => {
          if (brandPageNum === 1) return;
          setBrandPageNum((prev) => prev - 1);
        }}
        rowSelection={selectedBrandsRow}
        setRowSelection={setSelectedBrandsRow}
      />
      <Button
        type="button"
        size={"sm"}
        className={cn("w-20 self-end")}
        onClick={() => {
          if (!selectedBrandsRow) return;
          const newSelectedBrand = Object.keys(selectedBrandsRow).map((key) =>
            parseInt(key),
          );
          form.setValue(
            "relatedBrandIds",
            form
              .getValues("relatedBrandIds")
              .concat(newSelectedBrand)
              .filter(
                (value: number, index: number, self: number[]) =>
                  self.indexOf(value) === index,
              ),
          );
          setSelectedBrands((prev) =>
            prev
              .concat(
                brands?.content.filter((brand) =>
                  newSelectedBrand.includes(brand.id),
                ) ?? [],
              )
              .filter(
                (
                  value: IBrandListItemResponseDto,
                  index: number,
                  self: IBrandListItemResponseDto[],
                ) => self.indexOf(value) === index,
              ),
          );
          setSelectedBrandsRow({});
        }}
      >
        추가
      </Button>
    </div>
  );
};
