import { useToast } from "../ui/use-toast";
import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Loader2 } from "lucide-react";
import { useUpdateFeatureTag } from "src/hooks/api/useFeatures";

export const FeatureTagEditButton = ({
  id,
  name,
  refetch,
}: {
  id: string;
  name: string;
  refetch?: () => void;
}) => {
  const mutation = useUpdateFeatureTag(parseInt(id));
  const { toast } = useToast();
  const [tagName, setTagName] = useState(name);
  const [open, setOpen] = useState(false);

  const onSubmit = () => {
    if (tagName === "") {
      toast({
        title: "태그 이름을 입력해주세요.",
        description: "태그 이름을 입력해주세요.",
        variant: "destructive",
      });
      return;
    }
    mutation.mutate(
      { name: tagName },
      {
        onSuccess: () => {
          toast({
            title: "태그가 수정되었습니다.",
            description: `${tagName} 태그가 수정되었습니다.`,
          });
          setOpen(false);
          refetch && refetch();
        },
        onError: (error) => {
          if (error.message.includes("409")) {
            toast({
              title: "태그 수정에 실패했습니다.",
              description: "이미 존재하는 태그입니다.",
              variant: "destructive",
            });
          } else {
            toast({
              title: "태그 수정에 실패했습니다.",
              description: error.message,
              variant: "destructive",
            });
          }
          setOpen(false);
        },
      },
    );
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(val) => {
        setTagName(name);
        setOpen(val);
      }}
      defaultOpen={false}
    >
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>수정</Button>
      </DialogTrigger>
      <DialogContent className="max-w-[400px]">
        <DialogHeader>
          <DialogTitle>태그 수정</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              태그 이름
            </Label>
            <Input
              id="name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              className="col-span-3"
              autoFocus
              onKeyDown={(e) => {
                if (e.nativeEvent.isComposing) return;
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">취소</Button>
          </DialogClose>
          <Button onClick={onSubmit} disabled={mutation.isPending}>
            {mutation.isPending && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            수정
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
