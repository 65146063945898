import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "src/components/common/DataTable";
import { Button } from "src/components/ui/button";
import { UsersColumns } from "src/components/users/UserColumns";
import { useGetUsers } from "src/hooks/api/useUsers";

const NewUsersPage = () => {
  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  useEffect(() => {
    if (!isAdmin) {
      alert("접근 권한이 없습니다.");
      window.location.replace("/");
    }
  }, [isAdmin]);

  const [pageNum, setPageNum] = useState(1);
  const { data: users, refetch } = useGetUsers({
    pageNum: pageNum,
    pageSize: 10,
  });

  useEffect(() => {
    refetch();
  }, [pageNum, refetch]);

  const [selectedUsersRow, setSelectedUsersRow] = useState({});

  return (
    <>
      <h2 className="text-xl font-bold">사용자 관리</h2>
      <div className="mt-8 flex justify-end">
        <Button onClick={() => (window.location.href = "/new/users/add")}>
          신규 사용자 등록
        </Button>
      </div>
      <div className="mt-8">
        <DataTable
          columns={UsersColumns}
          data={users?.content ?? []}
          totalPages={users?.totalPages ?? 0}
          totalElements={users?.totalElements ?? 0}
          first={users?.first ?? false}
          last={users?.last ?? false}
          number={users?.number ?? 0}
          onClickNext={() => {
            if (pageNum === users?.totalPages) return;
            setPageNum((prev) => prev + 1);
          }}
          onClickPrev={() => {
            if (pageNum === 1) return;
            setPageNum((prev) => prev - 1);
          }}
          setRowSelection={setSelectedUsersRow}
          rowSelection={selectedUsersRow}
        />
      </div>
    </>
  );
};

export default NewUsersPage;
