import { useEffect, useState } from "react";

import { Box, Container, Button, Divider } from "@mui/material";
import { useHistory } from "react-router";
import Pagination from "react-js-pagination";
import {
  useDeleteTrendKeywords,
  useGetTrendKeywords,
} from "src/hooks/api/useTrendKeyword";
import TrendKeywordList from "src/components/trendKeyword/TrendKeywordList";
import ToolBar from "src/components/common/Toolbar";
import { convertDate } from "src/lib/utils";
import { useSelector } from "react-redux";
import { useToast } from "src/components/ui/use-toast";

const TrendKeywordPage = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [mainExposure, setMainExposure] = useState<boolean>();
  const [sort, setSort] = useState<"CREATE" | "NAME" | "LOGO_COUNT" | "UPDATE">(
    "CREATE",
  );
  const [selectedTrendKeywordIds, setSelectedTrendKeywordIds] = useState<
    number[]
  >([]);

  const {
    data: trendKeywords,
    // isLoading,
    // isError,
    refetch,
  } = useGetTrendKeywords({
    pageNum: pageNum,
    searchWord: searchWord,
    mainExposure: mainExposure,
    sort: sort,
  });

  useEffect(() => {
    refetch();
  }, [pageNum, sort, mainExposure, refetch]);

  const onClickSearch = () => {
    refetch();
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const onClickOrderByUpdate = () => {
    setSort("UPDATE");
    setMainExposure(undefined);
  };

  const onClickOrderByName = () => {
    setSort("NAME");
    setMainExposure(undefined);
  };

  const onClickOrderByLogoCount = () => {
    setSort("LOGO_COUNT");
    setMainExposure(undefined);
  };

  const onClickOrderByCreate = () => {
    setSort("CREATE");
    setMainExposure(undefined);
  };

  const onClickMainExposure = () => {
    setMainExposure(true);
    setSort("CREATE");
  };

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { toast } = useToast();

  const mutation = useDeleteTrendKeywords();
  const deleteTrendKeywords = () => {
    mutation.mutate(selectedTrendKeywordIds, {
      onSuccess: () => {
        toast({
          title: "삭제되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.reload();
      },
      onError: (error) => {
        if (error.message.includes("403")) {
          toast({
            title: "권한이 없습니다.",
            description: "관리자만 삭제할 수 있습니다.",
            variant: "destructive",
          });
          return;
        }
      },
    });
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <h2 className="text-xl font-bold">트렌드 키워드 관리</h2>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/new/trend-keywords/add")}
            >
              키워드 등록
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              onClick={() => window.location.reload()}
            >
              검색 결과 초기화
            </Button>
          </Box>
          {isAdmin && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteTrendKeywords()}
              >
                키워드 삭제
              </Button>
            </Box>
          )}
        </Box>
        <ToolBar
          search={searchWord}
          setSearch={setSearchWord}
          onClickSearch={onClickSearch}
        />
        <Divider />
        <Box mt={2} />
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByCreate}
          >
            등록 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByName}
          >
            가나다 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByLogoCount}
          >
            로고 많은 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByUpdate}
          >
            최종입력시간 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickMainExposure}
          >
            메인노출 키워드 보기
          </Button>
        </Box>
        <Divider />
        <Box mt={2} />
        <div className="py-4 text-xl font-semibold">
          전체 데이터 수 : {trendKeywords?.totalElements}개
        </div>
        <TrendKeywordList
          trendKeywordList={
            trendKeywords?.content?.map((trendKeyword) => ({
              id: trendKeyword.id,
              korName: trendKeyword.korName,
              engName: trendKeyword.engName,
              relatedLogoCount: trendKeyword.logoCount,
              mainExposure: trendKeyword.mainExposure,
              updateAt: convertDate(trendKeyword.updateAt),
            })) ?? []
          }
          trendKeywordsTotal={trendKeywords?.totalElements ?? 0}
          selectedTrendKeywordIds={selectedTrendKeywordIds}
          setSelectedTrendKeywordIds={setSelectedTrendKeywordIds}
        />
        <Pagination
          activePage={pageNum}
          itemsCountPerPage={10}
          totalItemsCount={trendKeywords?.totalElements || 0}
          pageRangeDisplayed={10}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={handlePageChange}
        />
      </Container>
    </Box>
  );
};

export default TrendKeywordPage;
