import { useState, useEffect, useRef, Dispatch, SetStateAction } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import { Checkbox as NCheckbox } from "src/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import {
  useGetBrandBusinessCategories,
  useGetBrandProjectProperties,
  useGetBrandWorkScopes,
} from "src/hooks/api/useCommon";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { Button } from "src/components/ui/button";
import { Check, ChevronsUpDown, X, XIcon } from "lucide-react";
import { cn } from "src/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "src/components/ui/command";
import { useGetSimpleTrendKeywords } from "src/hooks/api/useTrendKeyword";
import { ScrollArea } from "src/components/ui/scroll-area";
import { Separator } from "src/components/ui/separator";
import { Badge } from "src/components/ui/badge";
import { Label } from "src/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";
import { Textarea } from "src/components/ui/textarea";
import { useGetSimpleAgencies } from "src/hooks/api/useAgency";
import { DataTable } from "src/components/common/DataTable";
import { agencyColumns } from "src/components/LogoPage/BrandAgencyColumns";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { imageBaseUrl } from "src/constants";
import { useCreateBrand, useGetBrands } from "src/hooks/api/useBrand";
import { brandColumns } from "src/components/LogoPage/BrandColumn";
import { useGetSimpleTypefaces } from "src/hooks/api/useTypeface";
import { useSelector } from "react-redux";
import BrandTagSearch from "src/components/LogoPage/BrandTagSearch";
import BrandTagList from "src/components/LogoPage/BrandTagList";

interface IFormBrand {
  korBrandName: string;
  engBrandName: string;
  brandType: "KOREA" | "GLOBAL";
  announcementDate: string;
  thumbnail: File;
  image1: File;
  image2: File;
  projectPropertyId: number;
  scopeOfWorkIds: number[];
  businessCategoryIds: number[];
  trendKeywordIds: number[];
  client: string;
  brandSiteUrl: string;
  brandArticles: { id: number; title: string; url: string }[];
  relatedAgencyIds: number[];
  typefaceIds: number[];
  caseStudies: {
    id: number;
    type: "BEHANCE" | "INSTAGRAM" | "WEBSITE";
    url: string;
  }[];
  recommend: boolean;
  directorPlanning: string[];
  directorVerbal: string[];
  directorVisual: string[];
  winnerVerbal: string[];
  winnerVisual: string[];
  creatorsVerbal: string[];
  creatorsVisual: string[];
  relatedBrandIds: number[];
  description: string;
  exposure: boolean;
  modify: boolean;
  tagIds: number[];
}

const BrandAddPage = () => {
  const { data: projectProperites } = useGetBrandProjectProperties();
  const { data: workScopes } = useGetBrandWorkScopes();
  const { data: businessCategories } = useGetBrandBusinessCategories();
  const { data: trendKeywords } = useGetSimpleTrendKeywords();
  const { data: typefaces } = useGetSimpleTypefaces();

  const [selectedTags, setSelectedTags] = useState<
    { id: number; name: string }[]
  >([]);

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const form = useForm<IFormBrand>({
    defaultValues: {
      brandType: "KOREA",
      projectPropertyId: 1,
      scopeOfWorkIds: [],
      businessCategoryIds: [],
      trendKeywordIds: [],
      brandArticles: [],
      caseStudies: [],
      recommend: false,
      relatedAgencyIds: [],
      directorPlanning: [],
      directorVerbal: [],
      directorVisual: [],
      winnerVerbal: [],
      winnerVisual: [],
      creatorsVerbal: [],
      creatorsVisual: [],
      relatedBrandIds: [],
      typefaceIds: [],
      exposure: false,
      modify: true,
      brandSiteUrl: "",
      client: "",
      description: "",
      engBrandName: "",
      korBrandName: "",
      announcementDate: "",
      tagIds: [],
    },
  });

  const mutation = useCreateBrand();

  const onSubmit = (data: IFormBrand) => {
    const formData = new FormData();
    if (images.thumbnail) {
      data.thumbnail = images.thumbnail;
      formData.append("thumbnail", data.thumbnail);
    }
    if (images.image1) {
      data.image1 = images.image1;
      formData.append("images", data.image1);
    }
    if (images.image2) {
      data.image2 = images.image2;
      formData.append("images", data.image2);
    }

    if (!data.thumbnail) {
      alert("썸네일을 등록해주세요.");
      return;
    }

    selectedTags.forEach((tag) => {
      data.tagIds.push(tag.id);
    });

    formData.append(
      "brand",
      new Blob([JSON.stringify(data)], { type: "application/json" }),
    );

    mutation.mutate(formData, {
      onSuccess: () => {
        alert("등록되었습니다.");
        window.location.replace("/new/brands");
      },
      onError: () => {
        alert("등록에 실패하였습니다.");
      },
    });
  };

  const [isKoreanBrand, setIsKoreanBrand] = useState(
    form.getValues("brandType") === "KOREA",
  );

  const [selectedTrendKeywords, setSelectedTrendKeywords] = useState<
    ISimpleTrendKeywordDto[]
  >([]);
  const [selectedAgencies, setSelectedAgencies] = useState<ISimpleAgencyDto[]>(
    [],
  );
  const [selectedBrands, setSelectedBrands] = useState<
    IBrandListItemResponseDto[]
  >([]);
  const [selectedTypefaces, setSelectedTypefaces] = useState<
    ISimpleTypefaceDto[]
  >([]);

  const watchBrandType = form.watch("brandType");

  useEffect(() => {
    watchBrandType === "KOREA"
      ? setIsKoreanBrand(true)
      : setIsKoreanBrand(false);
  }, [watchBrandType]);

  const onBrandTypeChage = (brandType: "KOREA" | "GLOBAL") => {
    form.setValue("brandType", brandType);
  };

  const [images, setImages] = useState<{
    thumbnail?: File;
    image1?: File;
    image2?: File;
  }>({ thumbnail: undefined, image1: undefined, image2: undefined });

  const [imagesPreview, setImagesPreview] = useState<{
    thumbnail?: string;
    image1?: string;
    image2?: string;
  }>({ thumbnail: "", image1: "", image2: "" });

  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "thumbnail" | "image1" | "image2",
  ) => {
    const fileArr = e.target.files;

    let fileURLs: Array<string> = [];
    let file;

    if (!fileArr) return;

    setImages((prev) => {
      return { ...prev, [type]: fileArr[0] };
    });

    file = fileArr[0];
    let reader = new FileReader();
    reader.onload = () => {
      fileURLs[0] = reader.result ? (reader.result as string) : "";
      setImagesPreview((prev) => {
        return { ...prev, [type]: fileURLs[0] };
      });
    };
    reader.readAsDataURL(file);
  };

  const ProjectPropertyField = () => (
    <FormField
      control={form.control}
      name="projectPropertyId"
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">BrandNew</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(parseInt(value))}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              {projectProperites?.map((projectProperty) => (
                <FormItem
                  className="flex items-center gap-2 space-y-0"
                  key={projectProperty.id}
                >
                  <FormControl>
                    <RadioGroupItem value={projectProperty.id.toString()} />
                  </FormControl>
                  <FormLabel>{projectProperty.name}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  const ScopeOfWorkField = () => (
    <FormField
      control={form.control}
      name="scopeOfWorkIds"
      render={() => (
        <FormItem>
          <div className="mb-4">
            <Label className="text-lg font-bold">Branding</Label>
          </div>
          <div className="grid grid-cols-3 gap-3 lg:grid-cols-4">
            {workScopes?.map((workScope) => (
              <FormField
                key={workScope.id}
                control={form.control}
                name="scopeOfWorkIds"
                render={({ field }) => (
                  <FormItem
                    className="flex items-start gap-2 space-y-0"
                    key={workScope.id}
                  >
                    <FormControl>
                      <NCheckbox
                        checked={field.value?.includes(workScope.id)}
                        onCheckedChange={(checked) => {
                          checked
                            ? field.onChange([...field.value, workScope.id])
                            : field.onChange(
                                field.value?.filter(
                                  (value: number) => value !== workScope.id,
                                ),
                              );
                        }}
                      />
                    </FormControl>
                    <FormLabel>{workScope.name}</FormLabel>
                  </FormItem>
                )}
              />
            ))}
          </div>
        </FormItem>
      )}
    />
  );

  const BusinessCategoryField = () => (
    <FormField
      control={form.control}
      name="businessCategoryIds"
      render={() => (
        <FormItem>
          <div className="mb-4">
            <Label className="text-lg font-bold">Business</Label>
          </div>
          <div className="grid grid-cols-3 gap-3 lg:grid-cols-4">
            {businessCategories?.map((businessCategory) => (
              <FormField
                key={businessCategory.id}
                control={form.control}
                name="businessCategoryIds"
                render={({ field }) => (
                  <FormItem
                    className="flex items-start gap-2 space-y-0"
                    key={businessCategory.id}
                  >
                    <FormControl>
                      <NCheckbox
                        checked={field.value?.includes(businessCategory.id)}
                        onCheckedChange={(checked) => {
                          checked
                            ? field.onChange([
                                ...field.value,
                                businessCategory.id,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value: number) =>
                                    value !== businessCategory.id,
                                ),
                              );
                        }}
                      />
                    </FormControl>
                    <FormLabel>{businessCategory.name}</FormLabel>
                  </FormItem>
                )}
              />
            ))}
          </div>
        </FormItem>
      )}
    />
  );

  const TrendKeywordField = () => (
    <FormField
      control={form.control}
      name="trendKeywordIds"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="text-lg font-bold">트렌드 키워드</FormLabel>
          <TrendKeywordList />
          <Popover
            onOpenChange={(value) => {
              if (!value) {
                setSelectedTrendKeywords(
                  trendKeywords?.filter(
                    (trendKeyword) => field.value?.includes(trendKeyword.id),
                  ) ?? [],
                );
              }
            }}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn("w-[400px] justify-between")}
                >
                  키워드를 선택해주세요
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[400px] p-0">
              <Command>
                <CommandInput placeholder="검색어를 입력하세요." />
                <CommandEmpty>해당하는 키워드가 없습니다.</CommandEmpty>
                <CommandGroup>
                  <ScrollArea className="h-72 w-[400px] rounded-md border">
                    {trendKeywords?.map((trendKeyword) => (
                      <div key={trendKeyword.id}>
                        <CommandItem
                          value={`${trendKeyword.korName}${trendKeyword.engName}`}
                          key={trendKeyword.id}
                          className="py-3"
                          onSelect={() => {
                            field.value.includes(trendKeyword.id)
                              ? form.setValue(
                                  "trendKeywordIds",
                                  field.value.filter(
                                    (value: number) =>
                                      value !== trendKeyword.id,
                                  ),
                                )
                              : form.setValue("trendKeywordIds", [
                                  ...field.value,
                                  trendKeyword.id,
                                ]);
                          }}
                        >
                          <div className="flex w-full">
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                form
                                  .getValues("trendKeywordIds")
                                  .includes(trendKeyword.id)
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                            <div className="grid flex-1 grid-cols-2">
                              <div>{trendKeyword.korName}</div>
                              <div>{trendKeyword.engName}</div>
                            </div>
                          </div>
                        </CommandItem>
                        <Separator />
                      </div>
                    ))}
                  </ScrollArea>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  );

  const TrendKeywordList = () => (
    <div className="flex flex-wrap gap-3">
      {selectedTrendKeywords?.map((trendKeyword) => (
        <Badge
          variant={"outline"}
          key={trendKeyword.id}
          className={cn("px-4 py-2")}
        >
          <div className="flex items-center gap-2">
            <div>{trendKeyword.korName}</div>
            <Separator orientation="vertical" className={cn("h-4")} />
            <div>{trendKeyword.engName}</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                form.setValue(
                  "trendKeywordIds",
                  form
                    .getValues("trendKeywordIds")
                    .filter((value: number) => value !== trendKeyword.id),
                );
                setSelectedTrendKeywords(
                  selectedTrendKeywords.filter(
                    (selectedTrendKeyword) =>
                      selectedTrendKeyword.id !== trendKeyword.id,
                  ),
                );
              }}
            >
              <X className="h-4 w-4" />
            </div>
          </div>
        </Badge>
      ))}
    </div>
  );

  const BrandArticlesField = () => {
    const [brandArticleTag, setBrandArticleTag] = useState<string>("");
    const [brandArticleUrl, setBrandArticleUrl] = useState<string>("");
    const tagRef = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);
    return (
      <FormField
        control={form.control}
        name="brandArticles"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-lg font-bold">유관 아티클</FormLabel>
            <BrandArticlesList />
            <FormControl>
              <div className="flex items-center space-x-2">
                <NInput
                  className={cn("w-40")}
                  value={brandArticleTag}
                  onChange={(e) => setBrandArticleTag(e.target.value)}
                  ref={tagRef}
                />
                <NInput
                  placeholder="링크 주소 입력 / 자동 링크 / 노출은 안됨"
                  value={brandArticleUrl}
                  onChange={(e) => setBrandArticleUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.nativeEvent.isComposing) return;
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (brandArticleTag && brandArticleUrl) {
                        form.setValue("brandArticles", [
                          ...field.value,
                          {
                            id: field.value.length + 1,
                            title: brandArticleTag,
                            url: brandArticleUrl,
                          },
                        ]);
                        setBrandArticleTag("");
                        setBrandArticleUrl("");
                      }
                      tagRef.current?.focus();
                    }
                  }}
                  ref={urlRef}
                />
                <Button
                  type="button"
                  className={cn("text-lg")}
                  onClick={() => {
                    if (brandArticleTag && brandArticleUrl) {
                      form.setValue("brandArticles", [
                        ...field.value,
                        {
                          id: field.value.length + 1,
                          title: brandArticleTag,
                          url: brandArticleUrl,
                        },
                      ]);
                      setBrandArticleTag("");
                      setBrandArticleUrl("");
                    }
                  }}
                >
                  +
                </Button>
              </div>
            </FormControl>
          </FormItem>
        )}
      />
    );
  };

  const BrandArticlesList = () => (
    <div className="flex flex-wrap gap-3">
      {form.getValues("brandArticles")?.map((brandArticle) => (
        <Badge
          variant={"outline"}
          key={brandArticle.id}
          className={cn("px-4 py-2")}
        >
          <div className="flex items-center gap-2">
            <div>{brandArticle.title}</div>
            <Separator orientation="vertical" className={cn("h-4")} />
            <div>{brandArticle.url}</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                form.setValue(
                  "brandArticles",
                  form
                    .getValues("brandArticles")
                    .filter(
                      (value: { id: number; title: string; url: string }) =>
                        value.id !== brandArticle.id,
                    ),
                );
              }}
            >
              <X className="h-4 w-4" />
            </div>
          </div>
        </Badge>
      ))}
    </div>
  );

  const BrandCaseStudiesField = () => {
    const [brandCaseStudyType, setBrandCaseStudyType] = useState<
      "BEHANCE" | "INSTAGRAM" | "WEBSITE" | ""
    >("");
    const [brandCaseStudyUrl, setBrandCaseStudyUrl] = useState<string>("");

    return (
      <FormField
        control={form.control}
        name="caseStudies"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-lg font-bold">케이스 스터디</FormLabel>
            <BrandCaseStudiesList />
            <FormControl>
              <div className="flex items-center space-x-2">
                <Select
                  value={brandCaseStudyType}
                  onValueChange={(value) => {
                    setBrandCaseStudyType(
                      value as "BEHANCE" | "INSTAGRAM" | "WEBSITE",
                    );
                  }}
                >
                  <SelectTrigger className="w-40">
                    <SelectValue placeholder="케이스 선택" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="WEBSITE">Website</SelectItem>
                    <SelectItem value="INSTAGRAM">Instagram</SelectItem>
                    <SelectItem value="BEHANCE">Behance</SelectItem>
                  </SelectContent>
                </Select>
                <NInput
                  placeholder="URL 입력"
                  value={brandCaseStudyUrl}
                  onChange={(e) => setBrandCaseStudyUrl(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.nativeEvent.isComposing) return;
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (brandCaseStudyType && brandCaseStudyUrl) {
                        form.setValue("caseStudies", [
                          ...field.value,
                          {
                            id: field.value.length + 1,
                            type: brandCaseStudyType,
                            url: brandCaseStudyUrl,
                          },
                        ]);
                        setBrandCaseStudyType("");
                        setBrandCaseStudyUrl("");
                      }
                    }
                  }}
                />
                <Button
                  type="button"
                  className={cn("text-lg")}
                  onClick={() => {
                    if (brandCaseStudyType && brandCaseStudyUrl) {
                      form.setValue("caseStudies", [
                        ...field.value,
                        {
                          id: field.value.length + 1,
                          type: brandCaseStudyType,
                          url: brandCaseStudyUrl,
                        },
                      ]);
                      setBrandCaseStudyType("");
                      setBrandCaseStudyUrl("");
                    }
                  }}
                >
                  +
                </Button>
              </div>
            </FormControl>
          </FormItem>
        )}
      />
    );
  };

  const BrandCaseStudiesList = () => (
    <div className="flex flex-wrap gap-3">
      {form.getValues("caseStudies")?.map((caseStudy) => (
        <Badge
          variant={"outline"}
          key={caseStudy.id}
          className={cn("px-4 py-2")}
        >
          <div className="flex items-center gap-2">
            <div>{caseStudy.type}</div>
            <Separator orientation="vertical" className={cn("h-4")} />
            <div>{caseStudy.url}</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                form.setValue(
                  "caseStudies",
                  form
                    .getValues("caseStudies")
                    .filter(
                      (value: {
                        id: number;
                        type: "BEHANCE" | "INSTAGRAM" | "WEBSITE";
                        url: string;
                      }) => value.id !== caseStudy.id,
                    ),
                );
              }}
            >
              <X className="h-4 w-4" />
            </div>
          </div>
        </Badge>
      ))}
    </div>
  );

  const TextField = ({
    formField,
    label,
  }: {
    formField: "client" | "brandSiteUrl" | "korBrandName" | "engBrandName";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "exposure" | "modify" | "recommend";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  const MakersField = ({
    formField,
    label,
  }: {
    formField:
      | "directorPlanning"
      | "directorVerbal"
      | "directorVisual"
      | "winnerVerbal"
      | "winnerVisual"
      | "creatorsVerbal"
      | "creatorsVisual";
    label: string;
  }) => {
    const [name, setName] = useState<string>("");
    return (
      <FormField
        control={form.control}
        name={formField}
        render={({ field }) => (
          <FormItem>
            <div>
              <FormLabel className="text-lg font-bold">{label}</FormLabel>
              <div className="mt-1" />
              {form.getValues(formField)?.map((maker, index) => (
                <Badge
                  variant={"outline"}
                  key={index}
                  className={cn("mx-1 px-4 py-2")}
                >
                  <div className="flex items-center gap-2">
                    <div>{maker}</div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        form.setValue(
                          formField,
                          form
                            .getValues(formField)
                            .filter(
                              (value: string) =>
                                value !== form.getValues(formField)[index],
                            ),
                        );
                      }}
                    >
                      <X className="h-4 w-4" />
                    </div>
                  </div>
                </Badge>
              ))}
            </div>

            <FormControl>
              <div className="flex w-full max-w-sm items-center space-x-2">
                <NInput
                  placeholder="이름 입력"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.nativeEvent.isComposing) return;
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (name) {
                        form.setValue(formField, [...field.value, name]);
                        setName("");
                      }
                    }
                  }}
                />
                <Button
                  type="button"
                  className={cn("text-lg")}
                  onClick={() => {
                    if (name) {
                      form.setValue(formField, [...field.value, name]);
                      setName("");
                    }
                  }}
                >
                  +
                </Button>
              </div>
            </FormControl>
          </FormItem>
        )}
      />
    );
  };

  const TypefaceField = () => (
    <FormField
      control={form.control}
      name="typefaceIds"
      render={({ field }) => (
        <FormItem className="flex flex-col">
          <FormLabel className="text-lg font-bold">서체 정보</FormLabel>
          <TypefaceList />
          <Popover
            onOpenChange={(value) => {
              if (!value) {
                setSelectedTypefaces(
                  typefaces?.filter(
                    (typeface) => field.value?.includes(typeface.id),
                  ) ?? [],
                );
              }
            }}
          >
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn("w-[400px] justify-between")}
                >
                  서체를 선택해주세요
                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[400px] p-0">
              <Command>
                <CommandInput placeholder="검색어를 입력하세요." />
                <CommandEmpty>해당하는 서체가 없습니다.</CommandEmpty>
                <CommandGroup>
                  <ScrollArea className="h-72 w-[400px] rounded-md border">
                    {typefaces?.map((typeface) => (
                      <div key={typeface.id}>
                        <CommandItem
                          value={`${typeface.name}${typeface.designer}`}
                          key={typeface.id}
                          className="py-3"
                          onSelect={() => {
                            field.value.includes(typeface.id)
                              ? form.setValue(
                                  "typefaceIds",
                                  field.value.filter(
                                    (value: number) => value !== typeface.id,
                                  ),
                                )
                              : form.setValue("typefaceIds", [
                                  ...field.value,
                                  typeface.id,
                                ]);
                          }}
                        >
                          <div className="flex w-full">
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                form
                                  .getValues("typefaceIds")
                                  .includes(typeface.id)
                                  ? "opacity-100"
                                  : "opacity-0",
                              )}
                            />
                            <div className="grid flex-1 grid-cols-2">
                              <div>{typeface.name}</div>
                              <div>{typeface.designer}</div>
                            </div>
                          </div>
                        </CommandItem>
                        <Separator />
                      </div>
                    ))}
                  </ScrollArea>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </FormItem>
      )}
    />
  );

  const TypefaceList = () => (
    <div className="flex flex-wrap gap-3">
      {selectedTypefaces?.map((typeface) => (
        <Badge
          variant={"outline"}
          key={typeface.id}
          className={cn("px-4 py-2")}
        >
          <div className="flex items-center gap-2">
            <div>{typeface.name}</div>
            <Separator orientation="vertical" className={cn("h-4")} />
            <div>{typeface.designer}</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                form.setValue(
                  "typefaceIds",
                  form
                    .getValues("typefaceIds")
                    .filter((value: number) => value !== typeface.id),
                );
                setSelectedTypefaces(
                  selectedTypefaces.filter(
                    (selectedTypeface) => selectedTypeface.id !== typeface.id,
                  ),
                );
              }}
            >
              <X className="h-4 w-4" />
            </div>
          </div>
        </Badge>
      ))}
    </div>
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">브랜드 등록</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex gap-5">
            <div className="flex w-full items-center gap-2">
              {/* 브랜드명 한글 */}
              <TextField formField="korBrandName" label="브랜드명(한글)" />
              <NCheckbox
                checked={isKoreanBrand}
                onCheckedChange={() => onBrandTypeChage("KOREA")}
              />
            </div>

            {/* 브랜드명 영문 */}
            <div className="flex w-full items-center gap-2">
              <TextField formField="engBrandName" label="브랜드명(영문)" />
              <NCheckbox
                checked={!isKoreanBrand}
                onCheckedChange={() => onBrandTypeChage("GLOBAL")}
              />
            </div>
          </div>

          {/* 로고 발표년월 */}
          <FormField
            control={form.control}
            name="announcementDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">
                  로고 발표년월
                </FormLabel>
                <FormControl>
                  <NInput placeholder="예) YYYY-MM" {...field} />
                </FormControl>
              </FormItem>
            )}
          />

          <div>이미지 (870 X 480 72dpl 파일로 업로드 해주세요)</div>
          <div className="flex flex-wrap gap-4 space-y-0">
            <FormField
              control={form.control}
              name="thumbnail"
              render={({ field }) => (
                <FormItem>
                  <Label className="text-lg font-bold">썸네일</Label>
                  {imagesPreview.thumbnail && (
                    <Label>
                      <img
                        src={imagesPreview.thumbnail}
                        alt="brand-thumbnail"
                        className="h-[220px] w-[330px] object-contain"
                      />
                    </Label>
                  )}
                  <FormControl>
                    <NInput
                      type="file"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(e) => {
                        handleImageUpload(e, "thumbnail");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="image1"
              render={({ field }) => (
                <FormItem>
                  <Label className="text-lg font-bold">상세 이미지 1</Label>
                  {imagesPreview.image1 && (
                    <Label className="relative">
                      <img
                        src={imagesPreview.image1}
                        alt="brand-thumbnail"
                        className="h-[220px] w-[330px] object-contain"
                      />
                      <button
                        className="absolute right-0 top-0 text-red-500 hover:text-red-700"
                        onClick={() => {
                          setImagesPreview((prev) => {
                            return { ...prev, image1: "" };
                          });
                          setImages((prev) => {
                            return { ...prev, image1: undefined };
                          });
                        }}
                      >
                        <XIcon className="h-6 w-6" />
                      </button>
                    </Label>
                  )}
                  <FormControl>
                    <NInput
                      type="file"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(e) => {
                        handleImageUpload(e, "image1");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="image2"
              render={({ field }) => (
                <FormItem>
                  <Label className="text-lg font-bold">상세 이미지 2</Label>
                  {imagesPreview.image2 && (
                    <Label className="relative">
                      <img
                        src={imagesPreview.image2}
                        alt="brand-thumbnail"
                        className="h-[220px] w-[330px] object-contain"
                      />
                      <button
                        className="absolute right-0 top-0 text-red-500 hover:text-red-700"
                        onClick={() => {
                          setImagesPreview((prev) => {
                            return { ...prev, image2: "" };
                          });
                          setImages((prev) => {
                            return { ...prev, image2: undefined };
                          });
                        }}
                      >
                        <XIcon className="h-6 w-6" />
                      </button>
                    </Label>
                  )}
                  <FormControl>
                    <NInput
                      type="file"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(e) => {
                        handleImageUpload(e, "image2");
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <ProjectPropertyField />
          <ScopeOfWorkField />
          <BusinessCategoryField />
          <TrendKeywordField />
          <TextField formField="client" label="클라이언트 명" />
          <TextField formField="brandSiteUrl" label="브랜드 사이트 주소" />
          <BrandArticlesField />

          {/* 에이전시 */}
          <AgencyField form={form} setSelectedAgencies={setSelectedAgencies} />
          <AgencyList
            selectedAgencies={selectedAgencies}
            setSelectedAgencies={setSelectedAgencies}
            form={form}
          />

          <BrandCaseStudiesField />
          <BooleanField formField="recommend" label="추천 여부" />

          {/* 서체 정보 */}
          <TypefaceField />

          {/* 디렉터 */}
          <MakersField formField="directorPlanning" label="Planning Director" />
          <MakersField formField="directorVerbal" label="Verbal Director" />
          <MakersField formField="directorVisual" label="Visual Director" />
          <MakersField formField="winnerVerbal" label="Verbal Winner" />
          <MakersField formField="winnerVisual" label="Visual Winner" />
          <MakersField formField="creatorsVerbal" label="Verbal Creators" />
          <MakersField formField="creatorsVisual" label="Visual Creators" />

          {/* 연관 브랜드 */}
          <RelatedBrandField
            form={form}
            setSelectedBrands={setSelectedBrands}
          />
          <BrandList
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            form={form}
          />

          {/* 브랜드 설명 */}
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">브랜드 설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="브랜드 설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <BrandTagList
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          <BrandTagSearch
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />

          {isAdmin && <BooleanField formField="exposure" label="노출 여부" />}
          {isAdmin && <BooleanField formField="modify" label="보완 여부" />}

          <div className="flex justify-end">
            <Button type="submit">등록</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default BrandAddPage;

const AgencyField = ({
  form,
  setSelectedAgencies,
}: {
  form: UseFormReturn<IFormBrand>;
  setSelectedAgencies: Dispatch<SetStateAction<ISimpleAgencyDto[]>>;
}) => {
  const [agencySearchWord, setAgencySearchWord] = useState("");
  const [agencyPageNum, setAgencyPageNum] = useState(1);
  const { data: agencies, refetch: refetchAgencies } = useGetSimpleAgencies({
    pageNum: agencyPageNum,
    pageSize: 10,
    searchWord: agencySearchWord,
  });

  const [selectedAgenciesRow, setSelectedAgenciesRow] = useState({});
  useEffect(() => {
    if (!agencySearchWord) return;
    refetchAgencies();
  }, [agencyPageNum]);

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">에이전시</Label>
      <div className="flex max-w-sm items-center gap-2">
        <NInput
          placeholder="에이전시 검색"
          value={agencySearchWord}
          onChange={(e) => setAgencySearchWord(e.target.value)}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) return;
            if (!agencySearchWord) return;
            if (e.key === "Enter") {
              e.preventDefault();
              setAgencyPageNum(1);
              refetchAgencies();
            }
          }}
        />
        <Button
          type="button"
          onClick={() => {
            if (!agencySearchWord) return;
            setAgencyPageNum(1);
            refetchAgencies();
          }}
        >
          검색
        </Button>
      </div>
      <DataTable
        columns={agencyColumns}
        data={agencies?.content ?? []}
        totalPages={agencies?.totalPages}
        totalElements={agencies?.totalElements}
        first={agencies?.first}
        last={agencies?.last}
        number={agencies?.number}
        onClickNext={() => {
          if (agencyPageNum === agencies?.totalPages) return;
          setAgencyPageNum((prev) => prev + 1);
        }}
        onClickPrev={() => {
          if (agencyPageNum === 1) return;
          setAgencyPageNum((prev) => prev - 1);
        }}
        rowSelection={selectedAgenciesRow}
        setRowSelection={setSelectedAgenciesRow}
      />
      <Button
        type="button"
        size={"sm"}
        className={cn("w-20 self-end")}
        onClick={() => {
          if (!selectedAgenciesRow) return;
          const newSelectedAgency = Object.keys(selectedAgenciesRow).map(
            (key) => parseInt(key),
          );
          form.setValue(
            "relatedAgencyIds",
            form
              .getValues("relatedAgencyIds")
              .concat(newSelectedAgency)
              .filter(
                (value: number, index: number, self: number[]) =>
                  self.indexOf(value) === index,
              ),
          );
          setSelectedAgencies((prev) =>
            prev
              .concat(
                agencies?.content.filter((agency) =>
                  newSelectedAgency.includes(agency.id),
                ) ?? [],
              )
              .filter(
                (
                  value: ISimpleAgencyDto,
                  index: number,
                  self: ISimpleAgencyDto[],
                ) => self.indexOf(value) === index,
              ),
          );
          setSelectedAgenciesRow({});
        }}
      >
        추가
      </Button>
    </div>
  );
};

const AgencyList = ({
  selectedAgencies,
  setSelectedAgencies,
  form,
}: {
  selectedAgencies: ISimpleAgencyDto[];
  setSelectedAgencies: Dispatch<SetStateAction<ISimpleAgencyDto[]>>;
  form: UseFormReturn<IFormBrand>;
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {selectedAgencies?.map((agency) => (
        <Card className="w-[250px]" key={agency.id}>
          <CardHeader>
            <CardTitle className={cn("flex justify-between text-lg")}>
              {agency.agencyType === "KOREA" ? agency.korName : agency.engName}
              <div
                className="cursor-pointer"
                onClick={() => {
                  form.setValue(
                    "relatedAgencyIds",
                    form
                      .getValues("relatedAgencyIds")
                      .filter((value: number) => value !== agency.id),
                  );
                  setSelectedAgencies(
                    selectedAgencies.filter(
                      (selectedAgency) => selectedAgency.id !== agency.id,
                    ),
                  );
                }}
              >
                <X className="h-4 w-4" />
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              <img
                src={`${imageBaseUrl}/?fileId=${agency.thumbnailId}`}
                alt="agency thumbnail"
              />
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`agency-${agency.id}-url`}
                  className="font-bold"
                >
                  URL
                </Label>
                <div id={`agency-${agency.id}-url`} className="text-sm">
                  {agency.url}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`agency-${agency.id}-office`}
                  className="font-bold"
                >
                  오피스
                </Label>
                <div id={`agency-${agency.id}-office`} className="text-sm">
                  {agency.offices.join(", ")}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`agency-${agency.id}-exposure`}
                  className="font-bold"
                >
                  노출 여부
                </Label>
                <div id={`agency-${agency.id}-exposure`} className="text-sm">
                  {agency.exposure ? "Y" : "N"}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

const RelatedBrandField = ({
  form,
  setSelectedBrands,
}: {
  form: UseFormReturn<IFormBrand>;
  setSelectedBrands: Dispatch<SetStateAction<IBrandListItemResponseDto[]>>;
}) => {
  const [brandSearchWord, setBrandSearchWord] = useState("");
  const [brandPageNum, setBrandPageNum] = useState(1);
  const [selectedBrandsRow, setSelectedBrandsRow] = useState({});
  const { data: brands, refetch: refetchBrands } = useGetBrands({
    pageNum: brandPageNum,
    pageSize: 10,
    searchWord: brandSearchWord,
    sort: "CREATE",
  });
  useEffect(() => {
    if (!brandSearchWord) return;
    refetchBrands();
  }, [brandPageNum]);
  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">연관 브랜드</Label>
      <div className="flex max-w-sm items-center gap-2">
        <NInput
          placeholder="브랜드 검색"
          value={brandSearchWord}
          onChange={(e) => setBrandSearchWord(e.target.value)}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) return;
            if (!brandSearchWord) return;
            if (e.key === "Enter") {
              e.preventDefault();
              setBrandPageNum(1);
              refetchBrands();
            }
          }}
        />
        <Button
          type="button"
          onClick={() => {
            if (!brandSearchWord) return;
            setBrandPageNum(1);
            refetchBrands();
          }}
        >
          검색
        </Button>
      </div>
      <DataTable
        columns={brandColumns}
        data={brands?.content ?? []}
        totalPages={brands?.totalPages}
        totalElements={brands?.totalElements}
        first={brands?.first}
        last={brands?.last}
        number={brands?.number}
        onClickNext={() => {
          if (brandPageNum === brands?.totalPages) return;
          setBrandPageNum((prev) => prev + 1);
        }}
        onClickPrev={() => {
          if (brandPageNum === 1) return;
          setBrandPageNum((prev) => prev - 1);
        }}
        rowSelection={selectedBrandsRow}
        setRowSelection={setSelectedBrandsRow}
      />
      <Button
        type="button"
        size={"sm"}
        className={cn("w-20 self-end")}
        onClick={() => {
          if (!selectedBrandsRow) return;
          const newSelectedBrand = Object.keys(selectedBrandsRow).map((key) =>
            parseInt(key),
          );
          form.setValue(
            "relatedBrandIds",
            form
              .getValues("relatedBrandIds")
              .concat(newSelectedBrand)
              .filter(
                (value: number, index: number, self: number[]) =>
                  self.indexOf(value) === index,
              ),
          );
          setSelectedBrands((prev) =>
            prev
              .concat(
                brands?.content.filter((brand) =>
                  newSelectedBrand.includes(brand.id),
                ) ?? [],
              )
              .filter(
                (
                  value: IBrandListItemResponseDto,
                  index: number,
                  self: IBrandListItemResponseDto[],
                ) => self.indexOf(value) === index,
              ),
          );
          setSelectedBrandsRow({});
        }}
      >
        추가
      </Button>
    </div>
  );
};

const BrandList = ({
  selectedBrands,
  setSelectedBrands,
  form,
}: {
  selectedBrands: IBrandListItemResponseDto[];
  setSelectedBrands: Dispatch<SetStateAction<IBrandListItemResponseDto[]>>;
  form: UseFormReturn<IFormBrand>;
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {selectedBrands?.map((brand) => (
        <Card className="w-[250px]" key={brand.id}>
          <CardHeader>
            <CardTitle className={cn("flex justify-between text-lg")}>
              {brand.brandType === "KOREA" ? brand.korName : brand.engName}
              <div
                className="cursor-pointer"
                onClick={() => {
                  form.setValue(
                    "relatedBrandIds",
                    form
                      .getValues("relatedBrandIds")
                      .filter((value: number) => value !== brand.id),
                  );
                  setSelectedBrands(
                    selectedBrands.filter(
                      (selectedBrand) => selectedBrand.id !== brand.id,
                    ),
                  );
                }}
              >
                <X className="h-4 w-4" />
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              <img
                src={`${imageBaseUrl}/?fileId=${brand.thumbnailId}`}
                alt="brand thumbnail"
              />
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor={`brand-${brand.id}-url`} className="font-bold">
                  발표일
                </Label>
                <div
                  id={`brand-${brand.id}-announcementdate`}
                  className="text-sm"
                >
                  {brand.announcementDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-modifiedDate`}
                  className="font-bold"
                >
                  최종 입력 시간
                </Label>
                <div id={`brand-${brand.id}-modifiedDate`} className="text-sm">
                  {brand.modifiedDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-exposure`}
                  className="font-bold"
                >
                  노출 여부
                </Label>
                <div id={`brand-${brand.id}-exposure`} className="text-sm">
                  {brand.exposure ? "Y" : "N"}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
