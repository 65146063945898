import React, { useState, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { history } from "src/redux/configureStore";
import { actionCreators as userActions } from "src/redux/modules/user";

import Button from "../components/elements/Button";
import Text from "../components/elements/Text";

import { faEye as farEye } from "@fortawesome/free-regular-svg-icons";
import { faEyeSlash as farEyeSlash } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);
  const login_msg = useSelector((state) => state.user.login_msg);

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const _id = useRef();
  const _pw = useRef();

  // 로그인 warning 메시지
  const [warning, setWarning] = useState({
    _id_wrng: false,
    _pw_wrng: false,
    _wrng: false,
  });
  const { _id_wrng, _pw_wrng, _wrng } = warning;

  // 로그인 화면 에러 처리 UX
  const login = () => {
    setWarning({ _id_wrng: false, _pw_wrng: false }); // 로그인 시도를 여러번 할 수 있으므로
    if (!id && !pw) {
      setWarning({ _id_wrng: true, _pw_wrng: true });
      _id.current.focus();
    } else if (!id) {
      setWarning({ _id_wrng: true });
      _id.current.focus();
    } else if (!pw) {
      setWarning({ _pw_wrng: true });
      _pw.current.focus();
    } else {
      dispatch(userActions.loginAPI(id, pw));

      if (login_msg) {
        _id.current.focus();
        setWarning({ _wrng: true });
      }
    }
  };

  // 비밀번호 보기/숨기기
  const [show, setShow] = useState(false); // show = 비밀번호 인풋에 작성한 비밀번호

  const changeEye = () => {
    setShow(show ? false : true);
  };

  // 로그인 되어있을때 로그인 페이지 접근 방지
  if (isLogin) {
    history.push("/");
  }

  return (
    <Wrap>
      <Box>
        <div
          style={{
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          로그인
        </div>
        <LoginBox>
          <LoginInput
            value={id}
            type="text"
            placeholder="ID"
            // id="loginInput"
            onChange={(e) => {
              setId(e.target.value);
            }}
            onKeyPress={(e) => {
              if (window.event.keyCode === 13) {
                login();
              }
            }}
            ref={_id}
          />
        </LoginBox>
        {_id_wrng && (
          <Text subBody marginB="1rem">
            ID를 다시 확인해주세요!
          </Text>
        )}
        <LoginBox>
          <LoginInput
            type={show ? "text" : "password"}
            placeholder="PASSWORD"
            onChange={(e) => {
              setPw(e.target.value);
            }}
            onKeyPress={(e) => {
              if (window.event.keyCode === 13) {
                login();
                history.push("/");
              }
            }}
            ref={_pw}
          />
          <IconEyeSpan onClick={changeEye}>
            {show ? (
              <FontAwesomeIcon icon={farEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={farEye} />
            )}
          </IconEyeSpan>
        </LoginBox>
        {_pw_wrng && (
          <Text subBody marginB="1rem">
            비밀번호를 다시 확인해주세요!
          </Text>
        )}
        {_wrng && (
          <Text subBody marginB="1rem">
            존재하지 않는 아이디이거나, 잘못된 비밀번호입니다.
          </Text>
        )}

        <Button
          noflex
          _onClick={login}
          width="100%"
          margin="1rem 0"
          height="50px"
        >
          로그인
        </Button>
      </Box>
    </Wrap>
  );
};

const Wrap = styled.div`
  display: absolute;
  max-width: 100%;
  width: 350px;
  margin: 40px auto 0;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // min-height: 600px;
  height: 92vh;
  box-sizing: border-box;
`;

// const Check = styled.div`
//   width: 100%;
//   margin: 6px 0;
//   padding: 0;
//   box-sizing: border-box;
//   display: flex;
//   justify-content: flex-start;
//   gap: 30px;
//   color: rgba(0, 0, 0, 0.5);
// `;

const LoginBox = styled.div`
  border-bottom: 1px solid #8a8a8a;
  width: 100%;
  height: 46px;
  margin: 10px 0;
  padding: 8px 0;
  box-sizing: border-box;
  overflow: hidden;
  &:focus-within {
    transition: border-bottom 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-bottom: 2px solid #5262f2;
  }
  svg {
    width: 26px;
    /* float: left; */
    align-items: center;
    margin-right: 10px;
  }
`;

const LoginInput = styled.input`
  border: none;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  overflow: hidden;
  color: #000;
  font-size: 14px;
  width: 90%;
  outline: none;
  &::placeholder {
    color: rgba(0, 0, 0, 0.3);
    font-size: 12px;
    user-select: none;
  }
`;

const IconEyeSpan = styled.span`
  width: 26px;
  float: right;
  text-align: center;
  padding: 3px 0 0;
  font-size: 14px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
`;

export default Login;
