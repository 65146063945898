import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@mui/material";
import { useHistory } from "react-router";

const TableHeadColor = styled(TableHead)`
  background-color: #e2e2e2;
`;

const TableCellCenter = styled(TableCell)`
  :not(:last-child) {
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
  }
  :not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;

const Thumbnail = styled.img`
  border: 1px solid #dddddd;
  width: 300px;
  height: 150px;
  object-fit: contain;
`;

interface IBrandBFeatureListProps {
  brandBFeatureList: IBrandBFeatureListItem[];
  selectedBrandBFeatureIds: number[];
  setSelectedBrandBFeatureIds: Dispatch<SetStateAction<number[]>>;
}

const BrandBFeatureList = ({
  brandBFeatureList,
  selectedBrandBFeatureIds,
  setSelectedBrandBFeatureIds,
}: IBrandBFeatureListProps) => {
  const history = useHistory();

  const handleSelectAll = (event: any) => {
    let newSelectedCustomerIds: number[];

    if (event.target.checked) {
      newSelectedCustomerIds = brandBFeatureList.map(
        (brandBFeature) => brandBFeature.id,
      );
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedBrandBFeatureIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedBrandBFeatureIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandBFeatureIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandBFeatureIds.slice(1),
      );
    } else if (selectedIndex === selectedBrandBFeatureIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandBFeatureIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandBFeatureIds.slice(0, selectedIndex),
        selectedBrandBFeatureIds.slice(selectedIndex + 1),
      );
    }
    setSelectedBrandBFeatureIds(newSelectedCustomerIds);
  };

  return (
    <>
      <Card>
        {brandBFeatureList.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={3}
            mb={3}
          >
            <b>등록된 컨텐츠가 없습니다</b>
          </Box>
        ) : (
          <>
            <Box sx={{ minWidth: 600 }}>
              <Table>
                <TableHeadColor>
                  <TableRow>
                    <TableCellCenter padding="checkbox">
                      <Checkbox
                        checked={
                          selectedBrandBFeatureIds.length > 0 &&
                          selectedBrandBFeatureIds.length <
                            brandBFeatureList.length
                        }
                        color="primary"
                        indeterminate={
                          selectedBrandBFeatureIds.length > 0 &&
                          selectedBrandBFeatureIds.length <
                            brandBFeatureList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCellCenter>
                    <TableCellCenter align="center">No.</TableCellCenter>
                    <TableCellCenter align="center">메인노출</TableCellCenter>
                    <TableCellCenter align="center">썸네일</TableCellCenter>
                    <TableCellCenter align="center">제목</TableCellCenter>
                    <TableCellCenter align="center">설명문구</TableCellCenter>
                    <TableCellCenter align="center">발행년월</TableCellCenter>
                    <TableCellCenter align="center">노출 여부</TableCellCenter>
                    <TableCellCenter align="center">조회수</TableCellCenter>
                    <TableCellCenter align="center">순서일자</TableCellCenter>
                    <TableCellCenter align="center" />
                  </TableRow>
                </TableHeadColor>
                <TableBody>
                  {brandBFeatureList.map((brandBFeature, index) => (
                    <TableRow hover key={brandBFeature.id}>
                      <TableCellCenter padding="checkbox">
                        <Checkbox
                          checked={
                            selectedBrandBFeatureIds.indexOf(
                              brandBFeature.id,
                            ) !== -1
                          }
                          onChange={(event) =>
                            handleSelectOne(event, brandBFeature.id)
                          }
                          value="true"
                          color="primary"
                        />
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {brandBFeature.id}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {brandBFeature.mainExposure ? "Y" : "N"}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        <Box display="flex" justifyContent="center">
                          {brandBFeature.thumbnailUrl && (
                            <Thumbnail
                              src={brandBFeature.thumbnailUrl}
                              alt="thumbnail"
                            />
                          )}
                        </Box>
                      </TableCellCenter>
                      <TableCellCenter>{brandBFeature.title}</TableCellCenter>
                      <TableCellCenter>
                        {brandBFeature.description}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {brandBFeature.publishedDate}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {brandBFeature.exposure ? "Y" : "N"}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {brandBFeature.viewCount}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        {brandBFeature.orderDate}
                      </TableCellCenter>
                      <TableCellCenter align="center">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            history.push(
                              `/new/features/brandb/${brandBFeature.id}/edit`,
                            )
                          }
                        >
                          수정
                        </Button>
                      </TableCellCenter>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={3}></Box>
    </>
  );
};

export default BrandBFeatureList;
