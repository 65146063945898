import { useDeleteBrandTag } from "src/hooks/api/useBrand";
import { Button } from "../ui/button";
import { useToast } from "../ui/use-toast";

const BrandTagDeleteButton = ({
  id,
  refetch,
}: {
  id: string;
  refetch?: () => void;
}) => {
  const mutation = useDeleteBrandTag();
  const { toast } = useToast();

  const handleDelete = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      mutation.mutate(parseInt(id), {
        onSuccess: () => {
          toast({
            title: "태그가 삭제되었습니다.",
            description: "태그가 삭제되었습니다.",
          });
          refetch && refetch();
        },
        onError: () => {
          toast({
            title: "태그 삭제에 실패했습니다.",
            description: "태그 삭제에 실패했습니다.",
            variant: "destructive",
          });
        },
      });
    }
  };

  return (
    <Button variant="destructive" onClick={handleDelete}>
      삭제
    </Button>
  );
};

export default BrandTagDeleteButton;
