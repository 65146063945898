import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { useToast } from "src/components/ui/use-toast";
import { useEditManager, useGetUser } from "src/hooks/api/useUsers";

export interface IFormUser {
  password: string;
}

const NewUsersEditPage = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user, refetch: refetchUser } = useGetUser(parseInt(id));

  useEffect(() => {
    refetchUser();
  }, []);

  const form = useForm<IFormUser>({
    defaultValues: {
      password: "",
    },
  });
  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { toast } = useToast();

  useEffect(() => {
    if (!isAdmin) {
      alert("접근 권한이 없습니다.");
      window.location.replace("/");
    }
  }, [isAdmin]);

  const useConfirmPasswordRef = useRef<HTMLInputElement>(null);

  const mutation = useEditManager(parseInt(id));

  const onSubmit = (data: IFormUser) => {
    if (data.password === "") {
      toast({
        title: "매니저 등록에 실패했습니다.",
        description: "비밀번호를 입력해주세요.",
        variant: "destructive",
      });
      return;
    }

    if (data.password !== useConfirmPasswordRef.current?.value) {
      toast({
        title: "매니저 등록에 실패했습니다.",
        description: "비밀번호가 일치하지 않습니다.",
        variant: "destructive",
      });
      return;
    }

    mutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: "매니저 정보가 수정되었습니다.",
          description: "매니저 정보가 수정되었습니다.",
        });
        window.location.href = "/new/users";
      },
      onError: (error) => {
        toast({
          title: "매니저 등록에 실패했습니다.",
          description: error.message,
          variant: "destructive",
        });
      },
    });
  };

  return (
    <>
      <h2 className="text-xl font-bold">매니저 정보 수정</h2>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-8 flex max-w-lg flex-col gap-5"
        >
          <FormLabel className="text-lg font-bold">매니저 ID</FormLabel>
          <FormControl>
            <Input value={user?.userId ?? ""} readOnly />
          </FormControl>

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">비밀번호</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    value={field.value}
                    onChange={(e) => form.setValue("password", e.target.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div>
            <Label className="text-lg font-bold">비밀번호 확인</Label>
            <Input type="password" ref={useConfirmPasswordRef} />
          </div>
          <div className="mt-8 flex justify-end">
            <Button type="submit">수정</Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default NewUsersEditPage;
