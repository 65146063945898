import { useState, useEffect } from "react";

import { Box, Container, Button, Divider } from "@mui/material";
import { useHistory } from "react-router";
import { useDeleteAgencies, useGetAgencies } from "src/hooks/api/useAgency";
import AgencyList from "src/components/agency/AgencyList";
import Pagination from "react-js-pagination";
import AgencyToolBar from "src/components/agency/AgencyToolBar";
import { imageBaseUrl } from "src/constants";
import { useToast } from "src/components/ui/use-toast";
import { useSelector } from "react-redux";

const AgencyListPage = () => {
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");
  const [mainExposure, setMainExposure] = useState<boolean>();
  const [exposure, setExposure] = useState<boolean>();
  const [sort, setSort] = useState<"CREATE" | "NAME" | "LOGO_COUNT" | "UPDATE">(
    "CREATE",
  );
  const [selectedAgencyIds, setSelectedAgencyIds] = useState<number[]>([]);

  const {
    data: agencies,
    // isLoading,
    // isError,
    refetch,
  } = useGetAgencies({
    pageSize: 20,
    pageNum: pageNum,
    searchWord: searchWord,
    mainExposure: mainExposure,
    exposure: exposure,
    sort: sort,
  });

  useEffect(() => {
    refetch();
  }, [pageNum, sort, mainExposure, exposure, refetch]);

  const onClickSearch = () => {
    refetch();
  };

  const handlePageChange = (pageNumber: number) => {
    setPageNum(pageNumber);
  };

  const onClickOrderByCreate = () => {
    setSort("CREATE");
    setMainExposure(undefined);
    setExposure(undefined);
  };

  const onClickOrderByUpdate = () => {
    setSort("UPDATE");
    setMainExposure(undefined);
    setExposure(undefined);
  };

  const onClickOrderByName = () => {
    setSort("NAME");
    setMainExposure(undefined);
    setExposure(undefined);
  };

  const onClickOrderByLogoCount = () => {
    setSort("LOGO_COUNT");
    setMainExposure(undefined);
    setExposure(undefined);
  };

  const onClickHideAgency = () => {
    setExposure(false);
    setSort("CREATE");
    setMainExposure(undefined);
  };

  const onClickMainExposure = () => {
    setMainExposure(true);
    setSort("CREATE");
    setExposure(undefined);
  };

  const goBack = () => {
    window.location.reload();
  };

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { toast } = useToast();

  const mutation = useDeleteAgencies();
  const deleteAgencies = () => {
    mutation.mutate(selectedAgencyIds, {
      onSuccess: (data) => {
        toast({
          title: "삭제되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.reload();
      },
      onError: (error) => {
        if (error.message.includes("403")) {
          toast({
            title: "권한이 없습니다.",
            description: "관리자만 삭제할 수 있습니다.",
            variant: "destructive",
          });
          return;
        }
      },
    });
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <h2 className="text-xl font-bold">에이전시 관리</h2>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          mt={2}
          mb={2}
        >
          <Box display="flex" justifyContent="flex-start">
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/new/agencies/add")}
            >
              에이전시 등록
            </Button>
            <Box mr={2} />
            <Button variant="contained" onClick={() => goBack()}>
              검색 결과 초기화
            </Button>
          </Box>
          {isAdmin && (
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteAgencies()}
              >
                에이전시 삭제
              </Button>
            </Box>
          )}
        </Box>

        <AgencyToolBar
          search={searchWord}
          setSearch={setSearchWord}
          onClickSearch={onClickSearch}
        />

        <Divider />
        <Box mt={2} />
        <Box display="flex" flexDirection="row" mt={2} mb={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByCreate}
          >
            등록 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByName}
          >
            가나다 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByLogoCount}
          >
            로고 많은 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickHideAgency}
          >
            숨김처리 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickOrderByUpdate}
          >
            최종입력시간 순으로 보기
          </Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickMainExposure}
          >
            메인노출 에이전시 보기
          </Button>
        </Box>
        <Divider />
        <div className="py-4 text-xl font-semibold">
          전체 데이터 수 : {agencies?.totalElements}개
        </div>
        <Box sx={{ mb: 3 }}>
          {agencies?.totalElements === 0 ? (
            <Box display="flex" justifyContent="center">
              <h2>검색 결과가 없습니다.</h2>
            </Box>
          ) : (
            <>
              <AgencyList
                agencyList={
                  agencies?.content?.map((agency) => ({
                    id: agency.id,
                    korName: agency.korName,
                    engName: agency.engName,
                    agencyType: agency.agencyType,
                    url: agency.url,
                    thumbnailUrl: `${imageBaseUrl}/?fileId=${agency.thumbnailId}`,
                    exposure: agency.exposure,
                    mainExposure: agency.mainExposure,
                    offices: agency.offices,
                    relatedBrandCount: agency.relatedBrandCount,
                  })) ?? []
                }
                agencyTotal={agencies?.totalElements ?? 0}
                selectedAgencyIds={selectedAgencyIds}
                setSelectedAgencyIds={setSelectedAgencyIds}
              />
              <Pagination
                activePage={pageNum}
                itemsCountPerPage={20}
                totalItemsCount={agencies?.totalElements ?? 0}
                pageRangeDisplayed={10}
                prevPageText={"‹"}
                nextPageText={"›"}
                onChange={handlePageChange}
              />
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default AgencyListPage;
