import { Button, Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { actionCreators as userActions } from "src/redux/modules/user";

const HeaderStyle = styled.div`
  position: fixed;
  z-index: 4;
  height: 70px;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  background-color: #3f51b5;
  font-size: 1.3rem;
  font-weight: 700;
`;

const Title = styled.p`
  margin-left: 20px;
  color: white;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const LoginHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);

  const logOutBtn = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      dispatch(userActions.isLogout());
      history.push("/");
      window.location.reload();
    }
  };

  return (
    <HeaderStyle position="fixed">
      <Box flexGrow={1}>
        <StyledLink to="/logo">
          <Title>BrandB Admin</Title>
        </StyledLink>
      </Box>
      {isLogin && (
        <>
          <Button variant="contained" onClick={logOutBtn}>
            로그아웃
          </Button>
        </>
      )}
      <Box mr={2} />
    </HeaderStyle>
  );
};

export default LoginHeader;
