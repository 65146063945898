import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import { Checkbox as NCheckbox } from "src/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { DataTable } from "src/components/common/DataTable";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { imageBaseUrl } from "src/constants";
import { useGetBrands } from "src/hooks/api/useBrand";
import { trendKeywordBrandColumns } from "src/components/trendKeyword/TrendKeywordBrandColumns";
import { X } from "lucide-react";
import { useCreateTrendKeyword } from "src/hooks/api/useTrendKeyword";
import { useSelector } from "react-redux";
import { useToast } from "src/components/ui/use-toast";

export interface IFormTrendKeyword {
  korKeywordName: string;
  engKeywordName: string;
  trendKeywordType: "MARKET" | "DESIGN";
  description: string;
  mainExposure: boolean;
  brandIds: number[];
}

const NewTrendKeywordAddPage = () => {
  const [brandSearchWord, setBrandSearchWord] = useState("");
  const [brandPageNum, setBrandPageNum] = useState(1);
  const { data: brands, refetch: refetchBrands } = useGetBrands({
    pageNum: brandPageNum,
    pageSize: 10,
    sort: "CREATE",
    searchWord: brandSearchWord,
  });

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const [selectedBrandsRow, setSelectedBrandsRow] = useState({});

  useEffect(() => {
    if (!brandSearchWord) return;
    refetchBrands();
  }, [brandPageNum]);

  const form = useForm<IFormTrendKeyword>({
    defaultValues: {
      korKeywordName: "",
      engKeywordName: "",
      trendKeywordType: "MARKET",
      description: "",
      mainExposure: false,
      brandIds: [],
    },
  });

  const mutation = useCreateTrendKeyword();

  const { toast } = useToast();
  const onSubmit = (data: IFormTrendKeyword) => {
    mutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: "등록되었습니다.",
          description: "삭제되었습니다.",
        });
        window.location.replace("/new/trend-keywords");
      },
      onError: () => {
        toast({
          title: "등록에 실패하였습니다.",
          variant: "destructive",
        });
      },
    });
  };

  const [isMarketKeyword, setIsMarketKeyword] = useState(
    form.getValues("trendKeywordType") === "MARKET",
  );

  const [selectedBrands, setSelectedBrands] = useState<
    IBrandListItemResponseDto[]
  >([]);

  const watchTrendKeywordType = form.watch("trendKeywordType");

  useEffect(() => {
    watchTrendKeywordType === "MARKET"
      ? setIsMarketKeyword(true)
      : setIsMarketKeyword(false);
  }, [watchTrendKeywordType]);

  const onTrendKeywordTypeChange = (trendKeywordType: "MARKET" | "DESIGN") => {
    form.setValue("trendKeywordType", trendKeywordType);
  };

  const TextField = ({
    formField,
    label,
  }: {
    formField: "korKeywordName" | "engKeywordName";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "mainExposure";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BrandList = () => {
    return (
      <div className="flex flex-wrap gap-3">
        {selectedBrands?.map((brand) => (
          <Card className="w-[250px]" key={brand.id}>
            <CardHeader>
              <CardTitle className={cn("flex justify-between text-lg")}>
                {brand.brandType === "KOREA" ? brand.korName : brand.engName}
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    form.setValue(
                      "brandIds",
                      form
                        .getValues("brandIds")
                        .filter((value: number) => value !== brand.id),
                    );
                    setSelectedBrands(
                      selectedBrands.filter(
                        (selectedBrand) => selectedBrand.id !== brand.id,
                      ),
                    );
                  }}
                >
                  <X className="h-4 w-4" />
                </div>
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid w-full items-center gap-4">
                {brand.thumbnailId && (
                  <img
                    src={`${imageBaseUrl}/?fileId=${brand.thumbnailId}`}
                    alt="brand thumbnail"
                  />
                )}

                <div className="flex flex-col space-y-1.5">
                  <Label
                    htmlFor={`brand-${brand.id}-announcement`}
                    className="font-bold"
                  >
                    발표일
                  </Label>
                  <div
                    id={`brand-${brand.id}-announcement`}
                    className="text-sm"
                  >
                    {brand.announcementDate}
                  </div>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label
                    htmlFor={`brand-${brand.id}-update`}
                    className="font-bold"
                  >
                    최종 입력 시간
                  </Label>
                  <div id={`brand-${brand.id}-update`} className="text-sm">
                    {brand.modifiedDate}
                  </div>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label
                    htmlFor={`brand-${brand.id}-exposure`}
                    className="font-bold"
                  >
                    노출 여부
                  </Label>
                  <div id={`brand-${brand.id}-exposure`} className="text-sm">
                    {brand.exposure ? "Y" : "N"}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    );
  };

  return (
    <>
      <h1 className="py-4 text-xl font-bold">트렌드 키워드 등록</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex gap-5">
            <div className="flex items-center gap-2">
              <TextField formField="korKeywordName" label="키워드(한글)" />
            </div>
            <div className="flex items-center gap-2">
              <TextField formField="engKeywordName" label="키워드(영문)" />
            </div>
          </div>

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">키워드 설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormItem>
            <FormLabel className="text-lg font-bold">키워드 분류</FormLabel>
            <FormControl>
              <div className="flex w-full gap-6">
                <div className="flex flex-col gap-2">
                  <Label className="text-lg font-bold">MARKET</Label>
                  <NCheckbox
                    checked={isMarketKeyword}
                    onCheckedChange={() => onTrendKeywordTypeChange("MARKET")}
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Label className="text-lg font-bold">DESIGN</Label>
                  <NCheckbox
                    checked={!isMarketKeyword}
                    onCheckedChange={() => onTrendKeywordTypeChange("DESIGN")}
                  />
                </div>
              </div>
            </FormControl>
          </FormItem>

          <div className="flex flex-col gap-4">
            <Label className="text-lg font-bold">트렌드 연관 브랜드</Label>
            <BrandList />
            <div className="flex max-w-sm items-center gap-2">
              <NInput
                placeholder="검색"
                value={brandSearchWord}
                onChange={(e) => setBrandSearchWord(e.target.value)}
                onKeyDown={(e) => {
                  if (e.nativeEvent.isComposing) return;
                  if (!brandSearchWord) return;
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setBrandPageNum(1);
                    refetchBrands();
                  }
                }}
              />
              <Button
                type="button"
                onClick={() => {
                  if (!brandSearchWord) return;
                  setBrandPageNum(1);
                  refetchBrands();
                }}
              >
                검색
              </Button>
            </div>
            <DataTable
              columns={trendKeywordBrandColumns}
              data={brands?.content ?? []}
              totalPages={brands?.totalPages}
              totalElements={brands?.totalElements}
              first={brands?.first}
              last={brands?.last}
              number={brands?.number}
              onClickNext={() => {
                if (brandPageNum === brands?.totalPages) return;
                setBrandPageNum((prev) => prev + 1);
              }}
              onClickPrev={() => {
                if (brandPageNum === 1) return;
                setBrandPageNum((prev) => prev - 1);
              }}
              rowSelection={selectedBrandsRow}
              setRowSelection={setSelectedBrandsRow}
            />
            <Button
              type="button"
              size={"sm"}
              className={cn("w-20 self-end")}
              onClick={() => {
                if (!selectedBrandsRow) return;
                const newSelectedBrand = Object.keys(selectedBrandsRow).map(
                  (key) => parseInt(key),
                );
                form.setValue(
                  "brandIds",
                  form
                    .getValues("brandIds")
                    .concat(newSelectedBrand)
                    .filter(
                      (value: number, index: number, self: number[]) =>
                        self.indexOf(value) === index,
                    ),
                );
                setSelectedBrands((prev) =>
                  prev
                    .concat(
                      brands?.content.filter((brand) =>
                        newSelectedBrand.includes(brand.id),
                      ) ?? [],
                    )
                    .filter(
                      (
                        value: IBrandListItemResponseDto,
                        index: number,
                        self: IBrandListItemResponseDto[],
                      ) => self.indexOf(value) === index,
                    ),
                );
                setSelectedBrandsRow({});
              }}
            >
              추가
            </Button>
          </div>

          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}

          <div className="flex justify-end">
            <Button type="submit">등록</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default NewTrendKeywordAddPage;
