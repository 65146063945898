import React from "react";
import { Badge } from "../ui/badge";
import { cn } from "src/lib/utils";

interface FeatureTagListProps {
  selectedTags: { id: number; name: string }[];
  setSelectedTags: React.Dispatch<
    React.SetStateAction<{ id: number; name: string }[]>
  >;
}

const FeatureTagList = ({
  selectedTags,
  setSelectedTags,
}: FeatureTagListProps) => {
  return (
    <div className="flex gap-2">
      {selectedTags.map((tag) => (
        <Badge key={tag.id} variant="default" className={cn("px-4 py-2")}>
          {tag.name}
          <button
            onClick={() => {
              setSelectedTags(
                selectedTags.filter((selectedTag) => selectedTag.id !== tag.id),
              );
            }}
            className="ml-2"
          >
            <svg
              className="h-4 w-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </Badge>
      ))}
    </div>
  );
};

export default FeatureTagList;
