import { Dispatch, SetStateAction } from "react";
import { Card, Box, Grid, Button } from "@mui/material";
import styled from "styled-components";
import { useHistory } from "react-router";

const Image = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
  border: 1px solid #d2d2d2;
`;

const StyledCard = styled(Card)`
  padding: 15px;

  .brandTitle {
    font-size: 20px;
    font-weight: bold;
  }

  .client {
    font-size: 16px;
  }

  .title {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }

  .title-left {
    font-size: 15px;
    font-weight: bold;
    margin-left: 50px;
    margin-right: 10px;
  }

  .info {
    font-size: 15px;
  }
`;

const StyledCheckBox = styled.input`
  width: 20px;
  height: 20px;
  margin: 0;
`;

interface IBrandListProps {
  brandList: IBrandListItem[];
  brandsTotal: number;
  selectedBrandIds: number[];
  setSelectedBrandIds: Dispatch<SetStateAction<number[]>>;
}

const BrandList = ({
  brandList,
  brandsTotal,
  selectedBrandIds,
  setSelectedBrandIds,
}: IBrandListProps) => {
  const history = useHistory();

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedBrandIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandIds.slice(1),
      );
    } else if (selectedIndex === selectedBrandIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedBrandIds.slice(0, selectedIndex),
        selectedBrandIds.slice(selectedIndex + 1),
      );
    }
    setSelectedBrandIds(newSelectedCustomerIds);
  };

  return (
    <>
      {brandsTotal === 0 ? (
        <></>
      ) : (
        <>
          <Grid container spacing={3} columns={{ xs: 2, sm: 4, md: 12 }}>
            {brandList &&
              brandList.map((brand, index) => (
                <Grid item xs={3} key={index}>
                  <StyledCard>
                    <Box display="flex" justifyContent="flex-start" mb={1}>
                      <StyledCheckBox
                        type="checkbox"
                        checked={selectedBrandIds.indexOf(brand.id) !== -1}
                        onChange={(event) => handleSelectOne(event, brand.id)}
                      />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      style={{ cursor: "pointer" }}
                    >
                      <Box display="flex" flexDirection="row">
                        <Image src={brand.thumbnailUrl} />
                      </Box>
                      <Box mt={2} />
                      <div>
                        <span className="brandTitle">{brand.name}</span>
                        <br />
                        <span className="client">{brand.client ?? ""}</span>
                        <br />
                        <span className="title">발표일</span>
                        <span className="info">
                          {brand.announcementDate ?? ""}
                        </span>{" "}
                        <br />
                        <span className="title">최종입력시간</span>
                        <span className="info">
                          {brand.createdDate ?? ""}
                        </span>{" "}
                        <br />
                        <span className="title">노출여부</span>
                        <span className="info">
                          {brand.exposure ? "Y" : "N"}
                        </span>
                        <span className="title-left">보완여부</span>
                        <span className="info">{brand.modify ? "Y" : "N"}</span>
                      </div>
                    </Box>
                    <Box display="flex" mt={2} flexDirection="row">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() =>
                          history.push(`/new/brands/${brand.id}/edit`)
                        }
                      >
                        수정
                      </Button>
                    </Box>
                  </StyledCard>
                </Grid>
              ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default BrandList;
