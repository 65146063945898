import React from "react";
import styled from "styled-components";

const Button = (props) => {
  // disabled : 비활성화 모드
  const { main, sub, _onClick, text, children, width, height, margin, noflex, size } = props;
  const styles = {
    onClick: _onClick,
    text: text,
    width: width,
    height: height,
    margin: margin,
    noflex: noflex,
    size: size,
  };

  return (
    <>
      {sub ? (
        <Btn color="white" {...styles}>
          {text ? text : children}
        </Btn>
      ) : main ? (
        <Btn contrast="white" {...styles}>
          {text ? text : children}
        </Btn>
      ) : (
        <Btn contrast="white" {...styles}>
          {text ? text : children}
        </Btn>
      )}
    </>
  );
};

Button.defaultProps = {
  children: "I'm Button!",
  _onClick: () => {},
  margin: false,
  width: "max-content",
  height: "45px",
};

const Btn = styled.button`
  user-select: none;
  outline: none;
  background-color: #5262f2;
  border: none;
  border-radius: 12px;
  color: white;
  font-size: ${(props) => (props.size ? props.size : "17px")};
  font-weight: 700;
  ${(props) => (props.noflex ? "" : "flex-grow: 1;")};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  box-sizing: border-box;
  margin: ${(props) => props.margin};
  cursor: pointer;
  transition: color 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background-color 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    border 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275),
    box-shadow 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    background-color: #fff;
    color: #5262f2;
    border: 1px solid #5262f2;
    box-shadow: 0 0 0 3px black33;
    svg {
      color: ${(props) => props.color};
    }
  }
  &:active {
    box-shadow: 0 0 0 3px black33;
  }
  svg {
    color: ${(props) => props.contrast};
  }
`;

export default Button;
