import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@mui/material";
import { useHistory } from "react-router";
import { convertDate } from "src/lib/utils";

const TableHeadColor = styled(TableHead)`
  background-color: #e2e2e2;
`;

const TableCellCenter = styled(TableCell)`
  text-align: center;
  :not(:last-child) {
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
  }
  :not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;

interface ITypefaceListProps {
  typefaceList: ITypefaceListItem[];
  selectedTypefaceIds: number[];
  setSelectedTypefaceIds: Dispatch<SetStateAction<number[]>>;
}

const NewTypefaceList = ({
  typefaceList,
  selectedTypefaceIds,
  setSelectedTypefaceIds,
}: ITypefaceListProps) => {
  const history = useHistory();
  const handleSelectAll = (event: any) => {
    let newSelectedCustomerIds: number[];

    if (event.target.checked) {
      newSelectedCustomerIds = typefaceList.map((typeface) => typeface.id);
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedTypefaceIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedTypefaceIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTypefaceIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTypefaceIds.slice(1),
      );
    } else if (selectedIndex === selectedTypefaceIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTypefaceIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedTypefaceIds.slice(0, selectedIndex),
        selectedTypefaceIds.slice(selectedIndex + 1),
      );
    }
    setSelectedTypefaceIds(newSelectedCustomerIds);
  };

  return (
    <>
      <Card>
        {typefaceList.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={3}
            mb={3}
          >
            <b>등록된 컨텐츠가 없습니다</b>
          </Box>
        ) : (
          <>
            <Box sx={{ minWidth: 600 }}>
              <Table>
                <TableHeadColor>
                  <TableRow>
                    <TableCellCenter padding="checkbox">
                      <Checkbox
                        checked={
                          selectedTypefaceIds.length === typefaceList.length
                        }
                        color="primary"
                        indeterminate={
                          selectedTypefaceIds.length > 0 &&
                          selectedTypefaceIds.length < typefaceList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCellCenter>
                    <TableCellCenter align="center">No.</TableCellCenter>
                    <TableCellCenter align="center">서체명</TableCellCenter>
                    <TableCellCenter align="center">제작사</TableCellCenter>
                    <TableCellCenter align="center">로고 개수</TableCellCenter>
                    <TableCellCenter align="center">
                      최종입력시간
                    </TableCellCenter>
                    <TableCellCenter align="center">노출 여부</TableCellCenter>
                    <TableCellCenter align="center" />
                  </TableRow>
                </TableHeadColor>
                <TableBody>
                  {typefaceList &&
                    typefaceList.map((typeface, index) => (
                      <TableRow hover key={typeface.id}>
                        <TableCellCenter padding="checkbox">
                          <Checkbox
                            checked={
                              selectedTypefaceIds.indexOf(typeface.id) !== -1
                            }
                            onChange={(event) =>
                              handleSelectOne(event, typeface.id)
                            }
                            value="true"
                            color="primary"
                          />
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {typeface.id}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {typeface.name}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {typeface.designer}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {typeface.relatedBrandCount}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {convertDate(typeface.updateAt)}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {typeface.mainExposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              history.push(`/new/typeface/${typeface.id}/edit`)
                            }
                          >
                            수정
                          </Button>
                        </TableCellCenter>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={3}></Box>
    </>
  );
};

export default NewTypefaceList;
