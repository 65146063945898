import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../ui/command";
import { cn } from "src/lib/utils";
import { Check, Loader2 } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { useToast } from "../ui/use-toast";
import { useInView } from "react-intersection-observer";
import { ScrollArea } from "../ui/scroll-area";
import { useGetBrandTags, usePostBrandTag } from "src/hooks/api/useBrand";

interface BrandTagSearchProps {
  selectedTags: { id: number; name: string }[];
  setSelectedTags: Dispatch<SetStateAction<{ id: number; name: string }[]>>;
}

const BrandTagSearch = ({
  selectedTags,
  setSelectedTags,
}: BrandTagSearchProps) => {
  const [open, setOpen] = useState(false);
  const [searchWord, setSearchWord] = useState("");

  const { data, hasNextPage, fetchNextPage } = useGetBrandTags({
    pageNum: 1,
    pageSize: 100,
    searchWord: searchWord,
  });

  const { ref: inviewRef, inView } = useInView();

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, inView]);

  const handleSelectTag = useCallback(
    (tag: { id: number; name: string }, isSelected: boolean) => {
      if (isSelected) {
        setSelectedTags((prev) =>
          prev.filter((selectedTag) => selectedTag.id !== tag.id),
        );
      } else {
        setSelectedTags((prev) => [...prev, tag]);
        setSearchWord("");
      }
    },
    [setSelectedTags, setSearchWord],
  );

  return (
    <Command className="w-[300px] rounded-lg border shadow-md">
      <CommandInput
        placeholder="태그를 입력하세요."
        value={searchWord}
        onValueChange={(value) => setSearchWord(value)}
        onKeyDown={(e) => {
          if (e.nativeEvent.isComposing) return;
          if (e.key === "Enter") {
            e.preventDefault();
            setOpen(true);
          }
        }}
      />
      <CommandEmpty>
        {searchWord === "" && (
          <div className="text-center text-muted-foreground">
            태그를 입력하세요.
          </div>
        )}
      </CommandEmpty>
      <CommandGroup>
        <ScrollArea className="max-h-[200px] overflow-y-auto">
          {data?.pages.map((page) => {
            return page.content.map((tag) => {
              const isSelected = selectedTags.some(
                (selectedTag) => selectedTag.id === tag.id,
              );
              return (
                <TagItem
                  key={tag.id}
                  tag={tag}
                  isSelected={isSelected}
                  handleSelectTag={handleSelectTag}
                  isLast={
                    page.number + 1 === data.pageParams.length &&
                    page.content.indexOf(tag) === page.content.length - 1
                  }
                  inviewRef={inviewRef}
                />
              );
            });
          })}
        </ScrollArea>
      </CommandGroup>
      <div className="my-3 flex justify-center">
        <TagCreateDialog nameProps={searchWord} open={open} setOpen={setOpen} />
      </div>
    </Command>
  );
};

export default BrandTagSearch;

export const TagCreateDialog = ({
  nameProps,
  open,
  setOpen,
  refetch,
}: {
  nameProps: string;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refetch?: () => void;
}) => {
  const mutation = usePostBrandTag();
  const { toast } = useToast();
  const [tagName, setTagName] = useState(nameProps);

  useEffect(() => {
    if (!open) {
      setTagName(nameProps);
    }
  }, [open, nameProps]);

  const onSubmit = () => {
    if (tagName === "") {
      toast({
        title: "태그 이름을 입력해주세요.",
        description: "태그 이름을 입력해주세요.",
        variant: "destructive",
      });
      return;
    }
    mutation.mutate(
      { name: tagName },
      {
        onSuccess: () => {
          toast({
            title: "태그가 추가되었습니다.",
            description: `${tagName} 태그가 추가되었습니다.`,
          });
          setOpen(false);
          refetch && refetch();
        },
        onError: (error) => {
          if (error.message.includes("409")) {
            toast({
              title: "태그 추가에 실패했습니다.",
              description: "이미 존재하는 태그입니다.",
              variant: "destructive",
            });
          } else {
            toast({
              title: "태그 추가에 실패했습니다.",
              description: error.message,
              variant: "destructive",
            });
          }
          setOpen(false);
        },
      },
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen} defaultOpen={false}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>{tagName} 태그 추가</Button>
      </DialogTrigger>
      <DialogContent className="max-w-[400px]">
        <DialogHeader>
          <DialogTitle>태그 추가</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              태그 이름
            </Label>
            <Input
              id="name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              className="col-span-3"
              autoFocus
              onKeyDown={(e) => {
                if (e.nativeEvent.isComposing) return;
                if (e.key === "Enter") {
                  e.preventDefault();
                  onSubmit();
                }
              }}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">취소</Button>
          </DialogClose>
          <Button onClick={onSubmit} disabled={mutation.isPending}>
            {mutation.isPending && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            추가
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

type TagItemProps = {
  tag: { id: number; name: string };
  isSelected: boolean;
  handleSelectTag: (
    tag: { id: number; name: string },
    isSelected: boolean,
  ) => void;
  isLast?: boolean;
  inviewRef?: any;
};

const TagItem = React.memo(
  React.forwardRef<HTMLDivElement, TagItemProps>(
    (
      {
        tag,
        isSelected,
        handleSelectTag,
        isLast = false,
        inviewRef,
      }: TagItemProps,
      ref,
    ) => {
      return (
        <CommandItem
          key={tag.id}
          value={tag.name}
          onSelect={() => handleSelectTag(tag, isSelected)}
          ref={isLast ? inviewRef : ref}
        >
          <div className="flex items-center">
            <Check
              className={cn(
                "mr-2 h-4 w-4",
                isSelected
                  ? "text-primary opacity-100"
                  : "text-muted-foreground opacity-0",
              )}
            />
            {tag.name}
          </div>
        </CommandItem>
      );
    },
  ),
);
