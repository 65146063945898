import React, { Dispatch, SetStateAction } from "react";
import { Box, Button, Card, TextField } from "@mui/material";
import styled from "styled-components";

const ButtonMR = styled(Button)`
  &:not(:last-of-type) {
    margin-right: 10px;
  }
`;

const StyledTextField = styled(TextField)`
  background-color: white;
`;

const CardPadding = styled(Card)`
  background-color: #f6f6f6;
  padding: 15px;
`;

interface AgencyToolBarProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  onClickSearch: () => void;
}

const AgencyToolBar = ({
  search,
  setSearch,
  onClickSearch,
}: AgencyToolBarProps) => {
  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };

  return (
    <CardPadding>
      <Box display="flex" flexDirection="row">
        <Box flexGrow={1} display="flex" flexDirection="row">
          <StyledTextField
            style={{ width: 500 }}
            size="small"
            placeholder="검색하기"
            variant="outlined"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyPress={handleOnKeyPress}
          />
          <Box mr={1} />
          <ButtonMR color="primary" variant="contained" onClick={onClickSearch}>
            검색
          </ButtonMR>
        </Box>
      </Box>
    </CardPadding>
  );
};

export default AgencyToolBar;
