import {
  keepPreviousData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { api } from "src/api";

interface useGetFeaturesProps {
  pageNum: number;
  pageSize: number;
  sort: "CREATE" | "PUBLISH" | "VIEWCOUNT" | "ORDER";
  mainExposure?: boolean;
  exposure?: boolean;
  searchWord?: string;
  type: "BRANDB" | "EXTERNAL";
}

interface useGetFeatureTagsProps {
  pageNum: number;
  pageSize: number;
  searchWord?: string;
}

export function useDeleteFeatures() {
  return useMutation({
    mutationFn: (featureIds: number[]) => {
      return api.delete("/api/v2/admin/features/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: featureIds,
        },
      });
    },
  });
}

export function useGetFeatures({ ...props }: useGetFeaturesProps) {
  return useQuery({
    queryKey: ["features", props],
    queryFn: async () => {
      const response = await api.get<IPage<IFeatureListItemResponseDto>>(
        "/api/v2/admin/features",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useCreateExternalFeature() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/features/external", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useInsertContentImage() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/features/content/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useCreateBrandBFeature() {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.post("/api/v2/admin/features/brandb", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetFeautreDetail(featureId: number) {
  return useQuery({
    queryKey: ["feature", featureId],
    queryFn: async () => {
      const response = await api.get<IFeatureDetailResponseDto>(
        `/api/v2/admin/features/${featureId}`,
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateExternalFeature(featureId: number) {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.put(`/api/v2/admin/features/external/${featureId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useUpdateBrandBFeature(featureId: number) {
  return useMutation({
    mutationFn: (formData: FormData) => {
      return api.put(`/api/v2/admin/features/brandb/${featureId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useHideFeatures() {
  return useMutation({
    mutationFn: (featureIds: number[]) => {
      return api.put("/api/v2/admin/features/multiple/hide", {
        ids: featureIds,
      });
    },
  });
}

export function useExposeFeatures() {
  return useMutation({
    mutationFn: (featureIds: number[]) => {
      return api.put("/api/v2/admin/features/multiple/expose", {
        ids: featureIds,
      });
    },
  });
}

export function useGetFeatureTags({ ...props }: useGetFeatureTagsProps) {
  return useInfiniteQuery({
    queryKey: ["featureTags", props],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await api.get<IPage<IBrandCommonPropertyDto>>(
        "/api/v2/admin/features/tags",
        {
          params: {
            ...props,
            pageNum: pageParam,
          },
        },
      );
      return response.data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.last) {
        return undefined;
      }
      return lastPage.number + 2;
    },
    enabled: props.searchWord !== undefined && props.searchWord !== "",
  });
}

export function usePostFeatureTag() {
  return useMutation({
    mutationFn: (data: { name: string }) => {
      return api.post("/api/v2/admin/features/tags", data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetFeatureTagsInPage({ ...props }: useGetFeatureTagsProps) {
  return useQuery({
    queryKey: ["featureTags", props],
    queryFn: async () => {
      const response = await api.get<IPage<IBrandCommonPropertyDto>>(
        "/api/v2/admin/features/tags/page",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useDeleteFeatureTag() {
  return useMutation({
    mutationFn: (tagId: number) => {
      return api.delete(`/api/v2/admin/features/tags/${tagId}`, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useUpdateFeatureTag(tagId: number) {
  return useMutation({
    mutationFn: (data: { name: string }) => {
      return api.put(`/api/v2/admin/features/tags/${tagId}`, data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}
