import React, { useEffect } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Main from "./components/common/Main";
import { Routes } from "./routes";
import LoginPage from "./pages/LoginPage";
import LoginMain from "./components/LoginPage/LoginMain";
import LoginHeader from "./components/LoginPage/LoginHeader";
import { useDispatch, useSelector } from "react-redux";
import { actionCreators as userActions } from "src/redux/modules/user";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";

const theme = createTheme();
const App = () => {
  const dispatch = useDispatch();
  const isLogin = localStorage.getItem("access_token") ? true : false;

  const is__login = useSelector((state) => state.user.isLogin);

  useEffect(() => {
    if (isLogin) {
      dispatch(userActions.isLogin());
      return;
    }
  }, [dispatch, isLogin]);

  if (!is__login) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <LoginMain>
            <LoginHeader />
            <LoginPage />
            <Route path="/login" exact component={LoginPage} />
          </LoginMain>
        </ThemeProvider>
      </>
    );
  }

  if (is__login) {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Main>
            <Routes />
          </Main>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
};

export default App;
