import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";
import { api } from "src/api";
import { IFormTypeface } from "src/pages/new/typeface/NewTypefaceAddPage";

interface useGetTypfacesProps {
  pageNum: number;
  sort: "CREATE" | "NAME" | "LOGO_COUNT" | "UPDATE" | "DESIGNER";
  mainExposure?: boolean;
  searchWord?: string;
}

export function useDeleteTypefaces() {
  return useMutation({
    mutationFn: (typefaceIds: number[]) => {
      return api.delete("/api/v2/admin/typefaces/multiple", {
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          ids: typefaceIds,
        },
      });
    },
  });
}

export function useGetTypefaces({ ...props }: useGetTypfacesProps) {
  return useQuery({
    queryKey: ["typefaces", props],
    queryFn: async () => {
      const response = await api.get<IPage<ITypefaceListItemResponseDto>>(
        "/api/v2/admin/typefaces",
        {
          params: props,
        },
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useCreateTypeface() {
  return useMutation({
    mutationFn: (data: IFormTypeface) => {
      return api.post("/api/v2/admin/typefaces", data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetTypefaceDetail(typefaceId: number) {
  return useQuery({
    queryKey: ["typefaceDetail", typefaceId],
    queryFn: async () => {
      const response = await api.get<ITypefaceDetailResponseDto>(
        `/api/v2/admin/typefaces/${typefaceId}`,
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60,
    enabled: false,
  });
}

export function useUpdateTypeface(typefaceId: number) {
  return useMutation({
    mutationFn: (data: IFormTypeface) => {
      return api.put(`/api/v2/admin/typefaces/${typefaceId}`, data, {
        headers: {
          Authorization: localStorage.getItem("access_token") ?? "",
        },
      });
    },
  });
}

export function useGetTypefaceFamilyList() {
  return useQuery({
    queryKey: ["typefaceFamilyList"],
    queryFn: async () => {
      const response = await api.get<IBrandCommonPropertyDto[]>(
        "/api/v2/common/typefaceFamilies",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60,
  });
}

export function useGetSimpleTypefaces() {
  return useQuery({
    queryKey: ["simpleTypefaces"],
    queryFn: async () => {
      const response = await api.get<ISimpleTypefaceDto[]>(
        "/api/v2/admin/typefaces/simple",
      );
      return response.data;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });
}
