import { useDeleteManager } from "src/hooks/api/useUsers";
import { Button } from "../ui/button";
import { useToast } from "../ui/use-toast";

const UserDeleteButton = ({ id }: { id: string }) => {
  const mutation = useDeleteManager();
  const { toast } = useToast();

  const handleDelete = () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      mutation.mutate(parseInt(id), {
        onSuccess: () => {
          toast({
            title: "매니저 정보가 삭제되었습니다.",
            description: "매니저 정보가 삭제되었습니다.",
          });
          window.location.href = "/new/users";
        },
        onError: () => {
          toast({
            title: "매니저 정보 삭제에 실패했습니다.",
            description: "매니저 정보 삭제에 실패했습니다.",
            variant: "destructive",
          });
        },
      });
    }
  };

  return (
    <Button variant="destructive" onClick={handleDelete}>
      삭제
    </Button>
  );
};

export default UserDeleteButton;
