import { createAction, handleActions } from "redux-actions";
import { produce } from "immer";
import { FETCH_API, baseURL } from "../API";
import { createHashHistory } from "history";
export const history = createHashHistory();

// actions
const LOG_OUT = "LOG_OUT";
const SET_USER = "SET_USER";

// actionCreators
export const logOut = createAction(LOG_OUT, () => ({}));
const setUser = createAction(SET_USER, ({ token, role }) => ({ token, role }));

// initialState
const initialState = {
  token: null,
  role: null,
  isLogin: false,
  isAdmin: false,
};

// 일반 로그인
const loginAPI = (id, pw) => {
  const api = `${baseURL}/api/v2/admin/users/login`;
  return async function (dispatch, { history }) {
    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          userId: id,
          password: pw,
        }),
      });
      const res = await response.json();

      if (res.message === "Bad credentials") {
        alert("아이디 또는 비밀번호를 확인해주세요");
      }

      if (res.accessToken) {
        let token = `Bearer ${res.accessToken}`;
        localStorage.setItem("access_token", token);
        dispatch(setUser(token, res.role));
      }

      if (res.role) {
        localStorage.setItem("role", res.role);
      }
    } catch (err) {
      console.log(err, "loginAPI");
    }
  };
};

// 로그인 상태 체크
const isLogin = () => {
  return function (dispatch) {
    const token = localStorage.getItem("access_token");
    const role = localStorage.getItem("role");

    if (!token) {
      return;
    }
    dispatch(
      setUser({
        token: token,
        role: role,
      }),
    );
  };
};

const isAdmin = () => {
  return function (dispatch) {
    const role = localStorage.getItem("role");

    if (role !== "ROLE_ADMIN") {
      alert("접근 권한이 없습니다.");
      history.push("/");
    }
  };
};

// 로그아웃 localstorage 삭제
const isLogout = () => {
  return function (dispatch, { history }) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("role");
    dispatch(logOut());
  };
};

// reducer
export default handleActions(
  {
    [SET_USER]: (state, action) =>
      produce(state, (draft) => {
        draft.token = action.payload.token;
        draft.role = action.payload.role;
        draft.isLogin = true;
        draft.isAdmin = action.payload.role === "ROLE_ADMIN" ? true : false;
      }),

    [LOG_OUT]: (state, action) =>
      produce(state, (draft) => {
        draft.token = null;
        draft.role = null;
        draft.isLogin = false;
        draft.isAdmin = false;
      }),
  },
  initialState,
);

const actionCreators = {
  setUser,
  loginAPI,
  isLogin,
  isLogout,
  isAdmin,
};

export { actionCreators };
