import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FeatureTagColumns } from "src/components/brandbFeature/FeatureTagColumns";
import { TagCreateDialog } from "src/components/brandbFeature/FeatureTagSearch";
import { DataTable } from "src/components/common/DataTable";
import { Button } from "src/components/ui/button";
import { Input } from "src/components/ui/input";
import { useGetFeatureTagsInPage } from "src/hooks/api/useFeatures";

const FeatureTagsPage = () => {
  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  useEffect(() => {
    if (!isAdmin) {
      alert("접근 권한이 없습니다.");
      window.location.replace("/");
    }
  }, [isAdmin]);

  const [pageNum, setPageNum] = useState(1);
  const [searchWord, setSearchWord] = useState("");

  const { data: featureTags, refetch } = useGetFeatureTagsInPage({
    pageNum: pageNum,
    pageSize: 10,
    searchWord: searchWord,
  });

  const [open, setOpen] = useState(false);

  useEffect(() => {
    refetch();
  }, [pageNum, refetch]);

  const [selectedFeatureTagsRow, setSelectedFeatureTagsRow] = useState({});

  return (
    <>
      <h2 className="text-xl font-bold">콘텐츠 태그 관리</h2>
      <div className="mt-8 flex justify-end">
        <TagCreateDialog
          nameProps=""
          open={open}
          setOpen={setOpen}
          refetch={refetch}
        />
      </div>
      <div className="mt-8 flex">
        <Input
          value={searchWord}
          onChange={(e) => setSearchWord(e.target.value)}
          placeholder="검색어를 입력하세요"
          className="w-1/4"
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) return;
            if (e.key === "Enter") {
              e.preventDefault();
              refetch();
            }
          }}
        />
        <Button
          onClick={() => {
            refetch();
          }}
          className="ml-2"
        >
          검색
        </Button>
      </div>

      <div className="mt-8">
        <DataTable
          columns={FeatureTagColumns(refetch)}
          data={featureTags?.content ?? []}
          totalPages={featureTags?.totalPages ?? 0}
          totalElements={featureTags?.totalElements ?? 0}
          first={featureTags?.first ?? false}
          last={featureTags?.last ?? false}
          number={featureTags?.number ?? 0}
          onClickNext={() => {
            if (pageNum === featureTags?.totalPages) return;
            setPageNum((prev) => prev + 1);
          }}
          onClickPrev={() => {
            if (pageNum === 1) return;
            setPageNum((prev) => prev - 1);
          }}
          setRowSelection={setSelectedFeatureTagsRow}
          rowSelection={selectedFeatureTagsRow}
        />
      </div>
    </>
  );
};

export default FeatureTagsPage;
