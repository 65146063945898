import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import {
  useGetPinnedCaseStudyDetail,
  useUpdatePinnedCaseStudy,
} from "src/hooks/api/usePinnedCaseStudy";
import { useParams } from "react-router";
import { imageBaseUrl } from "src/constants";
import { useSelector } from "react-redux";

export interface IFormPinnedCaseStudy {
  mainExposure: boolean;
  thumbnail: File;
  exposure: boolean;
  brandName: string;
  content: string;
  brandSiteUrl: string;
  caseStudyUrl: string;
}

const NewPinnedCaseStudyEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { data: pinnedCaseStudy, refetch: refetchPinnedCaseStudy } =
    useGetPinnedCaseStudyDetail(parseInt(id));
  const form = useForm<IFormPinnedCaseStudy>({
    defaultValues: {
      mainExposure: false,
      exposure: false,
      brandName: "",
      content: "",
      brandSiteUrl: "",
      caseStudyUrl: "",
    },
  });

  useEffect(() => {
    refetchPinnedCaseStudy();
  }, []);

  useEffect(() => {
    if (pinnedCaseStudy) {
      form.setValue("mainExposure", pinnedCaseStudy.mainExposure);
      form.setValue("exposure", pinnedCaseStudy.exposure);
      form.setValue("brandName", pinnedCaseStudy.brandName);
      form.setValue("content", pinnedCaseStudy.content);
      form.setValue("brandSiteUrl", pinnedCaseStudy.brandSiteUrl);
      form.setValue("caseStudyUrl", pinnedCaseStudy.caseStudyUrl);
      setThumbnailPreview(
        `${imageBaseUrl}/?fileId=${pinnedCaseStudy.thumbnailId}`,
      );
    }
  }, [pinnedCaseStudy]);

  const [thumbnail, setThumbnail] = useState<File>();

  const [thumbnailPreview, setThumbnailPreview] = useState<string>("");

  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "thumbnail",
  ) => {
    const fileArr = e.target.files;

    let fileURLs: Array<string> = [];
    let file;

    if (!fileArr) return;

    setThumbnail((prev) => {
      return fileArr[0];
    });

    file = fileArr[0];
    let reader = new FileReader();
    reader.onload = () => {
      fileURLs[0] = reader.result ? (reader.result as string) : "";
      setThumbnailPreview((prev) => {
        return fileURLs[0];
      });
    };
    reader.readAsDataURL(file);
  };

  const mutation = useUpdatePinnedCaseStudy(parseInt(id));

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const onSubmit = (data: IFormPinnedCaseStudy) => {
    const formData = new FormData();
    if (thumbnail) {
      data.thumbnail = thumbnail;
      formData.append("thumbnail", thumbnail);
    }

    formData.append(
      "pinnedCaseStudy",
      new Blob([JSON.stringify(data)], { type: "application/json" }),
    );

    mutation.mutate(formData, {
      onSuccess: () => {
        alert("수정되었습니다.");
        window.location.replace("/new/pinned-case");
      },
      onError: () => {
        alert("수정에 실패하였습니다.");
      },
    });
  };

  const TextField = ({
    formField,
    label,
    placeholder,
  }: {
    formField: "brandName" | "brandSiteUrl" | "caseStudyUrl";
    label: string;
    placeholder?: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
              placeholder={placeholder}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "mainExposure" | "exposure";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">링크 등록</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
            control={form.control}
            name="thumbnail"
            render={({ field }) => (
              <FormItem>
                <Label className="text-lg font-bold">
                  썸네일(960 X 500 72dpl 파일로 업로드 해주세요)
                </Label>
                {thumbnailPreview && (
                  <Label>
                    <img
                      src={thumbnailPreview}
                      alt="brand-thumbnail"
                      className="h-[220px] w-[330px] object-contain"
                    />
                  </Label>
                )}
                <FormControl>
                  <NInput
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    onChange={(e) => {
                      handleImageUpload(e, "thumbnail");
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TextField formField="brandName" label="브랜드명" />

          <FormField
            control={form.control}
            name="content"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) => form.setValue("content", e.target.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TextField
            formField="brandSiteUrl"
            label="브랜드 사이트"
            placeholder="예시) http://example.com"
          />
          <TextField
            formField="caseStudyUrl"
            label="케이스 스터디(필수)"
            placeholder="예시) http://example.com"
          />

          {isAdmin && <BooleanField formField="exposure" label="노출 여부" />}
          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}

          <div className="flex justify-end">
            <Button type="submit">수정</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default NewPinnedCaseStudyEditPage;
