import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card,
  Checkbox,
} from "@mui/material";
import { useHistory } from "react-router";

const TableHeadColor = styled(TableHead)`
  background-color: #e2e2e2;
`;

const TableCellCenter = styled(TableCell)`
  :not(:last-child) {
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
  }
  :not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
`;

const Thumbnail = styled.img`
  border: 1px solid #dddddd;
  width: 300px;
  height: 150px;
  object-fit: contain;
`;

interface IPinnedCaseStudyListProps {
  pinnedCaseStudyList: IPinnedCaseStudyListItem[];
  selectedPinnedCaseStudyIds: number[];
  setSelectedPinnedCaseStudyIds: Dispatch<SetStateAction<number[]>>;
}

const PinnedCaseStudyList = ({
  pinnedCaseStudyList,
  selectedPinnedCaseStudyIds,
  setSelectedPinnedCaseStudyIds,
}: IPinnedCaseStudyListProps) => {
  const history = useHistory();
  const handleSelectAll = (event: any) => {
    let newSelectedCustomerIds: number[];

    if (event.target.checked) {
      newSelectedCustomerIds = pinnedCaseStudyList.map(
        (pinnedCaseStudy) => pinnedCaseStudy.id,
      );
    } else {
      newSelectedCustomerIds = [];
    }
    setSelectedPinnedCaseStudyIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event: any, id: number) => {
    const selectedIndex = selectedPinnedCaseStudyIds.indexOf(id);
    let newSelectedCustomerIds: number[] = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedPinnedCaseStudyIds,
        id,
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedPinnedCaseStudyIds.slice(1),
      );
    } else if (selectedIndex === selectedPinnedCaseStudyIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedPinnedCaseStudyIds.slice(0, -1),
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedPinnedCaseStudyIds.slice(0, selectedIndex),
        selectedPinnedCaseStudyIds.slice(selectedIndex + 1),
      );
    }
    setSelectedPinnedCaseStudyIds(newSelectedCustomerIds);
  };

  return (
    <>
      <Card>
        {pinnedCaseStudyList.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            mt={3}
            mb={3}
          >
            <b>등록된 컨텐츠가 없습니다</b>
          </Box>
        ) : (
          <>
            <Box sx={{ minWidth: 600 }}>
              <Table>
                <TableHeadColor>
                  <TableRow>
                    <TableCellCenter padding="checkbox">
                      <Checkbox
                        checked={
                          selectedPinnedCaseStudyIds.length > 0 &&
                          selectedPinnedCaseStudyIds.length <
                            pinnedCaseStudyList.length
                        }
                        color="primary"
                        indeterminate={
                          selectedPinnedCaseStudyIds.length > 0 &&
                          selectedPinnedCaseStudyIds.length <
                            pinnedCaseStudyList.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCellCenter>
                    <TableCellCenter align="center">No.</TableCellCenter>
                    <TableCellCenter align="center">메인노출</TableCellCenter>
                    <TableCellCenter align="center">썸네일</TableCellCenter>
                    <TableCellCenter align="center">브랜드명</TableCellCenter>
                    <TableCellCenter align="center">설명문구</TableCellCenter>
                    <TableCellCenter align="center">등록일자</TableCellCenter>
                    <TableCellCenter align="center">노출 여부</TableCellCenter>
                    <TableCellCenter align="center" />
                  </TableRow>
                </TableHeadColor>
                <TableBody>
                  {pinnedCaseStudyList &&
                    pinnedCaseStudyList.map((pinnedCaseStudy) => (
                      <TableRow hover key={pinnedCaseStudy.id}>
                        <TableCellCenter padding="checkbox">
                          <Checkbox
                            checked={
                              selectedPinnedCaseStudyIds.indexOf(
                                pinnedCaseStudy.id,
                              ) !== -1
                            }
                            onChange={(event) =>
                              handleSelectOne(event, pinnedCaseStudy.id)
                            }
                            value="true"
                            color="primary"
                          />
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {pinnedCaseStudy.id}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {pinnedCaseStudy.mainExposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Box display="flex" justifyContent="center">
                            {pinnedCaseStudy.thumbnailUrl && (
                              <Thumbnail src={pinnedCaseStudy.thumbnailUrl} />
                            )}
                          </Box>
                        </TableCellCenter>
                        <TableCellCenter>
                          {pinnedCaseStudy.brandName}
                        </TableCellCenter>
                        <TableCellCenter>
                          {pinnedCaseStudy.content}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {pinnedCaseStudy.createAt}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          {pinnedCaseStudy.exposure ? "Y" : "N"}
                        </TableCellCenter>
                        <TableCellCenter align="center">
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              history.push(
                                `/new/pinned-case/${pinnedCaseStudy.id}/edit`,
                              )
                            }
                          >
                            수정
                          </Button>
                        </TableCellCenter>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </>
        )}
      </Card>
      <Box display="flex" justifyContent="flex-end" mt={3}></Box>
    </>
  );
};

export default PinnedCaseStudyList;
