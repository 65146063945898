import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import { Checkbox as NCheckbox } from "src/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { DataTable } from "src/components/common/DataTable";
import { useGetBrands } from "src/hooks/api/useBrand";
import { trendKeywordBrandColumns } from "src/components/trendKeyword/TrendKeywordBrandColumns";
import {
  useGetTrendKeywordDetail,
  useUpdateTrendKeyword,
} from "src/hooks/api/useTrendKeyword";
import { useParams } from "react-router";
import TrendKeywordBrandItem from "src/components/trendKeyword/TrendKeywordBrandItem";
import { useSelector } from "react-redux";

export interface IFormTrendKeyword {
  korKeywordName: string;
  engKeywordName: string;
  trendKeywordType: "MARKET" | "DESIGN";
  description: string;
  mainExposure: boolean;
  brandIds: number[];
}

const NewTrendKeywordEditPage = () => {
  const [brandSearchWord, setBrandSearchWord] = useState("");
  const [brandPageNum, setBrandPageNum] = useState(1);
  const { id } = useParams<{ id: string }>();

  const brandSerchRef = useRef<HTMLInputElement>(null);

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { data: trendKeyword, refetch: refetchTrendKeyword } =
    useGetTrendKeywordDetail(parseInt(id));

  const { data: brands, refetch: refetchBrands } = useGetBrands({
    pageNum: brandPageNum,
    pageSize: 10,
    sort: "CREATE",
    searchWord: brandSearchWord,
  });

  const [selectedBrandsRow, setSelectedBrandsRow] = useState({});

  useEffect(() => {
    if (!brandSearchWord) return;
    refetchBrands();
  }, [brandPageNum, brandSearchWord]);

  const form = useForm<IFormTrendKeyword>({
    defaultValues: {
      korKeywordName: trendKeyword?.korName ?? "",
      engKeywordName: trendKeyword?.engName ?? "",
      trendKeywordType: trendKeyword?.trendKeywordType ?? "MARKET",
      description: trendKeyword?.description ?? "",
      mainExposure: trendKeyword?.mainExposure ?? false,
      brandIds: trendKeyword?.relatedBrands.map((brand) => brand.id) ?? [],
    },
  });

  const mutation = useUpdateTrendKeyword(parseInt(id));

  useEffect(() => {
    refetchTrendKeyword();
  }, []);

  useEffect(() => {
    if (trendKeyword) {
      form.setValue("korKeywordName", trendKeyword.korName);
      form.setValue("engKeywordName", trendKeyword.engName);
      form.setValue("trendKeywordType", trendKeyword.trendKeywordType);
      form.setValue("description", trendKeyword.description);
      form.setValue("mainExposure", trendKeyword.mainExposure);
      form.setValue(
        "brandIds",
        trendKeyword.relatedBrands.map((brand) => brand.id),
      );
      setSelectedBrands(trendKeyword.relatedBrands);
    }
  }, [trendKeyword]);

  const onSubmit = (data: IFormTrendKeyword) => {
    mutation.mutate(data, {
      onSuccess: () => {
        alert("수정되었습니다.");
        window.location.replace("/new/trend-keywords");
      },
      onError: () => {
        alert("수정에 실패하였습니다.");
      },
    });
  };

  const [selectedBrands, setSelectedBrands] = useState<
    IBrandListItemResponseDto[]
  >([]);

  const onTrendKeywordTypeChange = (trendKeywordType: "MARKET" | "DESIGN") => {
    form.setValue("trendKeywordType", trendKeywordType);
  };

  const TextField = ({
    formField,
    label,
  }: {
    formField: "korKeywordName" | "engKeywordName";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "mainExposure";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">트렌드 키워드 수정</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div className="flex gap-5">
            <div className="flex items-center gap-2">
              <TextField formField="korKeywordName" label="키워드(한글)" />
            </div>
            <div className="flex items-center gap-2">
              <TextField formField="engKeywordName" label="키워드(영문)" />
            </div>
          </div>

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">키워드 설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="trendKeywordType"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">키워드 분류</FormLabel>
                <FormControl>
                  <div className="flex w-full gap-6">
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">MARKET</Label>
                      <NCheckbox
                        checked={field.value === "MARKET"}
                        onCheckedChange={() =>
                          onTrendKeywordTypeChange("MARKET")
                        }
                      />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Label className="text-lg font-bold">DESIGN</Label>
                      <NCheckbox
                        checked={field.value === "DESIGN"}
                        onCheckedChange={() =>
                          onTrendKeywordTypeChange("DESIGN")
                        }
                      />
                    </div>
                  </div>
                </FormControl>
              </FormItem>
            )}
          />

          <div className="flex flex-col gap-4">
            <Label className="text-lg font-bold">트렌드 연관 브랜드</Label>
            <BrandList
              selectedBrands={selectedBrands}
              setSelectedBrands={setSelectedBrands}
              form={form}
            />
            <div className="flex max-w-sm items-center gap-2">
              <NInput
                ref={brandSerchRef}
                placeholder="검색"
                onKeyDown={(e) => {
                  if (e.nativeEvent.isComposing) return;
                  if (e.key === "Enter") {
                    e.preventDefault();
                    setBrandPageNum(1);
                    setBrandSearchWord(brandSerchRef.current?.value ?? "");
                  }
                }}
              />
              <Button
                type="button"
                onClick={() => {
                  setBrandPageNum(1);
                  setBrandSearchWord(brandSerchRef.current?.value ?? "");
                }}
              >
                검색
              </Button>
            </div>
            <DataTable
              columns={trendKeywordBrandColumns}
              data={brands?.content ?? []}
              totalPages={brands?.totalPages}
              totalElements={brands?.totalElements}
              first={brands?.first}
              last={brands?.last}
              number={brands?.number}
              onClickNext={() => {
                if (brandPageNum === brands?.totalPages) return;
                setBrandPageNum((prev) => prev + 1);
              }}
              onClickPrev={() => {
                if (brandPageNum === 1) return;
                setBrandPageNum((prev) => prev - 1);
              }}
              rowSelection={selectedBrandsRow}
              setRowSelection={setSelectedBrandsRow}
            />
            <Button
              type="button"
              size={"sm"}
              className={cn("w-20 self-end")}
              onClick={() => {
                if (!selectedBrandsRow) return;
                const newSelectedBrand = Object.keys(selectedBrandsRow).map(
                  (key) => parseInt(key),
                );
                form.setValue(
                  "brandIds",
                  form
                    .getValues("brandIds")
                    .concat(newSelectedBrand)
                    .filter(
                      (value: number, index: number, self: number[]) =>
                        self.indexOf(value) === index,
                    ),
                );
                setSelectedBrands((prev) =>
                  prev
                    .concat(
                      brands?.content.filter((brand) =>
                        newSelectedBrand.includes(brand.id),
                      ) ?? [],
                    )
                    .filter(
                      (
                        value: IBrandListItemResponseDto,
                        index: number,
                        self: IBrandListItemResponseDto[],
                      ) => self.indexOf(value) === index,
                    ),
                );
                setSelectedBrandsRow({});
              }}
            >
              추가
            </Button>
          </div>

          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}

          <div className="flex justify-end">
            <Button type="submit">수정</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default NewTrendKeywordEditPage;

const BrandList = ({
  selectedBrands,
  setSelectedBrands,
  form,
}: {
  selectedBrands: IBrandListItemResponseDto[];
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  form: ReturnType<typeof useForm<IFormTrendKeyword>>;
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {selectedBrands?.map((brand) => (
        <TrendKeywordBrandItem
          key={`brand-item-${brand.id}`}
          brand={brand}
          form={form}
          selectedBrands={selectedBrands}
          setSelectedBrands={setSelectedBrands}
        />
      ))}
    </div>
  );
};
