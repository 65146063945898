import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button } from "src/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { useToast } from "src/components/ui/use-toast";
import { useRegisterManager } from "src/hooks/api/useUsers";

export interface IFormUser {
  userId: string;
  password: string;
}

const NewUsersAddPage = () => {
  const form = useForm<IFormUser>({
    defaultValues: {
      userId: "",
      password: "",
    },
  });
  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const { toast } = useToast();

  useEffect(() => {
    if (!isAdmin) {
      alert("접근 권한이 없습니다.");
      window.location.replace("/");
    }
  }, [isAdmin]);

  const useConfirmPasswordRef = useRef<HTMLInputElement>(null);

  const mutation = useRegisterManager();

  const onSubmit = (data: IFormUser) => {
    if (data.userId === "" || data.password === "") {
      alert("아이디와 비밀번호를 입력해주세요.");
      return;
    }

    if (data.password !== useConfirmPasswordRef.current?.value) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    mutation.mutate(data, {
      onSuccess: () => {
        toast({
          title: "매니저가 등록되었습니다.",
          description: `${data.userId} 매니저가 등록되었습니다.`,
        });
        window.location.href = "/new/users";
      },
      onError: (error) => {
        if (error.message.includes("409")) {
          toast({
            title: "매니저 등록에 실패했습니다.",
            description: "이미 존재하는 매니저입니다.",
            variant: "destructive",
          });
        } else {
          toast({
            title: "매니저 등록에 실패했습니다.",
            description: error.message,
            variant: "destructive",
          });
        }
      },
    });
  };

  return (
    <>
      <h2 className="text-xl font-bold">신규 매니저 등록</h2>

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="mt-8 flex max-w-lg flex-col gap-5"
        >
          <FormField
            control={form.control}
            name="userId"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">매니저 ID</FormLabel>
                <FormControl>
                  <Input
                    value={field.value}
                    onChange={(e) => form.setValue("userId", e.target.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">비밀번호</FormLabel>
                <FormControl>
                  <Input
                    type="password"
                    value={field.value}
                    onChange={(e) => form.setValue("password", e.target.value)}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <div>
            <Label className="text-lg font-bold">비밀번호 확인</Label>
            <Input type="password" ref={useConfirmPasswordRef} />
          </div>
          <div className="mt-8 flex justify-end">
            <Button type="submit">등록</Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default NewUsersAddPage;
