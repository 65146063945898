import { Box, Button } from "@mui/material";
import { history } from "src/redux/configureStore";
import { useDispatch, useSelector } from "react-redux";
// import { actionCreators as userActions } from "src/redux/modules/user";
import { actionCreators as userActions } from "src/redux/modules/user";

const Header = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.user.isLogin);

  const logOutBtn = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      dispatch(userActions.isLogout());
      history.push("/");
      window.location.reload();
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} />
      {isLogin && (
        <>
          <Button variant="contained" onClick={logOutBtn}>
            로그아웃
          </Button>
        </>
      )}
    </>
  );
};

export default Header;
