import { ColumnDef } from "@tanstack/react-table";
import BrandTagDeleteButton from "./BrandTagDeleteButton";
import { BrandTagEditButton } from "./BrandTagEditButton";

export const BrandTagColumns = (refetch?: () => void) => {
  return [
    {
      accessorKey: "id",
      header: "ID",
      size: 100,
    },
    {
      accessorKey: "name",
      header: "태그 이름",
      size: 500,
    },
    {
      id: "edit",
      header: "수정",
      size: 100,
      cell: ({ row }) => {
        return (
          <BrandTagEditButton
            id={row.id}
            name={row.original.name}
            refetch={refetch}
          />
        );
      },
    },
    {
      id: "delete",
      header: "삭제",
      size: 100,
      cell: ({ row }) => <BrandTagDeleteButton id={row.id} refetch={refetch} />,
    },
  ] as ColumnDef<IBrandCommonPropertyDto>[];
};
