import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Input as NInput } from "src/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "src/components/ui/form";
import { RadioGroup, RadioGroupItem } from "src/components/ui/radio-group";
import { Button } from "src/components/ui/button";
import { cn } from "src/lib/utils";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { DataTable } from "src/components/common/DataTable";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "src/components/ui/card";
import { imageBaseUrl } from "src/constants";
import { useGetBrands } from "src/hooks/api/useBrand";
import { trendKeywordBrandColumns } from "src/components/trendKeyword/TrendKeywordBrandColumns";
import { X } from "lucide-react";
import { useGetTypefaceFamilyList } from "src/hooks/api/useTypeface";
import { Checkbox } from "src/components/ui/checkbox";
import { useCreateTypeface } from "src/hooks/api/useTypeface";
import { useSelector } from "react-redux";

export interface IFormTypeface {
  name: string;
  designer: string;
  description: string;
  mainExposure: boolean;
  relatedBrandIds: number[];
  typefaceFamilyIds: number[];
}

const NewTypefaceAddPage = () => {
  const form = useForm<IFormTypeface>({
    defaultValues: {
      name: "",
      designer: "",
      description: "",
      mainExposure: false,
      relatedBrandIds: [],
      typefaceFamilyIds: [],
    },
  });

  const { data: typefaceFamilyList } = useGetTypefaceFamilyList();

  const isAdmin = useSelector((state: UserState) => state.user.isAdmin);

  const mutation = useCreateTypeface();

  const onSubmit = (data: IFormTypeface) => {
    mutation.mutate(data, {
      onSuccess: () => {
        alert("등록되었습니다.");
        window.location.replace("/new/typeface");
      },
      onError: () => {
        alert("등록에 실패하였습니다.");
      },
    });
  };

  const [selectedBrands, setSelectedBrands] = useState<
    IBrandListItemResponseDto[]
  >([]);

  const TextField = ({
    formField,
    label,
  }: {
    formField: "name" | "designer";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="text-lg font-bold">{label}</FormLabel>
          <FormControl>
            <NInput
              value={field.value}
              onChange={(e) => form.setValue(formField, e.target.value)}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );

  const BooleanField = ({
    formField,
    label,
  }: {
    formField: "mainExposure";
    label: string;
  }) => (
    <FormField
      control={form.control}
      name={formField}
      render={({ field }) => (
        <FormItem className="gap-3">
          <Label className="text-lg font-bold">{label}</Label>
          <FormControl>
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              defaultValue={field.value.toString()}
              className="grid grid-cols-3 gap-3 lg:grid-cols-4"
            >
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"true"} />
                </FormControl>
                <FormLabel>Y</FormLabel>
              </FormItem>
              <FormItem className="flex items-center gap-2 space-y-0">
                <FormControl>
                  <RadioGroupItem value={"false"} />
                </FormControl>
                <FormLabel>N</FormLabel>
              </FormItem>
            </RadioGroup>
          </FormControl>
        </FormItem>
      )}
    />
  );

  const TypefaceFamilyField = () => (
    <FormField
      control={form.control}
      name="typefaceFamilyIds"
      render={() => (
        <FormItem>
          <div className="mb-4">
            <Label className="text-lg font-bold">서체 패밀리</Label>
          </div>
          <div className="grid grid-cols-3 gap-3 lg:grid-cols-4">
            {typefaceFamilyList?.map((typefaceFamily) => (
              <FormField
                key={typefaceFamily.id}
                control={form.control}
                name="typefaceFamilyIds"
                render={({ field }) => (
                  <FormItem
                    className="flex items-start gap-2 space-y-0"
                    key={typefaceFamily.id}
                  >
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(typefaceFamily.id)}
                        onCheckedChange={(checked) => {
                          checked
                            ? field.onChange([
                                ...field.value,
                                typefaceFamily.id,
                              ])
                            : field.onChange(
                                field.value?.filter(
                                  (value: number) =>
                                    value !== typefaceFamily.id,
                                ),
                              );
                        }}
                      />
                    </FormControl>
                    <FormLabel>{typefaceFamily.name}</FormLabel>
                  </FormItem>
                )}
              />
            ))}
          </div>
        </FormItem>
      )}
    />
  );

  return (
    <>
      <h1 className="py-4 text-xl font-bold">서체 정보 등록</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <TextField formField="name" label="서체명(국영문 혼용 가능)" />
          <TextField formField="designer" label="제작사(국영문 혼용 가능)" />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-lg font-bold">설명</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="설명을 적어주세요."
                    className={cn("min-h-[200px] resize-none")}
                    value={field.value}
                    onChange={(e) =>
                      form.setValue("description", e.target.value)
                    }
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <TypefaceFamilyField />
          <BrandSearch setSelectedBrands={setSelectedBrands} form={form} />
          <BrandList
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            form={form}
          />

          {isAdmin && (
            <BooleanField formField="mainExposure" label="메인 노출 여부" />
          )}

          <div className="flex justify-end">
            <Button type="submit">등록</Button>
          </div>

          <div className=" h-96" />
        </form>
      </Form>
    </>
  );
};

export default NewTypefaceAddPage;

const BrandSearch = ({
  setSelectedBrands,
  form,
}: {
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  form: ReturnType<typeof useForm<IFormTypeface>>;
}) => {
  const [brandSearchWord, setBrandSearchWord] = useState("");
  const [brandPageNum, setBrandPageNum] = useState(1);
  const { data: brands, refetch: refetchBrands } = useGetBrands({
    pageNum: brandPageNum,
    pageSize: 10,
    sort: "CREATE",
    searchWord: brandSearchWord,
  });

  const [selectedBrandsRow, setSelectedBrandsRow] = useState({});

  useEffect(() => {
    if (!brandSearchWord) return;
    refetchBrands();
  }, [brandPageNum]);

  return (
    <div className="flex flex-col gap-4">
      <Label className="text-lg font-bold">서체 연관 브랜드</Label>
      <div className="flex max-w-sm items-center gap-2">
        <NInput
          placeholder="검색"
          value={brandSearchWord}
          onChange={(e) => setBrandSearchWord(e.target.value)}
          onKeyDown={(e) => {
            if (e.nativeEvent.isComposing) return;
            if (!brandSearchWord) return;
            if (e.key === "Enter") {
              e.preventDefault();
              setBrandPageNum(1);
              refetchBrands();
            }
          }}
        />
        <Button
          type="button"
          onClick={() => {
            if (!brandSearchWord) return;
            setBrandPageNum(1);
            refetchBrands();
          }}
        >
          검색
        </Button>
      </div>
      <DataTable
        columns={trendKeywordBrandColumns}
        data={brands?.content ?? []}
        totalPages={brands?.totalPages}
        totalElements={brands?.totalElements}
        first={brands?.first}
        last={brands?.last}
        number={brands?.number}
        onClickNext={() => {
          if (brandPageNum === brands?.totalPages) return;
          setBrandPageNum((prev) => prev + 1);
        }}
        onClickPrev={() => {
          if (brandPageNum === 1) return;
          setBrandPageNum((prev) => prev - 1);
        }}
        rowSelection={selectedBrandsRow}
        setRowSelection={setSelectedBrandsRow}
      />
      <Button
        type="button"
        size={"sm"}
        className={cn("w-20 self-end")}
        onClick={() => {
          if (!selectedBrandsRow) return;
          const newSelectedBrand = Object.keys(selectedBrandsRow).map((key) =>
            parseInt(key),
          );
          form.setValue(
            "relatedBrandIds",
            form
              .getValues("relatedBrandIds")
              .concat(newSelectedBrand)
              .filter(
                (value: number, index: number, self: number[]) =>
                  self.indexOf(value) === index,
              ),
          );
          setSelectedBrands((prev) =>
            prev
              .concat(
                brands?.content.filter((brand) =>
                  newSelectedBrand.includes(brand.id),
                ) ?? [],
              )
              .filter(
                (
                  value: IBrandListItemResponseDto,
                  index: number,
                  self: IBrandListItemResponseDto[],
                ) => self.indexOf(value) === index,
              ),
          );
          setSelectedBrandsRow({});
        }}
      >
        추가
      </Button>
    </div>
  );
};

const BrandList = ({
  selectedBrands,
  setSelectedBrands,
  form,
}: {
  selectedBrands: IBrandListItemResponseDto[];
  setSelectedBrands: React.Dispatch<
    React.SetStateAction<IBrandListItemResponseDto[]>
  >;
  form: ReturnType<typeof useForm<IFormTypeface>>;
}) => {
  return (
    <div className="flex flex-wrap gap-3">
      {selectedBrands?.map((brand) => (
        <Card className="w-[250px]" key={brand.id}>
          <CardHeader>
            <CardTitle className={cn("flex justify-between text-lg")}>
              {brand.brandType === "KOREA" ? brand.korName : brand.engName}
              <div
                className="cursor-pointer"
                onClick={() => {
                  form.setValue(
                    "relatedBrandIds",
                    form
                      .getValues("relatedBrandIds")
                      .filter((value: number) => value !== brand.id),
                  );
                  setSelectedBrands(
                    selectedBrands.filter(
                      (selectedBrand) => selectedBrand.id !== brand.id,
                    ),
                  );
                }}
              >
                <X className="h-4 w-4" />
              </div>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid w-full items-center gap-4">
              {brand.thumbnailId && (
                <img
                  src={`${imageBaseUrl}/?fileId=${brand.thumbnailId}`}
                  alt="brand thumbnail"
                />
              )}

              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-announcement`}
                  className="font-bold"
                >
                  발표일
                </Label>
                <div id={`brand-${brand.id}-announcement`} className="text-sm">
                  {brand.announcementDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-update`}
                  className="font-bold"
                >
                  최종 입력 시간
                </Label>
                <div id={`brand-${brand.id}-update`} className="text-sm">
                  {brand.modifiedDate}
                </div>
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label
                  htmlFor={`brand-${brand.id}-exposure`}
                  className="font-bold"
                >
                  노출 여부
                </Label>
                <div id={`brand-${brand.id}-exposure`} className="text-sm">
                  {brand.exposure ? "Y" : "N"}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};
